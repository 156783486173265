.minicart-wrapper .action.showcart .counter {
    .loading-mask {
        background: none;
        .loader {
            > img {
                max-width: 18px;
            }
        }
    }
    &.qty {
        position: absolute;
        right: 0;
        top: 53px;
    }
}

.minicart-wrapper .block-minicart {
    border: none;
    min-width: inherit;
    right: -1px;
    width: 311px;
    background: $white;
    border-radius: 3px;
    list-style: none;
    margin: -35px 0 0;

    .block-title {
        display: block;
        font-size: em(18, 14);
        font-weight: 700;

        .qty {
            display: none;
        }
    }

    .minicart-items-wrapper {
        border: 0;
    }

    .subtitle.empty {
        color: $blue;
        font-family: $readStack;
        font-weight: 300;
        margin: 0;
        letter-spacing: normal;
        text-align: left;
        text-transform: none;

        &:after {
            display: none;
        }
    }

    .block-content > .actions {
        text-align: right;

        > .primary .action.primary {
            background: $green;
            border: 0;
        }
    }

    .items-total {
        float: none;
    }

    .items-total,
    .subtotal {
        margin: 20px 10px;
    }

    .items-total {
        span {
            color: $grayTertiary;
            font-size: em(11, 14);

        }
    }

    .price-container .price-excluding-tax {
        &:before {
            display: none;
        }
        &:after {
            content: attr(data-label);
            font-size: 1.1rem;
        }
    }
}
.minicart-wrapper .cart-container {
    width: 60px;
}
.cart-text {
    color: $white;
    display: flex;
    font-size: 14px;
    font-weight: 700;
    margin: 4px 10px 0 10px;
    position: relative;
    text-align: left;
    white-space: pre-wrap;
    &.is-active {
        color: black;
    }
}
.minicart-wrapper .action.showcart .cart-image {
    position: relative;

    .counter.qty {
        left: 38px;
        min-width: 25px;
        position: absolute;
        top: 9px;
    }

    .counter-number {
        &.active {
            color: #333;
        }
    }
}
img.cart-new.showcart {
    display: flex;
    margin-left: 10px;
    width: 32px;

    &.active {
        display: flex;
    }

    &.inactive {
        display: none;
    }
}

.minicart-wrapper .block-minicart:before {
    display: none;
}

.minicart-wrapper .block-minicart:after {
    display: none;
}

.cart-text.active {
    color: #333;
}

img.new-cart.showcart {
    display: none;
    margin-left: 10px;
    width: 32px;

    &.active {
        display: flex;
    }
}
  
img.cart-new.showcart + img.new-cart.showcart {
    margin-left: 10px;
    width: 32px;
}

