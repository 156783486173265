.introduction {
    margin-bottom: 44px;

    .introduction__title {
        color: $hp-lightblue;
        font-size: em(60, 14);
        font-weight: 900;
        margin-bottom: 8px;
        text-transform: none;
    }

    .introduction__subtitle {
        color: $hp;
        font-weight: bold;
        letter-spacing: 0.78px;
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    .introduction__description {
        font-size: em(16, 14);
        line-height: 1.56;
        margin: 0;
        max-width: 769px;

        a {
            color: $hp-about-light-blue;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}

.expert-introduction_introduction-image {
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    background-position: 100% 75%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    min-height: 372px;
    width: 100%;

    .expert-introduction--content {
        align-self: flex-end;
        color: $white;
        margin-bottom: 32px;
        padding: 0 32px;
        width: 500px;

        h2 {
            color: $white;
            font-size: em(28, 14);
        }
        p {
            font-size: em(14, 14);
            margin-bottom: 0;
        }
    }
}

.expert-block--servetten-kleuren {
    &.flex {
        @include max-screen(650px) {
            flex-direction: column;
        }
    }
    .left,
    .right { 
        width: 50%;
        
        @include max-screen(650px) {
            width: 100%;
        }
        
        .oval {
            border: 5px solid white;
            border-radius: 50%;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.15);
            display: inline-block;
            height: 37px;
            margin-right: 10px;
            width: 37px;
        }
        
        .oval--red { 
            background-color: #e3512b;
        }
        .oval--red2 { 
            background-color: #e20f0f; 
        }
        .oval--pink { 
            background-color: #fe76d5; 
        }
        .oval--blue { 
            background-color: #2f8dd8; 
        }
        .oval--yellow { 
            background-color: #fad354; 
        }
        .oval--green { 
            background-color: #05a400; 
        }
        .oval--salmon { 
            background-color: #ffe8d6; 
        }
        .oval--grey { 
            background-color: #6f6f6f; 
        }
        .oval--black { 
            background-color: #2e2e2e; 
        }

        p { 
            font-size: em(16, 14);
            line-height: 1.5625;
            margin-bottom: 48px;
            width: 80%;
		}
	}
}

.expert-page-headerPictures {
    margin-top: 30px;

    @include max-screen(768px) {
        display: flex;
        flex-direction: column;
    }

    img:nth-child(2) {
        margin-left: 20px;
      
        @include max-screen(768px) {
            margin-left: 0;
            margin-top: 20px;
        }
    }
    img {
        height: 469px;
        width: 435.5px;
    }
}

.expert__bestsellers {
    margin-bottom: 64px;

    @include max-screen(768px) {
        margin-bottom: 13px;
    }

    .expert__grid {
        h4 {
            font-size: em(24, 14);
            text-transform: none;

            span {
                color: $hp-about-light-blue;
            }
        }
        p {
            font-size: em(16, 14);
        }
    }
}

.expert-page-bodyContent {
    .btn {
        color: $white;
    }
}

ul.expert__products-categories__filter-list {
    margin-left: -8px;
    margin-right: -8px;

    li {
        border: 2px solid $hp-about-light-blue;
        border-radius: 3px;
        color: $hp-about-light-blue;
        font-size: em(14, 14);
        font-weight: bold;
        letter-spacing: 0.7px;
        margin-bottom: 16px;
        margin-left: 8px;
        margin-right: 8px;
        padding: 12px 32px;
        transition: 0.2s ease-in-out all;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
        }
        
        &.is-active {
            background-color: $hp-about-light-blue;
            color: $white;
        }
    }
}

.expert-textBlock__content {
    a {
        color: $white;
    }
    p {
        font-size: em(16, 14);
    }
    img {
        margin-left: 73px;
        max-height: 330px;
        max-width: 286px;

        @include max-screen(768px) {
            margin: 10px 0 0 0;
        }
    }
    &.expert-textBlock__inverted {
        img {
            margin-left: 0;
            margin-right: 73px;
        }
    }

    .flex {
        @include max-screen(768px) {
            flex-direction: column;
        }
    }
}
  
.expert__reviews {
    margin-top: 13px;

    @include max-screen(768px) {
        margin-bottom: 24px;
    }

    .reviews {
        display: flex;
        flex-wrap: wrap;
        margin-left: -27px;
        margin-right: -27px;

        @include max-screen(768px) {
            flex-flow: column;
            margin-left: -16px;
            margin-right: -16px;
        }

        .reviews__item {
            background-color: #f6faff;
            border-radius: 4px;
            display: flex;
            flex-basis: calc(50% - 54px);
            flex-flow: column;
            margin-bottom: 54px;
            margin-left: 27px;
            margin-right: 27px;
            padding: 24px;
            width: calc(50% - 54px);

            @include max-screen(768px) {
                flex-basis: calc(50% - 32px);
                margin-bottom: 24px;
                margin-left: 16px;
                margin-right: 16px;
                width: calc(100% - 32px);
            }

            @include max-screen(520px) {
                padding: 16px;
            }
        }
          
        .reviews__top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        .reviews__header {
            display: flex;
            flex-flow: column;
        }

        .reviews__name {
            color: #358ee0;
            font-size: em(20, 14);
            font-weight: 900;
            text-transform: uppercase;
        }

        .reviews__type {
            color: #358ee0;
            font-size: em(12, 14);
            font-weight: 400;
            text-transform: uppercase;
        }

        .reviews__figure {
            align-items: center;
            background-color: #f7b847;
            border-radius: 100%;
            color: white;
            display: flex;
            font-size: em(20, 14);
            font-weight: 900;
            height: 36px;
            justify-content: center;
            margin-left: auto;
            text-align: center;
            width: 36px;
        }

        .reviews__description {
            font-size: em(16, 14);
            font-style: italic;
            font-weight: 400;
        }
    } 
}

.tip {
    display: flex;
    margin-bottom: 32px;

    @include max-screen(768px) {
        flex-flow: column;
    }

    .tip__left {
        margin-right: 44px;
        width: calc(100% - 316px - 44px);

        @include max-screen(768px) {
            margin-bottom: 24px;
            margin-right: 0;
            width: 100%;
        }
    }

    .tip__right {
        width: 316px;

        @include max-screen(768px) {
            width: 100%;
        }
    }

    .tip__title {
        font-size: em(28, 14);
    }

    .tip__description {
        font-size: em(16, 14);
        line-height: 1.56;
        margin: 0;
    }

    .cta {
        border-radius: 5px;
        color: $white;
        padding: 32px;
    
        @include max-screen(768px) {
            padding: 24px;
        }

        &.cta--blue {
            background-color: #42a1e6;

            a.btn--white {
                color: #358ee0;
            }
        }
    
        .cta__title {
            font-size: em(20, 14);
        }
    
        .cta__description {
            line-height: 1.79;
        }
    }
}
