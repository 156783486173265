.reason-block {
    background: #232323;
    border-radius: 4px;
    display: flex;
    height: 309px;
    justify-content: space-between;
    position: relative;
    text-decoration: none;
    transition: transform 0.3s $transitionPrimary;
  
    @include max-screen(768px) {
        flex-flow: row wrap;
        height: auto;
        padding-top: 0;
    }
  
    &:hover {
        border-radius: 4px;
        transform: scale(1.05) translate3d(0, 0, 0);
        text-decoration: none;
    }
  
    &__image {
        height: auto;
        position: relative;
        width: 464px;

        @include max-screen(1280px) {
            width: 380px;
        }
  
        @include max-screen(768px) {
            order: 2;
            padding: 0;
            width: 100%;
        }
    }
  
    &__content {
        border-radius: 0 4px 4px 0;
        color: #fff;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        padding: 35px 35px 35px 90px;
  
        @include max-screen(768px) {
            border-radius: 0 0 4px 4px;
            order: 1;
            width: 100%;
        }

        .btn {
            margin-top: 10px;
            width: 250px;

            @include max-screen(768px) {
                width: 100%;
            }
        }
  
        .btn.btn--orange {
            background-color: #e05835;
            border-radius: 3px;
            box-shadow: 1px 1px 0 0 #7a1c04;
            font-family: $readStack;
            font-size: em(14, 14);
            font-weight: bold;
            letter-spacing: 0.6px;
            min-height: 42px;
            
        }

        .btn.btn--gold {
            background: #ec9835;
        }
    }
  
    &__title {
        color: #fff;
        display: block;
        font-family: $titleStack;
        font-size: em(38, 14);
        font-weight: 900;
        line-height: 38px;
        text-transform: uppercase;

        @include max-screen(1280px) {
            font-size: em(32, 14);
        }

        @include max-screen(1024px) {
            font-size: em(28, 14);
        }

        @include max-screen(768px) {
            font-size: em(20, 14);
        }
    }
  
    &__subtitle {
        text-transform: uppercase;
  
        @include max-screen(480px) {
            font-size: em(16, 14);
        }
    }

    .reason-block__content,
    .reason-block__image {
        background: inherit;
    }

    .reason-block__desc {
        font-size: em(18, 14);
        line-height: 25px;
    }
  
    p {
        font-weight: 400;
        margin-bottom: 0;
    }

    span.fa-caret-right {
        &:before {
            color: white;
            content: "\f105";
            display: inline-block;
            font-family: "FontAwesome";
            font-size: 13px;
            font-weight: 900;
            margin-right: 5px;
        }
    }

    &.de {
        .btn {
            width: 100%;
        }
    }
}
