.expert-disclaimer {
    @include max-screen(768px) {
        align-items: start;
        flex-direction: column;
        justify-content: flex-start;
    }
    .greyline {
        background-color: $grayWhite;
        color: $grayWhite;
        font-size: 1px;
        line-height: 2px;
        margin: 8px 10px 10px 10px;
        width: 51px;
    }
    .expert-disclaimer__item {
        font-size: em(16, 14);
        font-weight: normal;
        margin: 0 0 0 28px;
  
        @include max-screen(768px) {
            margin-bottom: 4px;
        }
  
        &:before {
            background-color: $hp-about-light-blue;
            border-radius: 50%;
            content: "";
            height: 16px;
            left: -20px;
            position: absolute;
            top: 3px;
            width: 16px;

            @include max-screen(768px) {
                display: none;
            }
        }
        &:after {
            border-bottom: 2px solid white;
            border-left: 2px solid white;
            content: "";
            height: 5px;
            left: -16px;
            position: absolute;
            top: 7px;
            transform: rotate(-45deg);
            width: 9px;

            @include max-screen(768px) {
                display: none;
            }
        }
        span {
            color: $hp-gold;
            font-weight: bold;
        }
    }
}
