// Buttons
.btn {
    padding: 13.5px 15px;
    display: inline-block;
    border-radius: 4px;
    font-size: em(14, 16);
    line-height: em(18, 16);
    cursor: pointer;
    font-family: $btnStack;
    appearance: none;
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    outline: none;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    background: $blueSecondary;
    box-shadow: 2px 2px 0 0 $blue;
    transition: box-shadow 0.1s $transitionPrimary;
    &:hover {
        box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
        color: $white;
        opacity: 1;
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
    &--white {
        background: white;
        box-shadow: 0px 0px 0 0 #aeaeae;
    }
  
    &--gold {
        background: $gold;
        color: #fff;
        box-shadow: 2px 2px 0 0 $goldSecondary;
    }
    &Compact {
        background: $blueTertiary;
        color: $blue;
        box-shadow: 2px 2px 0 0 $blueLight;
        &:hover {
            box-shadow: 1px 1px 3px 0 $blueLight;
        }
    }
    &--blue {
        background: $blue;
        color: #fff;
    }
    &--hp-blue {
        background: $hp-blue;
        color: #fff;
    }
    &--green {
        background: $green;
        color: #fff;
    }
    &--gray {
        background: $gray;
        color: $blue;
        box-shadow: 2px 2px 0 0 $graySecondary;
    }
    &--no-bg {
        background: none;
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
    &--success {
        background: $success;
        box-shadow: 2px 2px 0 0 #6da64d;
  
        &:before {
            font-family: 'FontAwesome';
            content: '\f00c';
            margin-right: 10px;
        }
        color: #fff;
    }
    &--close {
        background: $error;
        box-shadow: 2px 2px 0 0 #7b1d1d;
        color: #fff;
        margin-left: 10px;
        i {
            margin-right: 0;
        }
    }
    &--no-shadow {
        box-shadow: none;
        &:hover {
            box-shadow: none;
        }
    }
    &--disabled {
        opacity: 0.8;
        cursor: not-allowed;
        pointer-events: none;
    }
    i {
        margin-right: 10px;
    }
    &--blue-border {
        border: 1px solid $hp-about-light-blue;
        background: none;
        box-shadow: none;
        color: $hp-about-light-blue;
        padding: 13.5px 15px 11px;
    }
}
