.checkout-onepage-success main {
    max-width: initial;
    width: 100%;

    h2, 
    h3, 
    .title {
        color: $hp-about-light-blue;
        z-index: 1;
    }
    h2, 
    h3 {
        font-size: em(28, 14);
        font-weight: 800;
        text-transform: uppercase;
    }
  
    .hero-image {
        position: relative;

        .garlands, 
        .balloons {
            position: absolute;
            top: 0;
            
            @include max-screen(768px) {
                display: none;
            }
            
            &.garlands--left {
                left: 0;
            }
            &.garlands--right {
                right: 0;
            }
        }
        .balloons {
            right: 0;
            top: 240px;
            @include max-screen(1024px) {
                display: none;
            }
        }
        .wrapper {
            text-align: center;
            width: 650px;
            
            @include max-screen(768px) {
                width: 90%;
            }

            .title {
                color: $hp-about-light-blue;
                font-size: em(56, 14);
                font-weight: 800;
                text-transform: initial;

                @include max-screen(1024px) {
                    font-size: em(48, 14);
                }

                @include max-screen(768px) {
                    font-size: em(32, 14);
                }
            }
            .subtitle--center {
                font-family: 'Proxima Nova';
                font-size: em(16, 14);
                margin: 0 60px;

                &.subtitle--center--bold,
                &.subtitle--center--bold a {
                    color: $hp-grey;
                    font-weight: 600;
                }
            }
        }
    }
    .blocks .wrapper, 
    hr {
        width: 850px;
      
        @include max-screen(900px) {
            width: 80%;
        }

        .flex--center {
            align-items: flex-start;
            
            @include max-screen(1024px) {
                align-items: center;
                flex-direction: column;
            }
        }
    }
  
    .checkout-success--verzenden {
        width: 60%;

        @include max-screen(1024px) {
            width: 500px;
        }

        @include max-screen(600px) {
            width: 100%;
        }
        
        .table {
            flex-direction: column;
            flex-wrap: wrap;
            height: 260px;
            width: 500px;
            
            @include max-screen(600px) {
                height: auto;
                margin-bottom: 20px;
            }

            .table-cell {
                font-size: em(16, 14);
                margin: 10px 0;
                width: calc(1 / 2 * 100% - (1 - 1 / 2) * 15px);
            }
        }

        span { 
            color: $hp-grey;
            display: inline-block;
            font-weight: 600;
            max-width: 95px;
            min-width: 95px;
            vertical-align: top;
            
            &.right {
                font-weight: 300;
                word-wrap: break-word;
            }
        }
    }
  
    .checkout-success--container {
        width: 40%;

        @include max-screen(1024px) {
            width: 500px;
        }

        @include max-screen(600px) {
            width: 100%;
        }

        .table {
            .table-cell {
                font-size: em(16, 14);
                margin: 10px 0;
            }

            .table-cell--status {
                color: $green;
                font-weight: 600;

                span {
                    min-width: 20px;
                }

                span::after {
                    color: $green;
                    content: '\f00c';
                    font-family: 'FontAwesome';
                }
            }
        }
        span { 
            color: $hp-grey;
            display: inline-block;
            font-weight: 600;
            min-width: 150px;
        }
    }
    
    .checkout-success--opmerking {
        p {
            font-size: em(16, 14);
        }
    }
  
    hr {
        border-bottom: 1px solid $white;
        border-top: 1px solid #bfbfbf;
        color: #bfbfbf;
        display: block;
        font-size: 1px;
        margin-top: 40px;
    }
  
    .checkout-success--bestelling {
        width: 645px;

        @include max-screen(1024px) {
            flex-basis: 100%;
        }

        @include max-screen(900px) {
            width: 100%;
        }

        .table {
            .row {
                display: flex;

                &.row--head {
                    color: $hp-about-light-blue;
                    font-weight: bold;
                }

                .table-cell:nth-child(1) {
                    width: 49%;
                }

                .table-cell:nth-child(2),
                .table-cell:nth-child(3),
                .table-cell:nth-child(4) {
                    width: 17%;
                }

                &.row--subtotal {
                    justify-content: space-between;

                    .table-cell {
                        font-size: em(20, 14);

                        @include max-screen(900px) {
                            font-size: em(16, 14);
                        }
                        @include max-screen(600px) {
                            font-size: em(14, 14);
                        }
                    }
                    
                    .table-cell:nth-child(2) {
                        width: 121px;
                    }
                }
            }
            .table-cell {
                font-size: em(16, 14);
                margin: 10px 10px 0 0;

                @include max-screen(900px) {
                    font-size: em(14, 14);
                }

                @include max-screen(600px) {
                    font-size: em(12, 14);
                }

                &.table-cell--bold {
                    font-weight: bold;
                }

                &.table-cell--blue {
                    color: $hp-about-light-blue;
                    font-weight: bold;
                }

                &.table-cell--green {
                    color: $green;
                    font-weight: bold;
                }
            }

            hr {
                margin: 16px 0;
                width: 95%;
            }

            span.sub_bespaart {
                font-size: 16px;
                font-weight: normal;
                
                @include max-screen(900px) {
                    font-size: em(12, 14);
                }
            }
        }
    }
  
    .checkout-success--vragen {
        width: 205px;

        @include max-screen(1024px) {
            display: none;
        }
        
        .widget__title {
            color: $blue;
            font-size: em(14, 14);
        }

        .widget__information {
            margin-top: 20px;
        }
    }
  
    .checkout-success--text {
        .wrapper {
            div {
                width: 520px;

                @include max-screen(1024px) {
                    margin-left: 100px;
                    width: 80%;
                }

                @include max-screen(900px) {
                    margin-left: 0;
                }

                p {
                    font-family: 'Proxima Nova';
                    font-size: em(18, 14);
                }

                a.btn {
                    color: $white;
                    width: 230px;
                }
            }
        }
    }
}
