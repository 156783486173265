.sidebar.sidebar-main {
    @include max-screen(1024px) {
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
    }
} 

.sidebar .block.filter {
    border: 1px solid $grayBG;
    border-radius: 4px;
    padding: 25px;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);

    &.is-active {
        @include max-screen(1024px) {
            visibility: visible;
        }
    }

    @include max-screen(1024px) {
        background-color: $white;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .close-button {
        font-size: em(20, 14);
        position: absolute;
        right: 15px;
        top: 15px;

        @include min-screen(1024px) {
            display: none;
        }

        &:before {
            content: "\f00d";
            display: block;
            font-family: "FontAwesome";
            font-weight: 900;
        }
    }

    .block-subtitle.filter-subtitle {
        display: none;
    }

    .filter-options-item {
        .filter-options-title {
            cursor: pointer;

            &:after {
                color: $hp-blue;
                content: "\f106";
                display: inline-block;
                font-family: "FontAwesome";
                margin-left: 5px;
                transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
            }
        }

        &:not(.active) {
            .filter-options-title {
                &:after {
                    content: "\f107";
                }
            }
        }
    }

    .filter-content,
    .filter-options-item {
        .items {
            background: none;

            label {
                display: inline-block;
                width: 90%;

                span {
                    margin: 3px 0;
                    text-transform: none;
                }
            }

            input[type="checkbox"] {
                appearance: auto;
                margin: 3px 3px 0 0;
                -webkit-appearance: checkbox;
            }
        }
        .filter-label,
        .filter-value {
            text-transform: none;
        }

        .item {
            a {
                text-transform: none;
            }
            .action.remove {
                display: block;
                text-transform: none;
            }
        }
    }

    .smile-es-range-slider {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        [data-role="from-label"],
        [data-role="to-label"] {
            flex-grow: 2;
        }
        [data-role="to-label"] {
            text-align: right;
        }
        .ui-slider-horizontal {
            width: 100%;
        }
        .ui-slider-handle {
            background: linear-gradient(to bottom, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
            background-color: initial;
            border: 1px solid #eee;
            box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
            height: 18px;
            top: -5px;
            width: 18px;
        }
        .actions-toolbar {
            width: 100%;

            [data-role="message-box"] {
                margin: 5px 0;
            }
            .actions-primary {
                @extend .btn;
                background: $hp-blue;
                border: 0;
                padding: 2px;
                .action.primary {
                    background: $hp-blue;
                    border: 0;
                }
            }
        }
    }
}
