.page.messages .messages {
    left: 80px;
    margin: 0;
    padding: 20px;
    position: fixed;
    top: 0;
    transition: all 0.3s $transitionPrimary;
    width: 300px;
    z-index: 10;

    .message {
        border-radius: 4px;
        box-shadow: none;
        color: $white;
        font-family: $readStack;

        a {
            color: $white;
        }
    }

    .message-success.success.message {
        background: $success;
    }
  
    .message-error.error.message {
        background: $error;
    }

    @include max-screen(768) {
        left: 20px;
    }
  
    @include max-screen(480) {
        left: 0;
        right: 0;
        margin: 2.5% auto 0;
        width: 90%;
    }
}
