//Extend mixin of magento to include font-display: swap
@mixin lib-font-face(
    $family-name,
    $font-path,
    $font-weight: normal,
    $font-style: normal
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}
@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/light/opensans-300',
    $font-weight: 300,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/regular/opensans-400',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/semibold/opensans-600',
    $font-weight: 600,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/opensans/bold/opensans-700',
    $font-weight: 700,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}
