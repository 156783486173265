.modal {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    height: 100%;
    left: 0;
    margin: auto;
    position: fixed;
    opacity: 0;
    top: 0;
    transition: all 0.3s $transitionPrimary;
    visibility: hidden;
    width: 100%;
    z-index: 10;
  
    &.is-active {
        opacity: 1;
        transition: all 0.3s $transitionPrimary;
        visibility: visible;
    
        .modal__content {
            opacity: 1;
            transform: translateY(0);
            transition: all 0.3s $transitionPrimary;
        }
    }
  
    .modal__content {
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: all 0.3s $transitionPrimary;
        transform: translateY(-400px);
        width: 1025px;
    
        .input-text.UI-NAV-INPUT {
            padding-left: 0;
        }
    
        .all {
            display: none;
        }
    }

    &.modal--form {
        background-color: rgba(54, 54, 54, 0.5);
      
        @include max-screen(768px) {
            align-items: flex-start;
            padding-bottom: 20px;
            padding-top: 20px;
        }
    }
      
    &.modal--form .modal__content {
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);
        max-height: calc(100vh - 10px);
        overflow-y: auto;
        padding: 32px 40px;
        width: 701px;

        @include max-screen(768px) {
            width: calc(100% - 40px);
        }
    }
      
    &.modal--form .close-modal {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        right: 32px;
        top: 28px;
        border: 2px solid $hp-blue;
        color: $hp-blue;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s $transitionPrimary;
    
        &:hover {
            transform: scale(1.1);
        }
    }
      
    &.modal--form .close-modal svg {
        height: 14px;
        width: 14px;
    }
      
    &.modal--form .title {
        display: block;
        font-family: $titleStack;
        font-size: em(29, 16);
        font-weight: 900;
        margin-bottom: 28px;
        letter-spacing: 1px;
        text-transform: uppercase;
    
        @include max-screen(768px) {
            font-size: em(22, 16);
        }
    
        @include max-screen(480px) {
            padding-right: 46px;
        }
    }
      
    &.modal--form span.title--form {
        color: $blue;
        display: block;
        font-family: $titleStack;
        font-size: em(19, 14);
        font-weight: 700;
        letter-spacing: 1.3px;
        margin-bottom: 8px;
        text-transform: uppercase;
        
        @include max-screen(768px) {
            font-size: em(17, 16);
        }
    }
      
    &.modal--form input {
        max-width: 294px;
    
        @include max-screen(320px) {
            max-width: 100%;
        }
    }
    
    &.modal--form span.title--number {
        color: $hp-blue;
    }
    
    &.modal--form p {
        margin-bottom: 16px;
        margin-top: 0;
    }
    
    &.modal--form fieldset {
        margin-bottom: 40px;
    
        &:nth-child(2) {
            margin-bottom: 26px;
        }
    }
    
    &.modal--form button {
        min-width: 242px;
    
        @include max-screen(320px) {
            min-width: 100%;
        }
    }
    
    &.modal--form .product-list {
        color: $darkGrey;
        list-style: disc;
        list-style-position: inside;
        width: calc(70% - 35px);
    
        @include max-screen(768px) {
            width: 100%;
        }
    }
    
    &.modal--form .product-list li b {
        display: inline-block;
        width: 79px;
    
        @include max-screen(480px) {
            margin-left: 8px;
        }
    }
    
    &.modal--form .product-list li span {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-overflow: ellipsis;
        top: 2px;
        white-space: nowrap;
        width: 257px;
        
        @include max-screen(768px) {
            width: calc(100% - 18px);
        }
        
        @include max-screen(480px) {
            width: 151px;
        }
    }
    
    &.modal--form .product-list .product-item__remove {
        cursor: pointer;
        fill: $error;
        height: 10px;
        margin-left: 16px;
        transition: transform 0.2s $transitionPrimary;
        width: 10px;
        
        @include max-screen(480px) {
            margin-left: 8px;
        }
    }

    .field-recaptcha {
        padding: 0 0 20px 0;
    }
}
