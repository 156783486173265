.customer-account-edit,
.customer-account-index,
.customer-address-index,
.customer-address-form,
.newsletter-manage-index {
    .sidebar.sidebar-additional {
        margin-top: 0;
    }

    .column.main .flex.wrapper {
        width: 100%;
        @include max-screen(768px) {
            flex-direction: column;
        }
        .block-content {
            display: flex;
            flex-direction: column;
            width: 50%;

            @include max-screen(768px) {
                width: 100%;
            }

            .box {
                width: 100%;
            }
        }
    }
}

.dashboard--notification {
    align-items: center;
    background: $grayBG;
    border-radius: 4px;
    display: flex;
    flex: 1 100%;
    justify-content: space-between;
    margin-bottom: 35px;
    padding: 20px;

    h2 {
        font-size: em(21, 14);
        font-weight: 700;
        text-transform: none;
  
        @include max-screen(560px) {
            font-size: em(20, 16);
        }
    }

    a {
        color: $white;
    }
}

.order-history {
    background-color: $blueTertiary;
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 20px;

    .table-wrapper {
        align-items: flex-end;
        display: flex;
        flex-direction: column;

        a.action {
            margin-top: 20px;
        
            .link-orderhistory {
                color: $blue;
                font-family: "Proxima Nova";
                font-weight: 700;
                margin: 20px 20px 0 0;
                text-transform: uppercase;
            }
        }

        .order-history__table {
            th,
            td {
                border-bottom: 1px solid $gray;
                padding: 10px;
                text-align: left;
            }
        
            th {
                border-bottom: 1px solid $blue;
                font-weight: 600;
                text-transform: capitalize;
            }

            th.col.total {
                width: 50px;
            }
        
            a {
                color: $blueSecondary;
                text-decoration: none;
            }
          }
    }
}

.block,
.box {
    .box-actions {
        margin-top: 15px;

        .btn {
            margin-right: 15px;
        }
    }
}



.block,
.box-newsletter,
.box-shoppingcart {
    .block-title,
    .box-title {
        font-family: "Proxima Nova";
        font-weight: 600;
        text-transform: uppercase;
    }
}

.box-newsletter {
    background: $grayBG;
    border-radius: 4px;
    padding: 20px;

    a {
        color: $white;
    }
}

.box-shoppingcart {
    background: $blueSecondary;
    border-radius: 4px;
    color: $white;
    padding: 20px;
  
    h3 {
        color: $white;
    }

    .account-information__desc span {
        color: $hp-gold;
    }
}

.customer-address-form .input-cnt.street .control {
    label.label {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: auto;
        margin: 20px 0 0 0;
        overflow: hidden;
        padding: 0;
        position: static;
        width: auto;
    }
}

.customer-account-createpassword,
.customer-account-edit,
.customer-account-forgotpassword,
.customer-account-index,
.customer-address-index,
.customer-address-form,
.newsletter-manage-index {
    .page-main {
        .btn {
            color: $white;
            margin-right: 20px;
    
            &:hover {
                background: $blueSecondary;
                border: none;
            }
        }
        a:hover {
            text-decoration: none;
        }
        .box-shoppingcart .btn.btn--gold:hover {
            background: $hp-gold;
        }
    }
}
.sales-order-view {
    .payment-method,
    .table-order-items {
        .title {
            font-size: 14px;
        }
    }
}

.box-address-billing address,
.box-address-shipping address,
.box-billing-address address,
.box-shipping-address address {
    display: flex;
    min-height: 200px;

    .left,
    .right {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
}
.gw-euvat-field .message-success {
    top: 31px;
    height: 26px;
    position: absolute;
    line-height: 1rem;
}
