.bellen-mag-contact {
    h3 {
        color: $hp-about-light-blue;
        font-size: em(24, 14);
        font-weight: 600;
        margin-top: 5px;
        text-transform: initial;
    }
  
    .bellen-mag-contact__blocks {
        margin-left: -15px;
        margin-right: -15px;
  
        @include max-screen(1280px) {
            margin-left: -10px;
            margin-right: -10px;
        }
  
        .bellen-mag-contact__block {
            border: solid 2px $hp-about-light-blue;
            border-radius: 11px;
            margin-left: 7.5px;
            margin-right: 7.5px;
            min-width: 370px;
            padding: 24px;
            width: calc(33.3333% - 15px);
    
            @include max-screen(1280px) {
                margin-bottom: 10px;
                margin-left: 10px;
                margin-right: 10px;
                padding: 15px;
                width: calc(50% - 20px);
            }
    
            @include max-screen(568px) {
                width: calc(100% - 20px);
            }
    
            .bellen-mag-contact__block-content {
                a,
                span {
                    color: $hp;
                    display: block;
                    font-size: em(16, 14);
                    font-weight: 500;
                    margin-left: 15px;
                    text-decoration: none;
        
                    &:first-child {
                        color: $hp-about-light-blue;
                        font-weight: 900;
                        font-size: em(15, 14);
                        letter-spacing: 1px;
                        text-transform: capitalize;
                    }
        
                    &.block__email {
                        margin-top: 8px;
                    }
                }
        
                a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
