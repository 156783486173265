@mixin lib-font-face(
    $family-name,
    $font-path,
    $font-weight: normal,
    $font-style: normal
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}
@include lib-font-face(
    $family-name: $icons__font-name,
    $font-path  : $icons__font-path,
    $font-weight: normal,
    $font-style : normal
);
