.switch-container {
    display: flex;

    span {
        line-height: 23px;
    }
}

.switch {
    display: inline-block;
    height: 25px;
    margin-left: 5px;
    position: relative;
    width: 51px;
}
  
.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

.slider {
    background-color: $grey;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
}

.slider:before {
    content: "";
    background-color: white;
    border: 1px solid white;
    bottom: 3px;
    height: 19px;
    left: 3px;
    position: absolute;
    transition: 0.4s;
    width: 19px;
}

input:checked + .slider {
    background-color: $green;
}

input:focus + .slider {
    box-shadow: 0 0 1px $hp-green;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.slider.round {
    border-radius: 19px;
}

.slider.round:before {
    border-radius: 50%;
}

.page-footer .incbtw,
.top-bar__desc .incbtw {
    display: none;
}
