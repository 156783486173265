.page-wrapper > .breadcrumbs {
    margin-bottom: 0;
    margin-top: 35px;
    padding: 0;
    width: 1260px;

    @include max-screen(768px) {
        display: none;
    }
}
  
.sub-navigation {
    @extend .clearfix;
    background: $blueTertiary;
    flex: 1 100%;
    margin: 0;
    
    @include max-screen(768px) {
        margin: 0 auto 20px;
        width: 90%;
    }

    a {
        display: block;
        float: left;
        padding: 15px 10px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        &.sub-navigation__home {
            padding: 15px;
      
            @include max-screen(560px) {
                display: none;
            }
      
            &::before {
                content: "\f015";
                font-family: "FontAwesome";
            }
      
            &::after {
                content: "\f105";
                font-family: "FontAwesome";
                margin: 0 0 0 20px;
            }
        }

        &.sub-navigation__category {
            font-weight: 700;            
            height: 50px;
            margin-right: 15px;
            text-transform: uppercase;
      
            @include max-screen(768px) {
                margin-right: 0;
            }
      
            @include max-screen(480px) {
                display: none;
            }
        }

        &::after {
            content: "\f105";
            font-family: "FontAwesome";
            font-size: em(14, 14);
            margin: 0 0 0 20px;
        }
    }

    ul li {
        &:last-child {
            a {
                &::after {
                    content: "";
                }
            }
        }
    }

    .sub-navigation__items {
        @include max-screen(768px) {
            display: none;
            float: right;
        }
    
        li {
            a {
                color: $blueSecondary;
                font-weight: 400;
                padding: 15px 15px;
                text-transform: none;
            }
        
            &.is-active {
                a {
                    font-weight: bold;
                }
            }
        }
    }

    .item {
        display: inline-block;
        &.home {
            &:after {
                display: none;
            }
        }
    }
}

.breadcrumbs {
    .items > li {
        vertical-align: unset;
    }
    strong {
        font-weight: 700;
    }
}

.breadcrumbs {
    .items {
        @extend .clearfix;
        background: $blueTertiary;
        flex: 1 100%;
        margin: 0;
    
        .item {
            color: $hp;
            font-size: 14px;
            height: auto;
            margin-right: 15px;
            text-transform: uppercase;
    
            @include max-screen(768px) {
                margin-right: 0;
            }
        
            @include max-screen(480px) {
                display: none;
            }
    
            &.home {
                padding: 15px;
    
                a {
                    opacity: 0;
                    position: absolute;
                    visibility: hidden;
                }
        
                @include max-screen(560px) {
                    display: none;
                }
        
                &::before {
                    content: "\f015";
                    font-family: "FontAwesome";
                }
        
                &::after {
                    content: "\f105";
                    font-family: "FontAwesome";
                    font-size: em(14, 14);
                    margin: 0 0 0 20px;
                }
            }
        }
    }
    .item:not(:last-child):after {
        display: none;
    }
}
