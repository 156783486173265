.category-consument {
    .brands-images {
        justify-content: space-between;
        width: 750px;

        @include max-screen(900px) {
            width: 100%;
        }

        .consumer-cat-container-brands {
            border: 2px solid $blueTertiary;
            cursor: pointer;
            opacity: 0.8;
            width: 200px;

            @include max-screen(768px) {
                width: 30%;
            }

            &:hover {
                border: 2px solid $blueSecondary;
            }
        }

        .consumer-cat-container-brands.selected {
            border: 3px solid $blueSecondary;
            opacity: 1;
        }
    }
}
