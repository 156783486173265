// HEADER SECTION
.page-header {
    background: $blueTertiary;
    z-index: 3;

    @include max-screen(768px) {
        background: white;
        border: 0;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }

    .header.content {
        display: flex;
        max-width: 1260px;
        padding: 0;

        @include max-screen(768px) {
            visibility: hidden;

            .minicart-wrapper,
            .logo {
                display: none;
            }

            .block-search {
                margin: 0 21.125px;
                padding: 0 0 10px 0;
                visibility: visible;
                width: 100%;

                .block-content {
                    width: 100%;
                }

                form.minisearch {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                }

                .field.search {
                    flex-grow: 1;
                    max-width: 290px;

                    label {
                        display: none;
                    }

                    .control {
                        border: 0;
                        margin: 0;
                        width: 100%;
                    }
                }

                .actions {
                    height: 30px;
                    margin: 15px 0;
                    width: 65px;

                    button {
                        display: block;
                        position: static;
                    }
                }
                .action.search[disabled] {
                    opacity: 0.8;
                }

                .search-autocomplete {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }

    .logo,
    .block-search,
    .minicart-wrapper {
        padding: 35px 0;
        z-index: 0;
        @include max-screen(768px) {
            padding: 0;
        }
    }

    .logo {
        display: flex;
        margin: 0;
        padding: 15px;
        max-width: 22%;

        img {
            position: relative;
            right: 15px;
            min-width: 162px;
        
            @media only screen and (max-width: 767px) {
                right: auto;
                min-width: 20px;
            }
        }     
    }

    .logo--mobile {
        display: none;
    }

    .block-search {
        display: flex;
        float: none;
        margin-left: auto;
        order: 2;
        width: auto;

        .form.minisearch {
            flex: 1;
            width: 475px;
            margin: 0;

            .control {
                padding: 0;
            }
    
            @include max-screen(768px) {
                width: 100%;
            }
    
            input {
                background: #fafafa;
                border: 1px solid $blueSecondary;
                border-radius: 4px;
                @include max-screen(768px) {
                    border-radius: 4px 0 0 4px;
                }
                color: #9b9b9b;
                height: 42px;
                line-height: 40px;
                position: static;
                width: 100%;
    
                &:focus {
                    color: $blue;
                }
            }
            
            .control {
                position: relative;

                .search-autocomplete {
                    background: $white;
                    padding: 20px;
                    top: 42px;
                    z-index: 5;

                    dd {
                        cursor: pointer;

                        &:hover {
                            text-decoration: underline;
                        }

                        span.amount {
                            visibility: hidden;
                        }
                    }
                }
            }
    
            button {
                background: $blueSecondary;
                border-radius: 0 4px 4px 0;
                font-weight: 600;
                height: 42px;
                min-width: 1px;
                padding: 0 15px;
                position: absolute;
                right: 0;
                top: 35px;

                &:before {
                    display: none;
                }
    
                span {
                    @include max-screen(768px) {
                        display: none;
                    }
                }
            }
    
            .search-autocomplete {
                z-index: 4;

                .product-shop .price-box {
                    .item__price-old {
                        display: none;
                    }
                }
            }
    
            .searchautocomplete-loader {
                position: absolute;
                width: 30px;
                height: 30px;
                top: 5px;
                right: 27px;
            }
    
            ul {
                display: block;
                background: #fff;
                border-radius: 4px;
                border: 1px solid $gray;
    
                li {
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid $gray;
                    transition: all 0.2s $transitionPrimary;
                    cursor: pointer;
    
                    &:last-of-type {
                        border-bottom: 0;
                    }
    
                    &:hover {
                        background: $grayBG;
                    }
    
                    .suggestions-productimage {
                        margin-right: 12px;
    
                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }
    }

    .minicart-wrapper {
        display: flex;
        float: none;
        margin: 0 0 0 15px;
        order: 3;
        position: unset;

        .action.showcart {
            align-items: center;
            display: flex;
            height: 42px;
            padding: 0;
            width: 124px;

            &:before {
                display: none;
            }
            .btn {
                padding: 12px;
                box-shadow: 0 2px 0 0 $green-shopping-cart;
                position: relative;
                transition: 0.2s ease-in-out all;

                &:hover {
                    background-color: $green-shopping-cart;
                }

                .shopping-cart__amount {
                    background-color: $green-shopping-cart;
                    border-radius: 50%;
                    height: 11px;
                    font-size: 7px;
                    font-weight: 800;
                    line-height: 10px;
                    padding: 1px;
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    width: 11px;
                }
            }

            .counter.qty {
                background: none;
            }
        }
    }
}

.top-container {
    background: #fff;
    border-bottom: 2px solid $blueTertiary;
    max-width: unset;
    padding: 0;
    position: static;
    z-index: 2;

    @include max-screen(768px) {
        display: none;
    }

    .wrapper {
        background-color: $white;
        position: relative;
        width: 100%;

        &.flex--space-between {
            position: static;
            width: 1260px;

            @include max-screen(1268px) {
                width: 100%;
            }
        }

        .header-navigation__list {

            .header-navigation__list-item {
                margin-right: 32px;

                .header-navigation__list-item-title {
                    position: relative;
                    display: inline-block;
                    padding-top: 17px;
                    padding-bottom: 15px;
                    padding-right: 14px;
                    font-size: 1em;
                    color: $blue;
                    font-weight: bold;
                    border-bottom: 2px solid $white;
                    transition: 0.2s ease-in-out all;

                    &:hover {
                        cursor: pointer;
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        width: 6px;
                        height: 6px;
                        right: 0;
                        top: calc(50% - 3px);
                        border-bottom: 2px solid $hp-blue;
                        border-right: 2px solid $hp-blue;
                        transform: rotate(45deg);
                        transition: 0.2s ease-in-out all;
                    }
                    
                    a {
                        text-decoration: none;
                        &:visited,
                        &:hover {
                            color: $hp;
                            opacity: 0.8;
                        }
                    }
                }
                .header-navigation__list-item-title--no-arrow {
                    padding-right: 0;
                    text-decoration: none;
                    &::after {
                        display: none;
                    }
                }

                .header-navigation__categories-container {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin-left: -5000px;
                    background-color: $white;
                    border-top: solid 2px $blueTertiary;
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.5s ease-in-out all;
                    width: 100%;

                    .wrapper.flex {
                        width: 1260px;
                    }

                    .header-navigation__categories {

                        .header-navigation__category-item {
                            display: flex;
                            min-width: 225px;
                            padding-top: 16px;
                            padding-bottom: 16px;
                            border-right: 2px solid $blueTertiary;
                            border-bottom: 2px solid $blueTertiary;
                            transition: 0.2s ease-in-out all;

                            &:hover:not(.is-active) {
                                cursor: pointer;
                                color: $hp-blue;
                                font-weight: bold;
                            }

                            &.is-active {
                                border-right: 0 solid $blueTertiary;
                                color: $hp-blue;
                            }

                            &:last-child {
                                border-bottom-right-radius: 4px;
                            }

                            img {
                                margin-right: 10px;
                                height: 20px;
                            }

                        }
                    }

                    .hn__category__content-container {
                        position: relative;
                        flex: 1;
                        min-height: 500px;
                        padding: 24px;

                        .hn__category__content-section {
                            display: flex;
                            flex: 1;
                            height: 0;
                            visibility: hidden;
                            opacity: 0;

                            &.is-active {
                                height: auto;
                                visibility: visible;
                                opacity: 1;
                                flex-wrap: wrap;
                            }

                            .hn__subcategory__list-container {
                                width: calc(33.3333% - 40px);
                                margin-left: 20px;
                                margin-right: 20px;

                                .hn__subcategory__list {
                                    .hn__subcategory__title {
                                        margin-bottom: 16px;
                                        font-weight: bold;
                                    }

                                    .hn__subcategory__list-item {
                                        margin-bottom: 12px;
                                        font-size: 1em;
                                        font-weight: normal;

                                        a {
                                            text-decoration: none;
                                            font-weight: normal;

                                            &.f-bold {
                                                font-weight: bold;
                                            }

                                            img {
                                                max-width: 40px;
                                                margin-right: 5px;
                                                filter: grayscale(1.0);
                                            }
                                        }
                                    }
                                }
                            }

                            .header-navigation__category__usp-container {
                                bottom: 45px;
                                width: 408px;
                                height: 160px;
                                padding: 16px 20px;
                                background-color: $hp-blue;
                                box-shadow: 0 2px 0 0 $blueQuarternary;
                                margin-left: auto;
                                cursor: grab;

                                .usp {
                                    display: block;
                                    color: $white;
                                    font-family: $titleStack;
                                    width: 70%;

                                    &.usp--big {
                                        font-size: em(18, 14);
                                        font-weight: 900;
                                        text-transform: uppercase;
                                    }

                                    &.usp--small {
                                        font-size: em(14, 14);
                                        font-weight: normal;
                                        line-height: 18px;
                                        margin-top: 12px;
                                    }
                                }

                                img {
                                    position: absolute;
                                    right: 35px;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                }

                &.is-active {
                    .header-navigation__list-item-title {
                        border-bottom: 2px solid $hp-blue;

                        &::after {
                            top: calc(50% - 2px);
                            transform: rotate(-135deg);
                        }
                    }

                    .header-navigation__categories-container {
                        opacity: 1;
                        visibility: visible;
                        margin-left: 0;
                    }
                }
            }
        }

        .header.links {
            display: flex;
            
            >li {
                margin-left: 16px;
                padding-top: 17px;
                padding-bottom: 17px;
                font-size: 0.85em;
                font-weight: normal;

                &.account {
                    position: relative;
                }

                >a {
                    text-decoration: none;
                }

                .login-widget {
                    right: 0;
                    font-size: 1.15em;
                }
            }
            
            .lang-select {
                cursor: pointer;
                position: relative;
                &:after {
                    color: $hp-blue;
                    content: "\f107";
                    display: inline-block;
                    font-family: "FontAwesome";
                    margin-left: 5px;
                    transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
                }
                .language-widget {
                    background: $white;
                    display: none;
                    padding: 5px;
                    position: absolute;
                    right: 11px;
                    top: 4px;
                    width: 25px;
                    li {
                        padding: 8px 0;
                        a {
                            text-decoration: none;
                            &:hover {
                                color: $hp-blue;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.login-widget {
    background: #fff;
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.36);
    display: none;
    margin-top: 40px;
    min-width: 300px;
    position: absolute;
    right: 190px;
    width: 400px;
    z-index: 4;
  
    fieldset {
        padding: 20px 20px 0;
    }

    input {
        width: 100%;
        margin-bottom: 10px;
    }
  
    &.is-active {
        display: block;
    }

    &__forgot {
        float: right;
        font-size: 0.875em;
    }
  
    &__loading {
        background: #fff;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.2s $transitionPrimary;
        visibility: hidden;
  
        &.is-active {
            opacity: 0.9;
            visibility: visible;
        }
  
        .dot {
            width: 10px;
            height: 10px;
            border: 2px solid $grayTertiary;
            border-radius: 50%;
            float: left;
            margin: 5px;
            transform: scale(0);
            animation: fx 1s ease infinite;
    
            &:nth-child(2) {
                animation: fx 1s ease infinite;
                animation-delay: 0.3s;
            }
    
            &:nth-child(3) {
                animation: fx 1s ease infinite;
                animation-delay: 0.6s;
            }
        }
    }
  
    &__title {
        display: block;
        font-family: $titleStack;
        font-size: em(18, 14);
        font-weight: 700;
        text-transform: uppercase;
    
        &--noaccount {
            color: $blueSecondary;
        }
    }
  
    &__noaccount {
        background: $blueLight;
        padding: 20px 20px;
    }
  
    &__text {
        color: $blue;
    }
  
    &__usps {
        margin: 10px 0 20px 0;
  
        li {
            color: $blue;
    
            &::before {
                content: '\f00c';
                color: white;
                font-family: 'FontAwesome';
                margin-right: 10px;
                background: $green;
                border-radius: 7px;
            }
    
            .green {
                color: $green;
                font-weight: bold;
            }
        }
    }
  
    .btn-login {
        margin-bottom: 20px;
        width: 200px;
        &:hover {
            background: $hp-gold;
            border: 0;
        }
    }

    .btn-register {
        color: $white;
    }
    
    &__buttons {
        @extend .clearfix;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        float: left;
        width: 100%;
        margin: 20px 0 20px 0;
      
  
        .btn {
            color: white;
            float: left;
            width: calc(50% - 6px);
            &.is-active {
                background-color: $blue;
            }
            &:first-child {
                margin-right: 10px;
            }
        }
  
        .btn-register {
            color: $white;
            margin-right: 8px;
        }
    }
}

.panel .panel__background-overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.36);
    visibility: hidden;
    opacity: 0;
    transition: 500ms ease-in-out all;
    z-index: 1;

    &.is-active {
        visibility: visible;
        opacity: 1;
    }
}

.page-header .minicart-wrapper .action.showcart {
    border-radius: 3px;
}

.page-header .minicart-wrapper {
    margin: 0 0 0 10px;
}

.mobile-shopping-cart {
    position: relative;
    right: 2px;
    max-width: 125%;
}

.counter-indicator {
    position: absolute;
    right: 3px;
    bottom: 1px;
    color: $white;
}
