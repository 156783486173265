.checkout-index-index {
    .page-header .logo {
        @include max-screen(768px) {
            display: none;
        }

        @include max-screen(1268px) {
            margin-left: 35px;
        }
    }

    .page-header .logo.logo--mobile {
        @include max-screen(768px) {
            display: block;
            padding: 20px 0;
            width: 60px;
        }
    }

    .page-header .header.content {
        @include max-screen(768px) {
            visibility: visible;
        }
    }

    .checkout-header {
        align-items: center;
        display: flex;
        margin-top: 30px;

        .page-title {
            color: $hp-about-light-blue;
            font-size: em(32, 14);
            font-weight: 800;
        }

        img {
            margin-left: 20px;
            width: 30px;
        }
    }

    .columns .column.main {
        @include max-screen(768px) {
            padding-bottom: 240px;
        }
    }
}

.checkout-container {
    .opc-wrapper {
        width: 65%;

        @include max-screen(768px) {
            width: 100%;
        }

        #shipping {
            min-height: 260px;
        }

        .shipping-address-item.selected-item {
            border-color: $hp-blue;

            &::after {
                background: $hp-blue;
            }
        }
    }

    div#iosc-summary span.step-title,
    li.checkout-payment-method div.step-title,
    li#billing div.step-title,
    li#opc-shipping_method div.step-title,
    li#shipping div.step-title {
        font-family: "Proxima Nova";
        font-size: em(16, 14);
        font-weight: 600;
        padding-left: 0;
        text-transform: uppercase;
    }

    .checkout-shipping-address {
        .optional {
            display: inline-block;
            font-size: 11px;
            margin-left: 5px;
        }
        span.red {
            color: red;
            display: inline-block;
        }
    }

    .table-checkout-shipping-method .row {
        cursor: pointer;
    }

    input[type="checkbox"],
    input[type="radio"] {
        appearance: auto;
        display: inline-block;
        margin-right: 10px;
    }

    input[type="checkbox"] {
        appearance: checkbox;
        -webkit-appearance: checkbox;
    }

    input[type="radio"] {
        appearance: radio;
        -webkit-appearance: radio;
    }

    input[type="checkbox"].required-captcha {
        display: none;
    }

    form {
        .field .message.warning {
            margin-top: 40px;
        }

        label {
            margin: 0 10px 0 0;
            width: auto;

            @include max-screen(1280px) {
                width: 85%;
            }

            @include max-screen(920px) {
                width: 80%;
            }

            @include max-screen(768px) {
                width: auto;
            }
        }
    }

    .form.payments {
        .fieldset > .field.input--factuur-email > .control {
            overflow: unset;
        }
        .input--factuur-email {
            input {
                width: 80%;
            }

            .field-tooltip {
                display: inline-block;
                margin-left: 10px;
                position: relative;
                top: 5px;

                span.label {
                    display: none;
                }

                .field-tooltip-content {
                    @include max-screen(768px) {
                        left: -280px;
                        top: 50px;
                        &:before {
                            display: none;
                        }
                    }
                }
            }

            .field-note {
                margin-top: 20px;
            }
        }
    }

    .opc-payment {
        padding-top: 20px;

        .field.choice .field-tooltip {
            margin-left: 0;
        }
    }

    .opc-payment .mollie-payment-icon {
        width: 40px;
    }

    .opc-payment .items {
        background: none;
    }

    .opc-block-summary .product-item .product-item-name-block {
        display: block;
    }

    .minicart-items .product-item-details .details-qty.visible {
        display: flex;
        float: left;

        .label {
            margin-right: 5px;
        }
    }

    .opc-block-summary .product-item .qtybuttons {
        .add::before {
            color: $hp-blue;

            &:hover {
                color: $blue;
            }
        }
    }

    .discount .title {
        font-size: 14px;
    }

    .form-discount {
        .actions-toolbar {
            float: none;
            margin-top: 5px;
            width: unset;

            .action-cancel {
                min-height: 41px;
            }
        }
        .payment-option-inner {
            width: 100%;
        }
    }

    .iosc-place-order-container p {
        text-align: left;
    }

    div.iosc-place-order-container button.iosc-place-order-button {
        @extend .btn;
        background: $green;
        background-image: none;
        border-radius: 4px;
        box-shadow: 2px 2px 0 0 $blue;
        font-family: "Proxima Nova";
        font-size: em(16, 14);
        font-weight: 700;
        padding: 13.5px 15px;
        width: 100%;
        
        &:active,
        &:hover,
        &:visited {
            background: $green;
            border: none;
        }
    }
}

.column:not(.sidebar-additional) form .actions-toolbar {
    margin-left: 0;
}

.greyed-out-method {
    opacity: 0.6;
}

.mage-error {
    color: #e02b27;
}

.checkout-payment-method .payment-method+.payment-method .payment-method-title {
    display: flex;
}

