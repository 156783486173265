.blog-overzicht {
    h1,
    h3 {
        color: $hp-darkblue;
        font-family: 'Proxima Nova';
        font-size: 4em;
        font-weight: 900;
        line-height: 1.2;
	    text-transform: none;
    }
    p {
        color: $hp-darkblue;
        font-size: 1.5em;
        font-weight: normal;
        max-width: 700px;
    }
}
.blog-overzicht-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.blog-overzicht-grid .blog {
    border: 6px solid $gold;
    border-radius: 6px;
    box-sizing: border-box;
    color: $hp-darkblue;
    padding: 35px;
    flex: 1 1 30%;
    text-decoration: none;
}
.blog-overzicht-grid .blog--inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.blog-overzicht-grid img {
    height: auto;
    width: 150px;
}
.blog-overzicht-grid .blog h3 {
    font-size: 1.625em;
    font-weight: bold;
}
.blog-overzicht-grid .blog p {
    font-size: 1.1em;
    text-align: left;
}
.blog-overzicht-grid .btn {
    cursor: pointer;
    display: inline-block;
    margin-top: auto;
    text-align: center;
    width: 150px;
}
