.wrapper.trackandtrace {
    background: $hp-gold;
    border-radius: 4px;
    margin-bottom: 40px;
    padding: 20px;
    text-transform: uppercase;

    .flex {
        align-items: center;

        p {
            line-height: unset;
            margin-right: 15px;

            a {
                text-transform: none;
            }

            &.bold {
                font-weight: 700;
            }
        }

        .btn {
            color: white;
        }
    }
}
