.cms-merken-europroducts, 
.cms-merken-euro-products {
    .wrapper--flex {
        flex-flow: initial;
        flex-wrap: nowrap;
  
    }
    .wrapper.clearfix.wrapper--category {   
        max-width: none;
        width: 100%;
    }
    .btn.btn--green {
        @include button;
        background-color: $euroGreen;
        box-shadow: 1px 1px 0 0 $euroGreen;
        color: $white;
    }
    .sidebar--left {
        .sidebar__container {
            position: sticky;
            top: 50px;
            @include max-screen(1400px) {
                top: 50px;
            }

            .sidebar-block {
                border: 1px solid $euroProdBlue;
                border-radius: 3px;
                padding: 35px;
                @include max-screen(1400px) {
                    padding: 25px;
                }
                h3 {
                    color: $euroProdBlue;
                    font-size: em(20, 14);
                    font-weight: bold;
                    letter-spacing: 1.3px;
                    line-height: 1.2;
                }
                p {
                    font-size: em(16, 14);
                }
                .btn.btn--orange {
                    background-color: $euroProdBlue;
                    width: 100%;
                }
                .sidebar-block-check {
                    span {
                        font-size: em(10, 14);
                        padding-left: 20px;
                        position: relative;
                        line-height: 1.7;
                        &::before {
                            background-color: $white;
                            border-radius: 50%;
                            bottom: 0;
                            content: '';
                            height: 12px;
                            left: 0;
                            position: absolute;
                            width: 12px;
                        }
                        &::after {
                            border-bottom: 1px solid $black;
                            border-left: 1px solid $black;
                            bottom: 5px;
                            content: '';
                            height: 4px;
                            left: 2px;
                            position: absolute;
                            transform: rotate(-45deg);
                            width: 8px;
                        }
                    }
                }
            }
            .kiyoh-block {
                background: none;
                color: $euroProdBlue;
                margin-top: 35px;
                @include max-screen(1400px) {
                    margin-top: 25px;
                    padding: 25px;
                }

                h3 {
                    font-size: em(20, 14);
                    letter-spacing: 1.3px;
                    line-height: 1.2;
                    text-transform: uppercase;
                }
            }
        }
    }
    .content--left {
        h1, 
        h2, 
        h3, 
        h4, 
        h5 {
            color: $euroProdBlue;
        }
        h1 {
            font-size: em(60, 14);
            font-weight: 800;
        }
        h2, 
        h3 {
            font-size: em(28, 14);
            font-weight: 900;
        }
        h4, 
        span.title--subtitle {
            color: $euroProdLightBlue;
            font-family: $titleStack;
            font-size: em(24, 14);
            font-weight: 600;
            text-transform: none;
        }
        h5 {
            font-size: em(28, 14);
            font-weight: 800;
            text-transform: uppercase;
        }
        .label-detail--description {
            @include max-screen(768px) {
                header {
                    flex-direction: column;
                    img {
                        margin-top: 20px;
                    }
                }
            }
            div {
                @include max-screen(1024px) {
                    flex-direction: column;
                }
            }
            p.subEuroTitle {
                color: $euroProdLightBlue;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.8px;
                margin: 0;
                text-transform: uppercase;
            }
            h1 {
                color: $euroProdBlue;
                text-transform: none;
            }
            header img {
                height: 74px;
                width: 180px;
            }
            img.label-detail--description-content {
                height: 275px;
                width: 413px;
            }
            p { 
                margin-right: 20px;
            }
        }
        .europroducts--quartzline {
            .yellow {
                color: $euroProdYellow;
            }
            span.title--subtitle {
                font-size: em(14, 14);
                text-transform: uppercase;
            }
        }
        .europroducts--jouwbranche {
            p { 
                max-width: 700px;
            }
            ul.expert__products-categories__filter-list {
                margin-left: -8px;
                margin-right: -8px;
        
                li {
                    border: 2px solid $hp-about-light-blue;
                    border-radius: 3px;
                    color: $hp-about-light-blue;
                    font-size: em(14, 14);
                    font-weight: bold;
                    margin-bottom: 16px;
                    margin-left: 8px;
                    margin-right: 8px;
                    letter-spacing: 0.7px;
                    padding: 12px 32px;
                    transition: 0.2s ease-in-out all;
            
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                    }
                    &.is-active {
                        background-color: $hp-about-light-blue;
                        color: $white;
                    }
                }
            }
            .expert__grid {
                >h4 {
                    color: $euroProdBlue;
                    font-size: em(28, 14);
                    font-weight: bold;
                    text-transform: initial;
            
                    span {
                        color: $euroProdYellow;
                    }
                }
                p {
                    max-width: 769px;
                }
                .product-grid--four {
                    li {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .europroducts--poetslappen {
            p {
                max-width: 430px;
            }
            h4 {
                color: $euroProdBlue;
                font-size: em(28, 14);
                font-weight: 900;
                text-transform: uppercase;
            }
            span.title--subtitle {
                font-size: em(14, 14);
                font-weight: 600;
                text-transform: uppercase;
            }
            @include max-screen(1024px) {
                div {
                    flex-direction: column;
                }
                img {
                    margin-top: 40px;
                }
            }
        }
        .europroducts--schermen {
            h5 {
                font-weight: 900;
            }
            p {
                max-width: 700px;
            }
        }
        .pre-footer {
            flex-direction: column;
            text-align: center;
        }
        .bellen-mag-contact {
            .bellen-mag-contact__blocks {
                .bellen-mag-contact__block-content {
                    span, 
                    svg {
                        color: $euroProdBlue;
                    }
                }
                .bellen-mag-contact__block {
                    border-color: $euroProdBlue;
                }
            }
        }
    }
} 
  