$vendorGreen: #009385;

.cms-merken-vendor {
    overflow-x: hidden;

    main {
        @include max-screen(768px) {
            margin-top: 0;
        }

        @include max-screen(768px) {
            overflow-x: hidden;
        }
    }

    .wrapper--flex {
        flex-flow: initial;
        flex-wrap: nowrap;
    }

    .wrapper.clearfix.wrapper--category {
        max-width: none;
        width: 100%;
    }

    .vendor__page-hero {
        background-position: 50% 60%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 544px;
        width: 100%;

        @include max-screen(768px) {
            height: 250px;
            margin-bottom: 0;
        }
    }

    .sidebar-main {
        position: sticky;
        top: 40px;

        @include max-screen(1400px) {
            top: 50px;
        }

        @include max-screen(768px) {
            position: absolute;
            visibility: hidden;
        }

        .sidebar-block {
            border: 1px solid $white;
            border-radius: 3px;
            padding: 35px;

            @include max-screen(1400px) {
                padding: 25px;
            }

            h3 {
                font-size: em(20, 14);
                font-weight: bold;
                letter-spacing: 1.3px;
                line-height: 1.2;
            }

            p {
                font-size: em(16, 14);
            }

            .sidebar-block-check {
                span {
                    font-size: em(10, 14);
                    line-height: 1.7;
                    padding-left: 20px;
                    position: relative;

                    &::before {
                        background-color: $white;
                        border-radius: 50%;
                        bottom: 0;
                        content: '';
                        height: 12px;
                        left: 0;
                        position: absolute;
                        width: 12px;
                    }

                    &::after {
                        border-bottom: 1px solid $black;
                        border-left: 1px solid $black;
                        bottom: 5px;
                        content: '';
                        height: 4px;
                        left: 2px;
                        position: absolute;
                        transform: rotate(-45deg);
                        width: 8px;
                    }
                }
            }
        }

        .kiyoh-block {
            background: none;

            @include max-screen(1400px) {
                margin-top: 25px;
                padding: 25px;
            }

            h3 {
                color: $hp;
                font-size: em(20, 14);
                letter-spacing: 1.3px;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }

    .vendor-detail {
        font-family: $titleStack;
        max-width: 900px;

        h1,
        h2,
        h3,
        h5 {
            color: $vendorGreen;
            font-family: $titleStack;
            text-transform: initial;
        }

        h4,
        h6, 
        span.title--subtitle {
            color: #ec691d;
            font-family: $titleStack;
            font-weight: 700;
            letter-spacing: 0.8px;
            text-transform: uppercase;
        }

        h1 {
            font-size: em(60, 14);
        }

        h2 {
            font-size: em(28, 14);
            font-weight: 900;
            text-transform: uppercase;
        }

        h3 {
            font-size: em(28, 14);
            font-weight: 900;
            text-transform: uppercase;
        }

        p {
            font-family: $readStack;
            font-size: em(16, 14);
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            line-height: 1.56;
        }

        a {
            color: $white;
            text-decoration: none;
        }

        .btn.btn--orange {
            background-color: #ec691d;
            border-radius: 3px;
            box-shadow: 1px 1px 0 0 #7b3b15;
            font-family: $readStack;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: normal;
            min-height: 42px;
            padding: 12px 32px;
            transition: 0.2s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }

        .topProducten404 {
            h4 {
                color: $hp-lightblue;
            }

            .product-grid {
                margin-bottom: 0;
            }

            li.item {
                @include max-screen(1179px) {
                    margin-bottom: 0;
                }
            }
        }

        .vendor-detail_introduction {
            display: flex;

            @include max-screen(768px) {
                flex-flow: column;
            }

            .vendor-detail_introduction-content {
                margin-right: 78px;

                @include max-screen(768px) {
                    margin-right: 0;
                }

                h2 {
                    font-weight: bold;
                    letter-spacing: 0.8px;
                    line-height: normal;
                }

                p {
                    max-width: 539px;
                }
            }

            .vendor-detail_introduction-images {
                max-width: 322px;

                @include max-screen(1120px) {
                    margin-right: 0;
                }

                @include max-screen(768px) {
                    margin-top: 20px;
                }

                margin-right: -47px;
            }
        }

        .vendor-detail_cassettes {
            p {
                max-width: 660px;
            }
        }

        .expert-disclaimer {
            position: relative;

            @include max-screen(1280px) {
                &::before {
                    background-color: rgb(240, 240, 240);
                    content: "";
                    height: 3px;
                    left: 0;
                    position: absolute;
                    width: 51px;
                }

                &::after {
                    background-color: rgb(240, 240, 240);
                    content: "";
                    height: 3px;
                    position: absolute;
                    right: -33px;
                    width: 51px;
                }
            }

            .expert-disclaimer__item {
                letter-spacing: normal;
                margin-left: 32px;

                &:before {
                    background-color: $vendorGreen;
                }

                &:before,
                &:after {
                    @include max-screen(1024px) {
                        display: none;
                    }
                }

                @include max-screen(768px) {
                    margin-left: 28px;
                }

                span {
                    color: #ec691d;
                }
            }

            &.expert-disclaimer--modifier {
                &:before,
                &:after {
                    width: 100px;
                }

                .expert-disclaimer__item:first-child {
                    margin-left: 52px;

                    @include max-screen(768px) {
                        margin-left: 28px;
                    }
                }

                .expert-disclaimer__item:last-child {
                    margin-left: 45px;

                    @include max-screen(768px) {
                        margin-left: 28px;
                    }
                }
            }
        }

        .vendor-detail_allproducts {
            p {
                max-width: 660px;
            }
        }
    }

    .bellen-mag-contact {
        .bellen-mag-contact__block {
            border-color: $vendorGreen;

            &:first-child {
                a {
                    color: #34495e;
                    font-family: $readStack;
                    font-size: em(20, 14);
                    font-weight: normal;   
                }
            }

            svg circle {
                color: $vendorGreen;
                fill: $vendorGreen;
            }

            .bellen-mag-contact__block-content {
                span:first-child {
                    color: $vendorGreen;
                }
            }
        }
    }

    .pre-footer {
        h5 {
            text-align: center;
            font-size: em(28, 14);
            font-weight: 900;
        }
    }
}
