.heading {
    margin-bottom: 24px;

    .heading__title {
        color: $hp;
        font-family: $titleStack;
        font-size: em(28, 14);
        font-weight: 900;
        text-transform: uppercase;

        @include max-screen(768px) {
          font-size: em(24, 14);
        }
    }
    .heading__subtitle {
        color: $hp-about-light-blue;
        font-size: em(24, 14);
        font-weight: bold;

        @include max-screen(768px) {
            font-size: em(20, 14);
        }
    }
    .heading__description {
        font-size: em(16, 14);
        font-weight: 400;
        line-height: 1.56;
        margin: 0;
        margin-top: 8px;
    }
      
    .heading--mb8 {
        margin-bottom: 8px;
    }
}
