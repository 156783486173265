.catalog-category-view,
.catalogsearch-result-index {
    .consumer-cat-container {
        display: flex;
        justify-content: space-between;

        @include max-screen(767px) {
            flex-direction: column;
        }

        .kiyoh-block {
            margin-left: 20px;

            @include max-screen(767px) {
                margin-left: 0;
            }
        }
    }
    .consumer-cat-container-brands {
        margin: 30px 0;
    }
    .page-title {
        color: $darkBlue;
        font-size: em(28, 14);
        font-weight: 800;
        margin-bottom: 10px;
    }
    
    .category-description {
        margin: 14px 0 35px 0;

        @include max-screen(767px) {
            height: 60px;
            margin-bottom: 10px;
            overflow: hidden;
        }
    }

    span.readmore {
        color: $hp-blue;

        @include min-screen(767px) {
            display: none;
        }
    }

    .show-category-filter {
        @include max-screen(1024px) {
            display: block;
        }

        display: none;
        margin: 20px 0;
        width: 100%;
    }

    .toolbar-products {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        padding: 0;

        @include max-screen(768px) {
            align-items: flex-start;
            flex-direction: column;
        }

        &::before,
        &::after {
            content: initial;
        }

        .sorter {
            border: 1px solid $grayBorder;
            border-radius: 4px;
            float: none;
            padding: 0 10px;

            .sorter-options {
                appearance: none;
                border: 0;
                font-weight: 700;
                margin: 0 0 0 5px;
                padding: 0;
                width: 110px;
            }

            .sorter-action {
                border: 0;
                text-align: center;
                vertical-align: initial;
                width: 40px;
            }
        }

        .pages {
            display: block;
            margin-top: 10px;

            .items {
                background: none;
            }

            .item {
                color: $grayTertiary;
                font-size: 14px;
            }

            a.page,
            a.page:visited {
                color: $grayTertiary;
            }

            .action.next {
                margin: 0;
            }

            .pages-item-next,
            .pages-item-previous {
                margin: 0;

                a { 
                    border-radius: 4px;
                    border-color: $grayBorder;
                    height: 40px;
                    line-height: 40px;
                }
            }
        }
    }

    .products.wrapper {
        width: 100%;
    }

    .products.wrapper ~ .toolbar .sorter {
        display: block;
    }
}
