.brands {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -35px;
    width: 100%;
  
    li {
        width: 20%;
        float: left;
        margin: 0 auto 35px;
        text-align: center;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    
        @include max-screen(768px) {
            width: 33.33%;
        }
    
        @include max-screen(480px) {
            width: 50%;
        }
  
        a {
            margin: 0 auto;
            display: table;
            transition: all 0.2s $transitionPrimary;
            backface-visibility: hidden;
    
            img {
                float: left;
                display: block;
                max-width: 200px;
        
                @include max-screen(1100px) {
                    max-width: 90%;
                }
            }
        }
    }
}
