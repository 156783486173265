.cms-index-index {
    .wrapper.wrapper--flex {
        width: 100%;
    }
    .notification {
        border-radius: 4px;
        display: block;
        flex: 1 100%;
        margin-bottom: 35px;
        min-height: 80px;
        padding: 20px 0;
        text-align: center;
        text-transform: uppercase;

        @include max-screen(1040px) { padding: 20px; }

        a {
            text-decoration: none;
        }

        h1 {
            display: inline-block;
            font-size: 14px;
            font-style: normal;
        }

        span {
            @include max-screen(578px) {
                margin-left: auto;
                margin-right: auto;
            }
        }
      
        .btn {
            margin-left: 30px;
            @include max-screen(768px) {
                margin-top: 10px;
            }
        }
      
        &--gold {
            background: $gold;
        }
    }

    // Bestverkocht filter
    .filter--product {
        margin-bottom: $gutter;
  
        .filter__title {
            font-size: em(21, 14);
            font-weight: bold;
            text-transform: uppercase;
        }
    }
  
    .filter__list {
        @extend .clearfix;
        margin: $gutter 0 -10px;
        width: 100%;
    
        li {
            border: 2px solid $blueSecondary;
            border-radius: 4px;
            color: $blueSecondary;
            cursor: pointer;
            float: left;
            font-weight: 600;
            margin: 0 10px 10px 0;
            padding: 10px;
            text-transform: uppercase;
            transition: all 0.2s $transitionPrimary;
        
            &:hover {
                background: $blue;
                border-color: $blue;
                color: #fff;
            }
    
            &.is-active {
                background: $blueSecondary;
                color: #fff;
            }
        }
    }

    .image-block {
        @include full-bg;
        padding: $gutter;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        border-radius: 4px;
        background-repeat: no-repeat;
        text-decoration: none;
        min-width: calc(50% - 35px / 2);
        max-width: calc(50% - 35px / 2);
        float: left;
        transition: transform 0.3s $transitionPrimary;
      
        @include max-screen(768px) {
            flex-flow: row wrap;
            width: 100%;
            min-width: 100%;
        }
      
        &:hover {
            transform: scale(1.06) translate3d(0, 0, 0);
        }
      
        @include opacity-bg-image(0.75);
      
        &--first {
            margin: 0 $gutter 0 0;
      
            @include max-screen(768px) {
                margin: 0 $gutter $gutter 0;
            }
        }
      
        h4.image-block__title {
            color: white;
            font-family: $titleStack;
            font-size: em(24, 14);
            font-weight: 700;
            text-transform: uppercase;
        }
      
        &.luchtverfrisser {
            overflow: hidden;
            &:before {
                background: none;
            }
            p {
                font-size: em(14, 14);
            }
            .oval {
                background: $ovalBlue;
                border-radius: 50%;
                height: 350px;
                position: absolute;
                right: -80px;
                top: 90px;
                width: 306px;
            }
            img {
                height: 120px;
                position: absolute;
                right: 0;
                top: 45px;
                @include max-screen(1280px) {
                    display: none;
                }
            }
            background: $priceBlue;
            color: white;
            ul {
                margin: 10px 0 11px 0;
            
                li {
                    color: white;
                    &::before {
                        content: '\f00c';
                        color: $priceBlue;
                        font-family: 'FontAwesome';
                        margin-right: 10px;
                        background: $gold;
                        border-radius: 7px;
                    }
            
                    b {
                        color: $gold;
                    }
                }
            }
        }
      
        &.inrichtingen {
            &:before {
                background: none;
            }
            background: rgba(255, 255, 255, 0.6);
            color: white;
            margin: 0 0 40px 35px;
            @include max-screen(768px) {
                margin: 0;
            }
        }
      
        &--gold &__desc::after {
            background: $blueSecondary;
        }
      
        &__content {
            overflow: hidden;
            word-wrap: break-word;
        
            min-height: 150px;
            width: 100%;
        
            .btn.btn--gold {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
      
        &__title {
            font-size: em(15, 14);
            color: $blue;
            font-weight: 200;
        
            @include max-screen(480px) {
                font-size: em(14, 14);
            }
      
            span {
                display: block;
        
                font-size: em(24, 14);
                letter-spacing: 2px;
                font-weight: 700;
                color: $blueSecondary;
        
                @include max-screen(480px) {
                    font-size: em(18, 14);
                    letter-spacing: 1px;
                }
            }
        }
      
        &__desc {
            margin: 10px 0 0 0;
            width: calc(100% - 50px);
            position: relative;
            margin-bottom: 20px;
        
            @include max-screen(1280px) {
                width: 100%;
            }
        
            span {
                font-weight: 600;
                color: $blueSecondary;
            }
        }
      
        .btn {
            float: right;
        
            @include max-screen(768px) {
                float: left;
            }
        }
      }
}
