.widget {
    position: relative;
    display: inline-block;

    padding: $gutter;
    margin: 0 0 $gutter 0;
    width: 100%;
    border-radius: 4px;

    @include full-bg;

    .widget__title {
        @extend h2;
        font-size: em(16, 14);
        font-weight: 600;

        &.widget_title--cyan {
            color: $blueSecondary;
        }
    }
    .widget__title--filter {
        font-size: em(16, 14);
        font-weight: 600;
    }

    .widget__title.widget_title--cyan a {
        color: $blueSecondary;
    }

    .widget__desc {
        margin: 10px 0 0 0;
    }

    .contact__desc {
        margin: 20px 0 0 0;
    }

    .widget__contact-information {
        margin: 25px 0 0 0;
    }

    .widget__desc-contact {
        margin: 10px 0 0 0;
        display: grid;

        span {
            margin: 20px 0 0 0;
        }
    }

    a.kiyohanchor {
        text-decoration: none;

        .widget__desc.kiyohrating {
            width: 200px;
            max-height: 200px;
            border: 2px solid $gray2;
            color: black;
            background: rgb(255, 255, 255);

            .top.box-sizing {
                position: relative;
                min-height: 50px;
                margin: 6px;

                .circle-wrapper {
                    width: 70px;
                    float: left;

                    .outer-circle.box-sizing {
                        .inner-circle.box-sizing {
                            margin-left: auto;
                            margin-right: auto;

                            p {
                                margin: 0;
                                padding-top: 5px;
                                padding-left: 3px;
                                font-size: 22px;
                                font-weight: bold;

                                .rating-number {
                                    font-size: 22px;
                                }

                                .rating-number-sm {
                                    font-size: 12px;
                                    margin-left: -5px;
                                }
                            }
                        }
                    }
                }

                .rating.box-sizing {
                    height: 21px;
                    width: 120px;
                    margin-left: 65px;
                    background: url('../images/icons/small-star.png') #727272;

                    .ratingOverlay.box-sizing {
                        height: 21px;
                        background: url('../images/icons/small-star.png') #f8c541;
                    }
                }
            }

            .bottom {
                height: 38px;
                width: 100%;
                background-color: $blue;

                p {
                    margin: 0;
                    margin-top: 10px;
                    line-height: 38px;
                    color: white;
                    text-align: center;
                    font-weight: bold;
                    font-size: 24px;

                    a.bottom--thuiswinkel {
                        font-size: 10px;
                        width: 40px;
                        height: 100%;
                    }
                }
            }
        }
    }

    .bottom--thuiswinkel {
        max-height: 40px;

        img {
            height: 35px;
        }
    }

    &.last-of-type {
        margin-bottom: 0;
    }

    &--gradient {
        background: $blueGradient;
        border: 1px solid $grayBG;
    }

    &--gray {
        background: $grayBG;
        border: 1px solid $grayBG;
    }

    // List styles
    .widget__list {
        margin: 35px 0 0 0;

        &--numbered {
            counter-reset: i;

            li {
                counter-increment: i;
                margin-bottom: 15px;
                font-weight: bold;

                &::before {
                    content: counter(i);
                    background: $blueSecondary;
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    display: inline-block;
                    text-align: center;
                    line-height: 20px;
                    margin-right: 10px;
                    color: $white;
                }

                b {
                    color: $gold;
                    font-weight: normal;
                }
            }
        }

        &--check {
            li {
                margin: 0 0 10px 0;

                &:last-of-type {
                    margin: 0;
                }

                span {
                    color: $gold;
                }

                &::before {
                    content: '\f00c';
                    font-family: 'FontAwesome';
                    margin-right: 10px;
                }
            }
        }
    }

    &.widget--help {
        .widget__title {
            font-weight: bold;
        }

        .widget--help-image-text {
            justify-content: left;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            span.person {
                font-size: em(14, 14);
                font-weight: bold;
                text-transform: none;
            }

            span {
                font-size: em(12, 14);
            }
        }

        .widget__more {
            margin-top: 35px;
            display: block;
        }

        // Title > Description block (dl > dt/dd)
        .widget__details {
            dt {
                font-weight: bold;
            }

            dd {
                margin: 0 0 10px 0;

                &:last-of-type {
                    margin: 0;
                }
            }
        }
    }

    &.widget--filter {
        border: 1px solid $grayBG;
        border-radius: 4px;

        >*:first-child {
            margin-top: 0;
        }

        .select2,
        select {
            width: 100%;
            margin-top: 10px;
        }

        .btn {
            width: 100%;
            margin-top: 10px;
        }
    }
}

.widget--kiyoh {
    border: 1px solid $grayBG;
    border-radius: 4px;
}

.widget__information {
    margin: 20px 0 0;

    dt,
    dd {
        display: block;
        margin: 0;
    }

    dt {
        font-weight: bold;
        margin-bottom: 5px;

        span.fa-phone {
            &:before {
                content: "\f095";
                display: inline-block;
                font-family: "FontAwesome";
            }
        }

        span.fa-whatsapp {
            &:before {
                content: "\f232";
                display: inline-block;
                font-family: "FontAwesome";
            }
        }

        span.fa-envelope {
            &:before {
                content: "\f0e0";
                display: inline-block;
                font-family: "FontAwesome";
            }
        }

        span.fa-question-circle {
            &:before {
                content: "\f059";
                display: inline-block;
                font-family: "FontAwesome";
            }
        }
    }

    dd {
        margin-bottom: 15px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.widget__information--call {
    font-size: 16px;
    margin: 0 auto;
    padding: -10px 50px 50px 50px;
    margin-top: -35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
        font-size: 40px;
        margin-right: 0;
        padding-top: 22px;
        padding-bottom: 8px;
    }

    dt {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    p {
        padding-top: 23px;
    }

    a {
        text-decoration: none;
        font-weight: bold;
    }
}

.widget--appointment {
    background: $hp-blue;
    color: $white;

    .voordeligste__list {
        li {
            i {
                background: $white;
                color: $hp-blue;
            }
            &:before {
                background: $white;
                border-radius: 100%;
                color: $hp-blue;
                content: "\f00c";
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 7px;
                margin-right: 5px;
                padding: 4px;
            }
        }
    }
    .voordeligste__desc {
        margin-bottom: 10px;
    }
    .keurmerken {
        a {
            text-decoration: none;
        }
        a:first-child {
            margin-right: 20px;
        }
        a:nth-child(2) img {
            width: 60%;
        }
    }
}
.widget--appointment .widget__title,
.widget--appointment p {
    color: $white;
}
.widget--appointment .btn {
    width: 100%;
}

.navulling__corner {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50px;
    opacity: 0.2;
}

.navulling,
.voordeligste {
    border: solid 1px #f3f8f2;
    margin-top: 15px;
    background: $blueGradient;
}

.navulling__desc,
.voordeligste__desc {
    position: relative;
    margin-bottom: 40px;
    text-align: left;
    color: $blue;
}


.voordeligste h2 {
    font-size: 19px;
}
  
.voordeligste .voordeligste__desc {
    padding-right: 10px;
    margin-bottom: 24px;
}
  
.voordeligste .widget__information {
    margin-top: 24px;
}
  
.voordeligste__list {
    margin-top: 12px;
}
  
.voordeligste__list li {
    padding-left: 34px;
    position: relative;
}
  
.voordeligste__list li:before {
    position: absolute;
    left: 0;
    top: 5px;
    color: #fff;
    background-color: $success;
    padding: 4px;
    border-radius: 100%;
    font-size: 8px;
    margin-right: 5px;
}

.widget--newsletter {
    display: flex;
    justify-content: space-between;

    .flex--block {
        padding-right: 80px;

        @include max-screen(768px) {
            padding-right: 0;
        }
    }
    form {
        margin-top: 20px;
        width: 350px;

        @include max-screen(520px) {
            width: 100%;
        }
    }

    @include max-screen(768px) {
        .flex--block:nth-of-type(2) {
            display: none;
        }
    }   
}
