.header--mobile {
    align-items: center;
    display: flex;

    &.wrapper {
        padding: 10px;
        width: 95%;
    }

    @include min-screen(768px) {
        display: none;
    }

    &.content,
    &.panel__overlay {
        justify-content: space-between;

        .mobile-navigation {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            li {
                margin-right: 10px;
                margin-bottom: 0;

                &.menu {
                    align-items: center;
                    background: $blueSecondary;
                    border-radius: 4px;
                    box-shadow: 0 1px 0 #1c6eaa;
                    display: flex;
                    flex-direction: column;
                    font-family: $titleStack;
                    font-weight: 700;
                    height: 43px;
                    justify-content: center;
                    padding: 16px 20px 13px;
                    width: 95px;

                    a {
                        color: white;
                    }
                }

                a.logo {
                    float: none;
                    max-width: unset;
                    padding: 0;
                    width: 48px;
                }
            }
            a {
                display: inline-block;
                position: relative;
                text-decoration: none;
            }
            .fa-bars,
            .fa-question {
                &:before {
                    font-family: "FontAwesome";
                    font-size: 22px;
                    font-weight: 900;
                }
            }
            
            .fa-bars {
                &:before {
                    content: "\f0c9";
                    font-size: 15px;
                }
                color: white;
                margin-right: 10px;
            }

            .fa-question {
                &:before {
                    content: "\f128";
                }
            }
        }
    }
    &.search {
        padding-bottom: 20px;

        .block-search {
            display: inline-block;
            margin: 0;
            padding: 0;
            width: 100%;

            form.minisearch {
                .field.search {
                    label {
                        display: none;
                    }

                    .control {
                        border: 0;

                        input {
                            float: none;
                            margin: 0;
                            width: 85%;
                        }
                    }
                }
                .actions {
                    cursor: pointer;

                    button {
                        border: 0;
                        display: inline-block;
                        opacity: 1;
                        top: 0;
                    }
                }
            }
        }
    }
}

.header__top-bar {
    @include max-screen(768px) {
        .wrapper {
            width: 100%;
        }
        .top-bar__nav,
        .switch-container {
            display: none;
        }
        .top-bar__desc ul li {
            margin-left: 15px;
            margin-right: 0;
        }
        .top-bar__desc ul li::before {
            top: calc(50% - 6px);
        }
    }
}

.panel {
    @include min-screen(768px) {
        display: none;
    }

    .panel__header {
        @extend .header;
        display: none;
    }

    .panel__overlay {
        background: $white;
        height: 100vh;
        flex-direction: column;
        left: 0;
        opacity: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        position: fixed;
        top: 120vh;
        transition: all 0.4s $transitionPrimary;
        width: 90%;
        z-index: 5;
    
        &.is-active {
            justify-content: normal;
            opacity: 1;
            top: 0;
        }
          
        .mobile-navigation {
            li {
                display: flex;
        
                a {
                    display: flex;
                    font-size: em(26, 16);
                    height: 42px;
                    justify-content: center;
                    line-height: 42px;
                    width: 42px;
        
                    &.is-active {
                        background: $blueSecondary;
                        border-radius: 4px;
                        color: $white;
            
                        .fa-shopping-cart::after {
                            display: none;
                        }
                    }

                    .logo {
                        float: none;
                        max-width: unset;
                        padding: 0;
                        width: 48px;
                    }
        
                    .fa-shopping-cart.is-filled::after {
                        background-color: rgb(255, 54, 0);
                        border-radius: 100%;
                        content: '';
                        height: 9px;
                        position: absolute;
                        top: 6px;
                        width: 9px;
                    }
                }
            }

            @include max-screen(390px) {
                padding: 10px 0;
            }
        }
        
        .mobile-navigation__close {
            a {
                background: $error;
                border-radius: 4px;
                color: $white;

                &:before {
                    content: "\f00d";
                    font-family: 'FontAwesome';
                    font-weight: 900;
                }
            }
        }
    
        .panel__title {
            align-items: center;
            border-bottom: 1px solid $grayBG;
            display: flex;
            justify-content: space-between;
            padding: 5%;
            width: 100%;
    
            h2,
            span.widget__title {
                font-family: $titleStack;
                font-size: em(21, 14);
                font-weight: 700;
            }
    
            .panel__close {
                background: $blueSecondary;
                border-radius: 4px;
                color: $white;
                display: block;
                float: right;
                font-size: em(26, 16);
                height: 42px;
                line-height: 42px;
                text-align: center;
                width: 42px;
            }
        }

        .panel__head {
            padding: 15px;
            width: 100%;

            .header--mobile {
                display: block;

                .wrapper {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    padding: 0 10px;
                    width: 100%;
                }
            }
        }

        .mobile-nav__top {
            align-items: center;
            background-color: $hp-blue;
            height: 72px;
            width: 100%;
    
            .mobile-navigation__menu {
                font-size: 1.5em;
                padding-top: 5px;
                text-align: center;
                width: 44px;
    
                a {
                    color: $white;
                    background: none;
                    font-size: em(11, 14);
                    line-height: 11px;
                    text-decoration: none;
    
                    .fa-bars {
                        &:before {
                            content: "\f0c9";
                            font-family: "FontAwesome";
                            font-size: em(18, 14);
                            font-weight: 900;
                        }
                    }
                }
            }
    
            .mobile-navigation__close {
                font-size: 1.5em;
                padding-top: 5px;
                text-align: center;
                width: 44px;
    
                a {
                    background: none;
                    color: $white;
                    text-decoration: none;
                }
            }
    
            .mobile-navigation__back {
                border: solid 1px $borderMobileNav;
                color: $white;
                display: none;
                height: 100%;
                padding-top: 8px;
                text-align: center;
                width: 81px;

                a {
                    color: $white;
                    font-size: em(11, 14);
                    font-weight: 900;
                    text-decoration: none;

                    &:active,
                    &:hover,
                    &:visited {
                        color: $white;
                    }

                    span:before {
                        color: $white;
                        content: "\f104";
                        font-family: "FontAwesome";
                        font-weight: 900;
                        padding-right: 7px;
                    }
                }
            }

            .mobile-navigation__text.mobile-navigation__text--modifier {
                padding: 10px;
            }
    
            .mobile-navigation__text {
                color: $white;
                font-size: 1em;
                font-weight: bold;
                height: 100%;
                width: calc(100% - 88px);
    
                ul {
                    height: 100%;
                    flex-wrap: wrap;

                    li {
                        cursor: pointer;
                        height: auto;
                        line-height: 32px;
                        margin: 0;
                        padding: 0 5px;
                        text-align: center;
                        width: auto;
    
                        &.is-active {
                            background-color: $backgroundMobileNav;
                        }
                    }
                }
            }
        }
        .mobile-nav__top.mobile-nav__top--de {
            height: auto;
        }

        .mobile-nav {
            width: 100%;

            ul {
                display: none;
                opacity: 0;

                &.is-active {
                    display: initial;
                    opacity: 1;
                }

                li a {
                    cursor: pointer;
                    display: block;
                    padding: 15px 20px;
                    line-height: 1em;
                    text-decoration: none;

                    &:after {
                        color: $hp-blue;
                        content: "\f105";
                        float: right;
                        font-family: "FontAwesome";
                        font-size: em(18, 14);
                        font-weight: bold;
                    }
                }

                .mobile-nav__main-item {
                    margin: 0;

                    a {
                        border-bottom: solid 2px $blueTertiary;
                        color: $hp;
                        display: block;
                        font-family: $titleStack;
                        font-size: 1em;
                        font-weight: normal;
                        line-height: 1.21;
                        padding: 15px 20px;
                        text-decoration: none;
    
                        &:after {
                            color: $hp-blue;
                            content: "\f105";
                            float: right;
                            font-family: "FontAwesome";
                            font-size: em(18, 14);
                            font-weight: bold;
                        }
                    }
    
                    .mobile-nav__sub-container {
                        background: $white;
                        min-height: calc(100% - 42px);
                        opacity: 0;
                        overflow: auto;
                        position: absolute;
                        top: 72px;
                        right: -100%;
                        transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
                        visibility: hidden;
                        width: 100%;
    
                        &.is-active {
                            opacity: 1;
                            right: 0;
                            visibility: visible;

                            ul {
                                display: initial;
                                opacity: 1;
                            }
                        }
    
                        .mobile-nav__sub-list {
                            li {
                                a {
                                    align-items: flex-end;
                                    border-bottom: solid 2px $blueTertiary;
                                    display: flex;
                                    font-stretch: normal;
                                    font-style: normal;
                                    font-weight: normal;
                                    padding: 16px;

                                    img {
                                        margin-right: 10px;
                                    }
    
                                    &:after {
                                        content: none;
                                    }
    
                                    &.f-bold {
                                        font-weight: bold;
                                    }
                                }
    
                                &:last-child {
                                    a {
                                        border-bottom: solid 0 $blueTertiary;
                                        color: $hp;
                                        padding-bottom: 32px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobile-navigation__text {
            color: $white;
            font-size: 1em;
            font-weight: bold;
            width: 100%;

            ul {
                li {
                    cursor: pointer;
                    height: auto;
                    line-height: 42px;
                    text-align: center;
                    width: 92px;

                    &.is-active {
                        background-color: $hp-blue;
                    }
                }
            }
        }

        .login-widget {                   
            display: block;
            margin: 0;
            position: static;
            width: 100%;
        }
    }
}
