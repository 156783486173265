html {
    box-sizing: border-box;
}
*, 
*:before, 
*:after { 
    box-sizing: inherit;
}

body {
    font-weight: 300; // Light
    @include no-room;
    cursor: default;
    color: $blue;
    font-size: 14px;
    font-family: $readStack;
    background: #fff;
    min-width: 300px;
    transition: all 0.3s $transitionPrimary;
}

h1, 
h2, 
h3,
h4, 
h5, 
h6 {
    font-family: $titleStack;
    color: $blue;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

h3 {
    font-weight: 600;
}

img {
    max-width: 100%;
}

a {
    text-decoration: underline;
    color: $blue;
    &:active,
    &:hover,
    &:visited {
        color: $blue;
    }
}

li a, 
p a {
    &:hover { 
        opacity: 0.8;
    }
}
figure {
    margin: 0; 
    padding: 0;
}
.break {
    clear: both;
}
.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

ul { 
    @include no-list;
}
.hidden {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: -9999px;
}

p {
    line-height: 160%;
    font-weight: 300;
    font-size: em(14, 14);
}

.grid-puller {
    @extend .clearfix;
    margin-left: -20px; 
    margin-right: -20px; 
}

// Layout
.wrapper {
    margin: 0 auto;
    position: relative;
    @include min-screen(1280px) { width: 1260px; }
    @include max-screen(1280px) { width: 1024px; }
    @include screen(1024px, 1280px) { width: 1000px; }
    @include max-screen(1040px) { width: 90%; }
}

.wrapper--wide {
    width: 90%;
    max-width: 1440px;
}

.flex {
    display: flex;
    &--center {
        align-items: center;
        justify-content: center;
    }
    &--space-between {
        justify-content: space-between;
    }
    &--column {
        flex-direction: column;
    }
}

// Titles
.title {
    margin-bottom: 0;
    margin-top: 0;
    position: relative;
    @include min-screen(768px) { font-size: 38px; }
    @include max-screen(768px) { font-size: 28px; }	
}

.subtitle {
    text-transform: uppercase;
    font-family: $titleStack;
    letter-spacing: 2px;
    font-size: 13px;
    display: block;
    color: $gray;

    @include min-screen(768px) { margin-left: -20px; }

    &:after {
        display: block;
        height: 1px;
        width: 200px;
        content: "";
        background: $graySecondary;
        margin: 20px auto;
    }
}
.title--center,
.subtitle--center { 
    text-align: center; 
}

.subtitle--center {
    @include min-screen(768px) { margin-left: auto; }
}

// Standaard blocks
.block-cnt {
    @include clearfix;
    margin: 0 -10px;
}

.w-10 { width: 10%; }
.w-15 { width: 15%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-33 { width: 33.3333333%; }
.w-35 { width: 35%; }
.w-40 { width: 40%; }
.w-45 { width: 45%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%;}
.w-65 { width: 65%;}
.w-66 { width: 66.6666666%;}
.w-70 { width: 70%;}
.w-75 { width: 75%;}
.w-80 { width: 80%;}
.w-85 { width: 85%;}
.w-90 { width: 90%;}
.w-95 { width: 95%;}
.w-100 { width: 100%;}

*[class*=" w-"],
*[class^="w-"] {
    float: left;
    padding: 10px;
}

// Icons

.fa-before {
    margin: 0 0 0 10px;
}
.fa-after {
    margin: 0 10px 0 0;
}

// Helpers

.mb10 { margin-bottom: 10px; }
.mb20 { margin-bottom: 20px; }
.mb25 { margin-bottom: 25px; }
.mb30 { margin-bottom: 30px; }
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }

a.back-to-top {
    text-decoration: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: $gold;
    font-size: 20px;
}

// Main layout and 2-columns-left
.page-main {
    margin-top: 35px;
    min-height: 200px;
    padding: 0;
    width: 1260px;

    @include min-screen(1280px) { width: 1260px; }
    @include max-screen(1280px) { width: 1024px; }
    @include screen(1024px, 1280px) { width: 1000px; }
    @include max-screen(1040px) { 
        margin: 35px auto;
        width: 90%; 
    }
}

.columns {
    display: flex;
    flex-flow: row nowrap;

    .column.main,
    .sidebar {
        float: none;
    }

    .sidebar {
        flex: 1 260px;
        order: 1;
        margin-right: 35px;
        max-width: 260px;
        min-width: 260px;
        padding: 0 0 20px 0;

        @include max-screen(1024px) {
            margin: 0;
            max-width: 100vw;
            position: absolute;
            visibility: hidden;
        }
    }

    .column.main {
        flex: 3 auto;
        order: 2;
    }
}

.page-wrapper > .page-bottom {
    padding: 0;
    width: 1260px;
    
    @include max-screen(768px) {
        padding: 0;
    }
}

.page-products {
    .columns {
        z-index: 0;
    }
}

input, 
select { 
    font-size: 100%; 
}
