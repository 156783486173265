.header__top-bar {
    background: $blueSecondary;
    color: #fff;

    @include max-screen(1150px) {
        padding-bottom: 0;
    }

    .wrapper {
        @extend .clearfix;
        
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        min-height: 40px; // DE
        align-items: center;
    }

    .top-bar__desc {
        min-width: 45%;
        font-size: em(12, 14);
        vertical-align: middle;
        font-weight: 600;

        @include min-screen(1280px) {
            padding: 5px 0;
            text-align: center;
        }

        &.top-bar__desc--full {
            min-width: 100%;
            max-width: 100%;
        }

        ul {
            display: flex;

            li {
                font-weight: normal;
                margin-bottom: 0;
                margin-right: 25px;
                padding-left: 25px;
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 4px;
                    width: 12px;
                    height: 6px;
                    border-left: 2px solid $gold;
                    border-bottom: 2px solid $gold;
                    transform: rotate(-45deg);
                }

                span {
                    font-weight: bold;
                }
            }
        }
    }

    .top-bar__nav {
        display: flex;
        order: 2;
        margin: 0 0 0 10px;

        li.btn--flyout {
            margin-bottom: 0;
            width: 145px;

            &.btn--thuiswinkel {
                border-left: 1px solid rgba(255, 255, 255, 0.12);
                padding: 0 20px;
                width: auto;

                a {
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
            }

            &.btn--becommerce {
                width: 58px;
                border-left: 1px solid hsla(0, 0%, 100%, 0.12);

                a {
                    padding-top: 6px;
                    padding-bottom: 6px;

                    img {
                        height: 28px;
                        min-width: 50px;
                        position: relative;
                        right: 12px;
                    }
                }
            }

            &.is-filled {
                border-bottom: 4px solid $success;
            }

            &.is-active {
                position: relative;
                z-index: 3;

                >a {
                    background: #fff;
                    color: $blue;
                    box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.36);

                    &:hover,
                    &:focus {
                        background: #fff;
                        color: $blue;
                        box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.36);
                    }
                }
            }

            >a {
                @extend .btn;
                display: block;
                min-width: 1px;
                height: 40px;
                font-size: em(11, 14);
                box-shadow: none;
                border-radius: 0;

                &:hover {
                    box-shadow: none;
                    background: rgba(255, 255, 255, 0.12);
                }

                i {
                    margin: 0 10px 0 0;

                    @include min-screen(1024px) {
                        display: none;
                    }

                    &:nth-child(2) {
                        margin: 0 0 0 10px;
                    }
                }
            }

            a.de {
                width: 220px;
            }

            &:last-of-type {
                border-right: 1px solid rgba(255, 255, 255, 0.12);
                border-left: 1px solid rgba(255, 255, 255, 0.12);
            }
        }
    }
}