.cart-container {
    .form-cart .col.price,
    .form-cart .col.subtotal {
        div.flex {
            flex-direction: column-reverse;
        }
    }
    .form-cart .price-excluding-tax {
        span.price {
            font-size: 18px;
        }
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }
    .form-cart .price-including-tax {
        span.price {
            font-size: 11px;
        }
        &:after {
            content: attr(data-label);
            font-size: 1.1rem;
        }
    }
    .cart-summary .block > .title {
        font-size: em(28, 14);
    }
    .abs-discount-block > .title:after,
    .cart-summary .block > .title:after {
        right: 0;
        top: 0;
    }
    .checkout-methods-items .action.primary {
        &.btn.btn--green {
            background: $green;
            border: 0;
        }
    }

    .checkout,
    .table-wrapper {
        .items .item {
            @include max-screen(479px) {
                display: block;
            }
        }
    }
    .message.warning {
        display: inline-block;
    }

    input[type="checkbox"],
    input[type="radio"] {
        appearance: auto;
        display: inline-block;
        float: none;
        margin-right: 10px;
    }

    input[type="checkbox"] {
        -webkit-appearance: checkbox;
    }

    input[type="radio"] {
        -webkit-appearance: radio;
    }
}
.block.crosssell {
    margin-top: 0;
}