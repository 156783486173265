.image-block {
    @include full-bg;
    @include opacity-bg-image(0.75);

    align-items: flex-end;
    background-repeat: no-repeat;
    border-radius: 4px;
    display: flex;
    float: left;
    justify-content: flex-start;
    max-width: calc(50% - 35px / 2);
    min-width: calc(50% - 35px / 2);
    padding: $gutter;
    position: relative;
    text-decoration: none;
    transition: transform 0.3s $transitionPrimary;
  
    @include max-screen(768px) {
        flex-flow: row wrap;
        min-width: 100%;
        width: 100%;
    }
  
    &:hover {
        transform: scale(1.06) translate3d(0, 0, 0);
    }
  
    &--first {
        margin: 0 $gutter 0 0;
  
        @include max-screen(768px) {
            margin: 0 $gutter $gutter 0;
        }
    }
  
    h4.image-block__title {
        color: $white;
        font-family: $titleStack;
        font-size: em(24, 14);
        font-weight: 700;
        text-transform: uppercase;
    }
  
    &--gold &__desc::after {
        background: $blueSecondary;
    }
  
    &__content {
        min-height: 150px;
        overflow: hidden;
        width: 100%;
        word-wrap: break-word;
    
        .btn.btn--gold {
            bottom: 0;
            position: absolute;
            right: 0;
        }
    }
  
    &__title {
        color: $blue;
        font-size: em(15, 14);
        font-weight: 200;
        line-height: 23px;
    
        @include max-screen(480px) {
            font-size: em(14, 14);
        }
  
        span {
            color: $blueSecondary;
            display: block;
            font-size: em(24, 14);
            font-weight: 700;
            letter-spacing: 2px;
    
            @include max-screen(480px) {
                font-size: em(18, 14);
                letter-spacing: 1px;
            }
        }
    }
  
    &__desc {
        margin: 10px 0 20px 0;
        position: relative;
        width: calc(100% - 50px);

        @include max-screen(1280px) {
            width: 100%;
        }

        span {
            color: $blueSecondary;
            font-weight: 600;
        }
    }
  
    .btn {
        float: right;

        span.fa-caret-right {
            &:before {
                color: white;
                content: "\f105";
                display: inline-block;
                font-family: "FontAwesome";
                font-size: 13px;
                font-weight: 900;
                margin-right: 5px;
            }
        }
    
        @include max-screen(768px) {
            float: left;
        }
    }
}
