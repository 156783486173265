.page-footer {
    border-top: solid 1px #dbdbdb;
    background: white;
}
.footer__review {
    user-select: none;
}
.footer__review .wrapper {
    align-items: center;
    display: flex;
    font-weight: 400;
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px 0;
    @include max-screen(768px) {
        flex-flow: column;
        text-align: center;
    }
    @include max-screen(520px) {
        max-width: 260px;
    }
}
.footer__review img {
    width: 26px;
    height: 23px;
    margin-right: 8px;
    @include max-screen(768px) {
        margin-bottom: 8px;
        margin-right: 0;
    }
}
.footer__review b {
    color: #358ee0;
}
  
.footer__middle {
    background: #f1f8ff;
    padding-top: 54px;
    padding-bottom: 28px;
}
.footer__middle .wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    @include max-screen(800px) {
        flex-flow: column;
    }
}
.footer__column {
    max-width: 276px;
    margin-right: 104px;
    @include max-screen(1280px) {
        margin-right: 54px;
        max-width: 212px;
    }
    @include max-screen(1140px) {
        margin-right: 24px;
    }
    @include max-screen(1024px) {
        margin-bottom: 54px;
        max-width: 312px;
    }
    &:last-of-type {
        margin-right: 0;
        margin-left: auto;
        @include max-screen(1280px) {
            margin-left: 0;
        }
    }
}
.footer__title {
    text-transform: none;
    font-size: 16px;
    line-height: 1.25;
    font-weight: bold;
    margin-bottom: 8px;
}
.footer__list li {
    margin-bottom: 8px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.footer__list a {
    line-height: 1.43;
    text-decoration: none;
    font-weight: 400;
}
.footer__description {
    margin-top: 0;
    margin-bottom: 13px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
}
  
.footer__newsletter {
    display: flex;
    align-items: flex-start;

    label {
        margin: 0;
        width: 100%;

        .mage-error {
            color: red;
            font-size: 12px;
            font-weight: 400;
        }
    }
}
.footer__newsletter input {
    border: solid 1px #979797;
    &::placeholder {
        color: #9b9b9b;
    }
}
.footer__newsletter .btn {
    height: 38px;
    padding: 0;
    width: 40px;
    box-shadow: 0 2px 0 0 #bd9a5c;
    margin-left: 4px;
}
.footer__newsletter .btn img {
    position: relative;
    top: 1px;
}
.footer__contact-item {
    margin-bottom: 24px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.footer__contact-item a {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-decoration: none;
    position: relative;
    padding-left: calc(36px + 8px);
}
.footer__contact-item a:hover {
    opacity: 1;
}
.footer__icon {
    width: 36px;
    height: 36px;
    position: absolute;
    left: 0;
    top: 0;
    backface-visibility: hidden;
    &::before {
        content: '';
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #358ee0;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.1s ease-in-out;
    }
}
.footer__icon img {
    backface-visibility: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}
.footer__label {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    margin-top: 2px;
}
.footer__label i {
    font-size: 16px;
    line-height: 1.11;
    font-weight: bold;
    font-style: normal;
    white-space: nowrap;
}
.footer__label small {
    font-size: 12px;
    color: #34495e;
    font-style: italic;
    margin-top: 7px;
}
.footer__contact-item a:hover .footer__icon::before {
    width: 44px;
    height: 44px;
    left: -4px;
}
.footer__contact-item--green .footer__icon::before {
    background: #0f9a16;
}
  
.footer__bottom {
    padding-top: 22px;
    padding-bottom: 54px;
}
.footer__bottom .wrapper {
    align-items: center;
    display: flex;
    flex-flow: column;
}
.footer__bottom.footer__bottom--icons {
    padding-bottom: 22px;

    .wrapper {
        flex-flow: row;
        justify-content: center;
    }

    img {
        margin-right: 20px;
        width: 50px;

        @include max-screen(420px) {
            margin-right: 12px;
            width: 40px;
        }
    }
    .img--spedition {
        width: 75px;
    }
}
.footer__logo {
    width: 288px;
    @include max-screen(768px) {
        width: 198px;
    }
}
.footer__shops {
    margin-top: 16px;
    display: flex;
    @include max-screen(420px) {
        flex-flow: column;
    }
}
.footer__shops li {
    margin-right: 36px;
    position: relative;
    @include max-screen(768px) {
        margin-right: 18px;
        font-size: 10px;
    }
    @include max-screen(500px) {
        margin-right: 18px;
        font-size: 10px;
    }
    @include max-screen(420px) {
        margin-right: 0;
        font-size: 10px;
        margin-bottom: 8px;
    }
    &::before {
        content: '';
        width: 1px;
        height: 17px;
        background: #dbdbdb;
        position: absolute;
        right: -18px;
        top: 2px;
        @include max-screen(768px) {
            right: -9px;
        }
        @include max-screen(420px) {
            display: none;
        }
    }
    &:last-of-type {
        margin-right: 0;
        @include max-screen(420px) {
            margin-bottom: 0;
        }
        &::before {
            display: none;
        }
    }
}
.footer__shops li a {
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    font-weight: 400;
    @include max-screen(420px) {
        justify-content: left;
    }
}
.footer__shops li a img {
    height: 23px;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 23px;
}
  
.footer__copyright {
    color: rgba(52, 73, 94, 0.28);
    font-size: 10px;
    text-align: center;
    margin-bottom: 0;
    margin-top: 16px;
    max-width: 638px;
    font-weight: 400;
}
.footer__copyright a {
    color: inherit;
}

.becom-footer {
    min-width: 50px;
    left: 3px;
    margin: -2px 0 0 0;
}
  