.product-actions {
    display: flex;
    font-size: em(16, 14);
    justify-content: center;
    margin-bottom: 24px;
    margin-top: -11px;
    position: relative;
    width: 100%;

    @include max-screen(1280px) {
        font-size: em(14, 14);
    }
    
    @include max-screen(1180px) {
        align-items: flex-start;
        border-bottom: 1px solid $grayWhite;
        border-top: 1px solid $grayWhite;
        flex-flow: column;
        font-size: em(16, 14);
        padding-bottom: 24px;
        padding-top: 24px;
    }

    &::after {
        background: $grayWhite;
        bottom: 0;
        content: '';
        height: 1px;
        margin: auto;
        position: absolute;
        top: 0;
        width: 100%;

        @include max-screen(1180px) {
            display: none;
        }
    }

    .product-actions__item {
        background: white;
        display: flex;
        padding-left: 16px;
        padding-right: 8px;
        position: relative;
        z-index: 2;

        @include max-screen(1180px) {
            margin-bottom: 16px;
            padding-left: 0;
            padding-right: 0;
        }

        &:last-of-type {
            padding-left: 0;
            padding-right: 16px;

            @include max-screen(1180px) {
                margin-bottom: 0;
                padding-right: 0;
            }
        }
    }
    
    .product-actions__item svg {
        margin-right: 4px;
        position: relative;
        top: 3px;
    
    }

    .product-actions__item b {
        color: #ffc968;
    }
}
  