.customer-account-login,
.customer-account-create {
    h1 {
        font-size: em(16, 14);
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
    }

    .section-desc {
        font-weight: 700;
        margin: 15px 0;
    }

    button.btn:hover {
        border: none;
        background: $hp-lightblue;
        opacity: 1;
    }
    a.btn:hover {
        color: $blue;
    }

    .form-full-width {
        fieldset {
            position: relative;
        }
    }

    .input-cnt.password {
        margin-bottom: 0;

        div.mage-error {
            text-align: right;
            width: 100%;
        }
    }

    .input-cnt.confirmation {
        margin-top: 60px;
    }

    #password-strength-meter-container {
        position: absolute;
        right: 0;
    }

    .persistent {
        input[type="checkbox"] {
            -webkit-appearance: checkbox;
            width: auto;
        }
        label {
            width: auto;
        }
    }
}
