// Standaard styles
legend {
    @include hidden;
}
fieldset {
    @include no-room;
}
  
input {
    @include max-screen(768px) {
        @include placeholder {
            font-size: em(11, 14);
        }
    }
}
  
input,
textarea,
select {
    outline: none;
}

input::placeholder {
    color: $gray;
}

// Firefox - hide arrows on input number field
input[type=number] {
  -moz-appearance: textfield!important;
}
  
input,
select,
input[type="text"], 
input[type="password"], 
input[type="url"], 
input[type="tel"], 
input[type="search"], 
input[type="number"], 
input[type="datetime"], 
input[type="email"] {
    appearance: textfield;
    border: 1px solid $grayBorder;
    border-radius: 4px;
    clear: both;
    color: $blue;
    height: 41px;
    float: left;
    font-size: em(14, 14);
    line-height: 40px;
    padding: 0 12px;
    position: relative;
    
    &:focus {
        border: 1px solid $grayTertiary;
    }
    &[type='radio']{
      opacity: 1;
    }
    &[type='radio'],
    &[type='checkbox'] {
        width: auto;
        height: auto;
        box-shadow: none;
    }
    &[type='submit'] {
        border: 0;
    }
    &.is-valid {
        &::placeholder {
            &:after {
                position: absolute;
                right: 12px;
                content: '\f00c';
                font-family: 'FontAwesome';
                color: $success;
            }
        }
  
        border-color: $success;
        &:focus {
            outline: 2px solid $success;
            background: #fff;
        }
    }
    &.is-invalid {
        &::placeholder {
            &:after {
                position: absolute;
                right: 12px;
                content: '\f12a';
                font-family: 'FontAwesome';
                color: $error;
            }
        }
        border-color: $error;
        &:focus {
            outline: 2px solid $error;
            background: #fff;
        }
        &::after {
            content: '\f12a';
            font-family: 'FontAwesome';
        }
    }
}
  
select {
    float: none;
    appearance: menulist;
}
  
textarea {
    padding: 10px 12px;
    font-size: em(14, 14);
    color: $blue;
    border: 1px solid $grayBorder;
    border-radius: 4px;
    height: 100px;
    resize: vertical;
    &:focus {
        border: 1px solid $grayTertiary;
    }
}
  
label,
select {
    cursor: pointer;
    outline: none;
}
  
button {
    user-select: none;
    outline: none;
    appearance: none;
}
a.btn {
    user-select: none;
}
  
  // Custom radio's
  .is-disabled {
    pointer-events: none;
    .check-btn,
    .radio-btn {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .radio-btn {
    position: relative;
    // padding: 10px 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    &.is-checked {
      &:before {
        color: #fff;
        font-size: 11px;
        border: 1px solid $success;
        background: $success;
      }
    }
  }
  .radio-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #ffffff;
    transition: all 200ms ease-in;
    border: 0 solid #ffffff;
  }
  .radio-btn input[type='radio'] {
    @include hidden;
  }
  .radio-btn.is-checked:before {
    background: $success;
    border: 4px solid #ffffff;
  }
  
  .check-btn {
    position: relative;
    padding: 10px 0;
    margin-right: 20px;
    display: flex;
    align-items: center;
    input {
      @include hidden;
    }
    &:before {
      font-family: 'FontAwesome';
      content: '\f00c';
      display: inline-block;
      margin-right: 0;
      background: #fff;
      border: 1px solid $gray;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      text-align: center;
      line-height: 20px;
      font-size: 0;
      transition: all 0.1s $transitionPrimary;
    }
    input[type='checkbox']:disabled + &.check-btn:before {
      opacity: 0.3;
      cursor: not-allowed;
    }
    label {
      width: auto;
    }
    &.is-checked {
      &:before {
        //transform:rotate(0deg);
        color: #fff;
        font-size: 11px;
        border: 1px solid $success;
        background: $success;
      }
    }
  }
  //Buttons
  .btn {
    padding: 13.5px 15px;
    display: inline-block;
    border-radius: 4px;
    font-size: em(14, 16);
    line-height: em(18, 16);
    cursor: pointer;
    font-family: $btnStack;
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    color: #fff;
    outline: none;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
  
    background: $blueSecondary;
    box-shadow: 2px 2px 0 0 $blue;
    transition: box-shadow 0.1s $transitionPrimary;
    &:hover {
      //background: $blue;
      opacity: 1;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.15);
    }
    &:focus {
      outline: none;
      // box-shadow: inset 0 -50px 0 0 rgba(0, 0, 0, .15);
    }
    &--white {
      background: white;
      color: #358ee0;
      box-shadow: 1px 1px 0 0 #aeaeae;
    }
  
    &--gold {
      background: $gold;
      color: #fff;
      box-shadow: 2px 2px 0 0 $goldSecondary;
    }
    &Compact {
      background: $blueTertiary;
      color: $blue;
      box-shadow: 2px 2px 0 0 $blueLight;
      &:hover {
        box-shadow: 1px 1px 3px 0 $blueLight;
      }
    }
    &--blue {
      background: $blue;
      color: #fff;
    }
    &--hp-blue {
      background: $hp-blue;
      color: #fff;
    }
    &--green {
      background: $green;
      color: #fff;
    }
    &--gray {
      background: $gray;
      color: $blue;
      box-shadow: 2px 2px 0 0 $graySecondary;
    }
    &--no-bg {
      background: none;
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
    &--success {
      background: $success;
      box-shadow: 2px 2px 0 0 #6da64d;
  
      &:before {
        font-family: 'FontAwesome';
        content: '\f00c';
        margin-right: 10px;
      }
      color: #fff;
    }
    &--close {
      // min-width: 44px;
      background: $error;
      box-shadow: 2px 2px 0 0 #7b1d1d;
      color: #fff;
      margin-left: 10px;
      i {
        margin-right: 0 !important;
        // line-height: 44px;
      }
    }
    &--no-shadow {
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    }
    &--disabled {
      opacity: 0.8;
      cursor: not-allowed;
      pointer-events: none;
    }
    i {
      margin-right: 10px;
    }
    &--blue-border {
      border: 1px solid #50aae9;
      background: none;
      box-shadow: none;
      color: #50aae9;
      padding: 13.5px 15px 11px;
    }
  }
  
  //Default form styles
  form {
    fieldset {
      // margin: 20px 0;
      // max-width: calc(50% - 17.5px);
      p {
        margin: 0;
        margin-bottom: 20px;
      }
      &.group-select {
        .input-cnt {
          @include max-screen(768px) {
            flex-flow: row;
          }
        }
      }
      .input-cnt {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @include max-screen(768px) {
          flex-flow: column;
          align-items: flex-start;
        }
        &.input-cnt--start {
          align-items: flex-start;
        }
        i {
          margin-left: 5px;
          padding: 5px;
          @include max-screen(1180px) {
            margin-left: 0;
          }
        }
        &.flex-start {
          align-items: flex-start;
        }
        .select2 {
          width: calc(70% - 35px) !important;
        }
        &:last-of-type {
          margin-bottom: 0;
        }
        &--align-top {
          align-items: flex-start;
        }
        &--full,
        &--half {
          display: block;
          > * {
            width: 100%;
          }
          .select2 {
            width: 100% !important;
          }
          label {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
        &--half {
          width: calc(50% - 10px);
          float: left;
          &.first {
            margin-right: 20px;
          }
        }
        &--line {
          flex-wrap: wrap;
          flex-flow: wrap;
          align-items: center;
          label {
            width: calc(100% - 40px);
            margin-right: 0;
            margin-bottom: 0;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
        }
        &--check {
          flex-wrap: wrap;
          flex-flow: wrap;
          align-items: center;
          label {
            width: auto;
            margin-bottom: 0;
          }
        }
      }
      .payment-methods .input-cnt {
        margin-bottom: 20px;
      }
      &.submit {
        width: calc(70% - 17.5px);
        max-width: 100%;
        text-align: right;
        margin-bottom: 0;
        @include max-screen(1180px) {
          width: 100%;
        }
        .btn {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
            @include max-screen(480px) {
              margin-top: 10px;
            }
          }
        }
      }
    }
    label {
      width: 30%;
      margin-right: 35px;
      display: inline-block;
      vertical-align: middle;
      // padding: 5px 0;
      @include max-screen(768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    input,
    textarea,
    .taxvat-cnt {
      width: calc(70% - 35px);
      float: none;
      @include max-screen(768px) {
        width: 100%;
      }
    }
    .taxvat-cnt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include max-screen(768px) {
        flex-flow: column;
        float: left;
      }
      input {
        width: 100%;
        flex-basis: 100%;
        margin-right: 10px;
        clear: none;
        @include max-screen(768px) {
          width: 100%;
          margin-right: 0;
        }
        &.btn {
          margin-right: 0;
          width: 45%;
          @include max-screen(768px) {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .form-full-width {
      &.form.create.account {
          width: 100%;
      }

      fieldset {
          margin: 20px 0;
          max-width: calc(70% - 17.5px);
          @include max-screen(1180px) {
              max-width: 100%;
          }
      }

      input {
          background-color: rgb(232, 240, 254);
          float: none;
          width: calc(70% - 35px);
      }
  }
  
  // Select 2
  .select2-container,
  .select2-container > * {
    outline: none !important;
  }
  .select2-container--default .select2-results > .select2-results__options {
    width: 100%;
  }
  .select2-container--default .select2-selection--single {
    position: relative;
    height: 40px;
  
    padding: 0 12px;
  
    line-height: 40px;
    font-size: em(14, 14);
    color: $blue;
    border: 1px solid $gray;
    border-radius: 0;
    border-radius: 4px;
  }
  .select2.select2-container.select2-container--default.selected {
    background: blue;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    line-height: 40px;
    width: calc(100% - 20px);
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0;
  }
  .select2-selection {
    outline: none !important;
  }
  .select2-dropdown {
    border: 1px solid $gray;
  }
  .select2-results__option {
    padding: 10px;
  }
  .select2-results__option.select2-results__option--highlighted,
  .select2-container--default
    .select2-results__option--highlighted[aria-selected] {
    background: $blueSecondary;
  }
  .select2-container--default .select2-results__option[aria-selected='true'] {
    background: $gray;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    width: 20px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    right: 8px;
    top: -2px;
    transition: all 0.2s $transitionPrimary;
    &:before {
      content: '\f107';
      font-family: 'FontAwesome';
      display: block;
      font-size: em(14, 14);
      color: $grayTertiary;
    }
    b {
      display: none;
    }
  }
  .select2-container--open.select2-container--default
    .select2-selection--single
    .select2-selection__arrow {
    transform: rotate(180deg);
  }
  .validation-advice,
  #euvat_action_validatetaxvat_message div {
    margin-left: calc(30% + 35px);
    background: $blueTertiary;
    border-radius: 4px;
    margin-top: 5px;
    padding: 5px 10px !important;
    display: inline-block;
    width: auto;
    border: 0 !important;
    font-weight: normal !important;
    @include max-screen(768px) {
      margin-left: 0;
    }
    &.euvat-taxvat-validation-failure {
      background: $error;
      color: #fff;
    }
    &.euvat-taxvat-validation-success {
      background: $success;
      color: #fff;
    }
  }
  // Google reCaptcha
  .flex.justify-left {
    justify-content: flex-end;
  }
  // Warning in account
  .form-address-edit {
    .field .message.warning {
      margin-top: 40px;
    }
  }
  