div.checkout-container {
    .keytoe_international_fieldset {
        background: $blueLight;
        border: 1px solid $hp-blue;
        border-radius: 4px;
        margin-bottom: 20px;
        padding: 15px;
    }

    .keytoe_search_field {
        margin-bottom: 0 !important;

        .control{
            position: relative;

            img {
                height: 33px;
                position: absolute;
                right: 6px;
                top: 3px;
                width: 25px;
            }
        }
    }

    .keytoe_international_list {
        background: white;
        border: 1px solid $grayBorder;
        border-radius: 4px;
        height: 155px;
        overflow-x: hidden;

        li {
            align-items: center;
            border-bottom: 1px solid $grayBorder;
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
            width: 100%;

            &:hover {
                color: $hp-blue;
                cursor: pointer;
                font-weight: 700;
            }

            &:last-child {
                border-bottom: 0;
                padding-bottom: 0;
            }

            &.with-svg {
                &::after {
                    content: url('../images/icons/arrow-right.svg');
                    font-weight: 700;
                }
            }
        }
    }
}
