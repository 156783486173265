//Extend mixin of magento to include font-display: swap
@mixin lib-font-face(
    $family-name,
    $font-path,
    $font-weight: normal,
    $font-style: normal
) {
    @font-face {
        font-family: $family-name;
        src: url('#{$font-path}.eot');
        src: url('#{$font-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$font-path}.woff2') format('woff2'),
        url('#{$font-path}.woff') format('woff'),
        url('#{$font-path}.ttf') format('truetype'),
        url('#{$font-path}.svg##{$family-name}') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
        font-display: swap;
    }
}
// Proxima Nova
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Light',
    $font-weight: 300,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Regular',
    $font-weight: 400,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Semibold',
    $font-weight: 600,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Bold',
    $font-weight: 700,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Extrabold',
    $font-weight: 800,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'Proxima Nova',
    $font-path: '../fonts/proximanova/ProximaNova-Black',
    $font-weight: 900,
    $font-style: normal
);
@include lib-font-face(
    $family-name: 'FontAwesome',
    $font-path: '../fonts/fontawesome/fa-solid-900',
    $font-weight: 900,
    $font-style: normal
);
