// General style helpers

* {
    box-sizing: border-box;
}
  
img {
    max-width: 100%;
}
  
svg.icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
}
  
.clearfix:after {
    clear: both;
    content: '';
    display: table;
}

  
.flex {
    display: flex;
}

.hide-mobile {
    @include max-screen(768px) {
        display: none;
    }
}

.only-mobile {
    display: none;
    @include max-screen(768px) {
        display: block;
    }
}
  
.flex-1 {
    flex: 1;
}
  
.flex-ajbottom {
    align-items: flex-end;
    align-content: flex-end;
    justify-content: flex-start;
}
  
.flex-ajtop {
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
}

.flex-center {
    align-items: center;
    align-content: center;
    justify-content: center;
}

.flex-acenterjstart {
    align-items: center;
    align-content: center;
    justify-content: start;
}

.flex-column {
    flex-direction: column;
}
  
.flex-justify {
    justify-content: space-between;
}
  
.flex-justify-around {
    justify-content: space-around;
}
  
.flex-wrap {
    flex-wrap: wrap;
}
  
// Text styles
.title {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 0;
}
  
.title--uppercase {
    font-size: em(14, 16);
    font-weight: 600;
    letter-spacing: 1.7px;
    text-transform: uppercase;
}
  
.ls0 {
    letter-spacing: 0;
}
  
.ls1 {
    letter-spacing: 1px;
}
  
.ls2 {
    letter-spacing: 2px;
}
  
.ls4 {
    letter-spacing: 4px;
}
  
.ls11 {
    letter-spacing: 11px;
}
  
//Margins
.mt0 {
    margin-top: 0;
}
  
.mt5 {
    margin-top: 5px;
}
  
.mt10 {
    margin-top: 10px;
}
  
.mt15 {
    margin-top: 15px;
}
  
.mt16 {
    margin-top: 16px;
}
  
.mt20 {
    margin-top: 20px;
}
  
.mt25 {
    margin-top: 25px;
}
  
.mt30 {
    margin-top: 30px;
}
  
.mt35 {
    margin-top: 35px;
}
  
.mt40 {
    margin-top: 40px;
}
  
.mt45 {
    margin-top: 45px;
}
  
.mt50 {
    margin-top: 50px;
}
  
.mt55 {
    margin-top: 55px;
}
  
.mt60 {
    margin-top: 60px;
}
  
.mt70 {
    margin-top: 70px;
}
  
.mt75 {
    margin-top: 75px;
}
  
.mt80 {
    margin-top: 80px;
}
  
.mt90 {
    margin-top: 90px;
}
  
.mt96 {
    margin-top: 96px;
}
  
.mt100 {
    margin-top: 100px;
}
  
.mt110 {
    margin-top: 110px;
}

.mt120 {
    margin-top: 120px;
}

.mt130 {
    margin-top: 130px;
}

.mt140 {
    margin-top: 140px;
}

.mt150 {
    margin-top: 150px;
}

.mt200 {
    margin-top: 200px;
}

.mt400 {
    margin-top: 400px;
}
  
.mb0 {
    margin-bottom: 0;
}

.mb5 {
    margin-bottom: 5px;
}

.mb10 {
    margin-bottom: 10px;
}

.mb15 {
    margin-bottom: 15px;
}

.mb20 {
    margin-bottom: 20px;
}

.mb25 {
    margin-bottom: 20px;
}

.mb30 {
    margin-bottom: 30px;
}

.mb35 {
    margin-bottom: 35px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb50 {
    margin-bottom: 50px;
}

.mb55 {
    margin-bottom: 55px;
}

.mb60 {
    margin-bottom: 60px;
}

.mb72 {
    margin-bottom: 72px;
}

.mb80 {
    margin-bottom: 80px;
}   

.mb100 {
    margin-bottom: 100px;
}
  
// Padding
.pt0 {
    padding-top: 0;
}

.pt10 {
    padding-top: 10px;
}

.pt20 {
    padding-top: 20px;
}

.pt30 {
    padding-top: 30px;
}

.pt40 {
    padding-top: 40px;
}

.pt50 {
    padding-top: 50px;
}

.pt100 {
    padding-top: 100px;
}

.pt115 {
    padding-top: 115px;
}

.pt150 {
    padding-top: 150px;
}

.pt200 {
    padding-top: 200px;
}

.pb0 {
    padding-bottom: 0;
}

.pb10 {
    padding-bottom: 10px;
}

.pb15 {
    padding-bottom: 15px;
}

.pb40 {
    padding-bottom: 40px;
}

.pb50 {
    padding-bottom: 50px;
}

.pb70 {
    padding-bottom: 70px;
}

.pb100 {
    padding-bottom: 100px;
}

.pb115 {
    padding-bottom: 115px;
}

.pb150 {
    padding-bottom: 150px;
}

.pb200 {
    padding-bottom: 200px;
}
  
// Width
.w50 {
    width: 50%;
}
  
// Font weight
.f-light {
    font-weight: 300;
}

.f-regular {
    font-weight: 400;
}

.f-medium {
    font-weight: 500;
}

.f-600 {
    font-weight: 600;
}

.f-bold {
    font-weight: 700;
}
.f-black {
    font-weight: 900;
}

// Font style
.italic {
    font-style: italic;
}

// Font transform
.uppercase {
    text-transform: uppercase;
}

// Font decoration
.line-through {
    text-decoration: line-through;
}

.underline {
    text-decoration: underline;
}

// Font align
.ta-left {
    text-align: left;
}

.ta-center {
    text-align: center;
}

.ta-right {
    text-align: right;
}

.hidden {
    display: none;
    opacity: 0;
    visibility: hidden;
}

.no-scroll {
    overflow: hidden;
}
  