.dispensercheck-index-dispensercheck {
    .category-header {
        &__title {
            @extend .title;
            color: $blueSecondary;
            font-size: em(28, 14);
        }

        &__description {
            margin: 10px 0 35px;
        }
    }

    .product-options {
        margin: 15px 0 25px 0;

        h3 {
            cursor: pointer;
            margin-bottom: 20px;

            @include max-screen(768px) {
                font-size: em(14, 14);
            }

            &:after {
                content: '\f107';
                float: left;
                font-family: 'FontAwesome';
                left: 0;
                margin-right: 9px;
                position: relative;
                top: -2px;
                transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
            }

            &.is-active {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        .product-grid {
            float: left;
            margin-bottom: 20px;
            max-height: 0;
            overflow: hidden;
            transition: all 0.4s $transitionPrimary;
            width: 100%;
        
            &.is-active {
                max-height: 2999px;
            }

            //o.a. Dispensercheck
            .item--simple {
                display: inline-block;
                margin: 0 10px 10px 0;
                width: auto;

                &:nth-child(2n + 1),
                &:nth-child(2n + 2) {
                    margin: 0 10px 10px 0;
                }

                &:nth-child(4n + 4) {
                    @include min-screen(1180px) {
                        margin: 0 10px 10px 0;
                    }
                }

                &.is-active {
                    a {
                        background: $success;
                        border: 2px solid $success;

                        h4 {
                            color: $white;
                        }
                    }
                }

                a { 
                    border: 2px solid $blueTertiary;
                    border-radius: 4px;
                    display: block;
                    padding: 10px;
                    position: relative;
                    text-align: left;
                    text-decoration: none;
                    transition: 0.2s all ease-in-out;

                    h4 {
                        color: $blue;
                        font-size: em(18, 16);
                        font-weight: 400;
                        height: auto;
                        text-align: left;
                        text-transform: none;
                    }
                }
            }

            //o.a. Dispensercheck
            .item--brand {
                display: inline-block;
                margin: 0 7px 7px 0;
                padding-bottom: calc(16.8% - 10px);
                position: relative;
                width: calc(16.8% - 10px);

                @include max-screen(768px) {
                    padding-bottom: calc(33.33% - 7.5px);
                    width: calc(33.33% - 7.5px);
                }

                @include max-screen(480px) {
                    padding-bottom: calc(50% - 5px);
                    width: calc(50% - 15px);
                }

                &.is-active {
                    a {
                        border: 2px solid $success;
                    }
                }

                a {
                    align-items: center;
                    border: 2px solid $blueTertiary;
                    border-radius: 4px;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    position: absolute;
                    transition: 0.2s all ease-in-out;
                    width: 100%;
                }

                .item__figure {
                    img {
                        margin-bottom: 0;
                        max-width: 120px;
                    }
                }
            }
        }
    }

    .product-grid-new {
        border-top: 1px solid $blueTertiary;
        padding-top: $gutter;

        .product-grid-item {
            @include max-screen(768px) {
                width: 100%;
            }
        }
    }
}

.dispensercheck-index-dispenserchecksingle {
    .content {
        position: relative;
    }
    
    .product__notification {
        background: $green;
        border-radius: 4px;
        color: $white;
        left: 20px;
        padding: 10px;
        position: absolute;
        opacity: 0.75;
        text-align: left;
        top: 20px;
        z-index: 1;
    }

    .product-media {
        height: 475px;
        margin-right: 35px;
        max-width: 475px;
        overflow: hidden;

        @include max-screen(768px) {
            height: auto;
            margin-bottom: 35px;
            margin-right: 0;
            max-width: 100%;
        }
    }
    .product-data,
    .product-media {
        float: left;
        width: calc(50% - 17.5px);

        @include max-screen(768px) {
            width: 100%;
        }
    }

    .product-data__title {
        font-family: "Proxima Nova";
        font-size: em(16, 14);
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    .product-data {
        ul li {
            margin-bottom: 10px;
        }

        .price-box {
            display: flex;

            .item__price-old {
                font-weight: 400;
                margin-right: 15px;
                text-decoration: line-through;
            }

            .price {
                font-family: $titleStack;
                font-size: em(26, 14);
                font-weight: 600;
                position: relative;
                
                &.including {
                    font-size: em(35, 14);
                }
            }
        }

        .product-data__addto-tabel form label {
            @include max-screen(768px) {
                width: 20%;
            }
        }

        button.btn {
            &:hover {
                background: $green;
                border: 0;
                box-shadow: none;
            }
        }
    }
    .column-100 {
        clear: both;
    }

    .product-navullingen {
        display: flex;
        flex-direction: column;
        padding-top: 35px;
        width: 100%;

        h2 {
            margin-bottom: 35px;

            @include max-screen(768px) {
                font-size: em(20, 14);
            }
        }
    }
}
