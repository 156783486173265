.video {
    height: 417px;
    margin-bottom: 48px;
    max-width: 844px;
    overflow: hidden;
    position: relative;
    width: 100%;
  
    &.video__product-page {
        height: 281px;
    }
  
    @include max-screen(600px) {
        height: 317px;
    }
    @include max-screen(420px) {
        height: 237px;
    }  
}

.product-information__content-item.padding-0 {
    padding: 0;
}

.video__placeholder {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: calc(100% + 16px);
    left: 0;
    position: absolute;
    top: -16px;
    transition: all 0.2s ease;
    visibility: visible;
    width: 100%;
    &::after {
        background-image: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.73)
        );
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}
  
.video__button {
    align-items: center;
    bottom: 0;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    font-size: 12px;
    justify-content: center;
    left: 0;
    letter-spacing: 1.8px;
    margin: auto;
    position: absolute;
    right: 0;
    text-decoration: none;
    text-transform: uppercase;
    top: 0;
    transition: all 0.2s ease;
}

.video__button b {
    font-weight: 900;
}

.video__button svg {
    height: 62px;
    margin-bottom: 14px;
    transition: all 0.2s ease;
    width: 62px;
}

.video__youtube {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease;
    visibility: hidden;
    width: 100%;
    z-index: -1;
}

.video__youtube iframe {
    height: 100%;
    width: 100%;
}


.video:hover .video__button {
    &:hover {
        color: $white;
        text-decoration: none;
    }
    svg {
        transform: scale(1.1);
    }
}

.video--is-playing .video__placeholder {
    filter: blur(4px);
    opacity: 0;
    transform: translateY(16px);
    visibility: hidden;
}

.video--is-playing .video__youtube {
    opacity: 1;
    visibility: visible;
    z-index: 3;
}

.video--is-playing .video__button {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-16px);
}
  