.cms-over-hygienepapier,
.cms-over-hygieneproducten,
.cms-a-propos-de-hygienepapier,
.cms-uber-hallohygiene {
    .page-main {
        max-width: unset;
        width: 100%;
    }
}

.about-hp {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
        color: $hp-about-light-blue;
        font-family: 'Proxima Nova';
		text-transform: none;
	}

	h1 {
		font-size: em(64, 16);
		font-weight: 900;
		line-height: 1.2;

		@include max-screen(1024px) {
			font-size: em(48, 16);
		}

		@include max-screen(768px) {
			font-size: em(32, 16);
		}
	}

	h2 {
		font-size: em(64, 16);
		font-weight: 900;
		line-height: 1.2;

		@include max-screen(1024px) {
			font-size: em(48, 16);
		}

		@include max-screen(768px) {
			font-size: em(32, 16);
		}
	}

	h3 {
		font-size: em(40, 16);
		font-weight: bold;

		@include max-screen(1024px) {
			font-size: em(32, 16);
		}

		@include max-screen(768px) {
			font-size: em(28, 16);
		}
	}

	h4 {
        font-size: em(32, 16);
		font-weight: 500;
		margin-top: 5px;

		@include max-screen(1024px) {
			font-size: em(24, 16);
		}
	}

	p {
        color: $hp-grey;
        font-size: em(20, 16);
        font-stretch: normal;
        font-style: normal;
        font-weight: normal;
        letter-spacing: normal;
		line-height: 1.44;
		text-align: left;

		@include max-screen(1024px) {
			font-size: em(16, 16);
		}

		a {
			color: $hp-darkblue;
		}
	}

	.btn {
		background-color: $hp-about-light-blue;
		color: $white;
		font-weight: bold;
		padding: 10px;
		padding-left: 60px;
		padding-right: 60px;
		transition: all 0.2s ease-in-out;
	}

	.btn.btn--white {
		background-color: $white;
		color: $hp-darkblue;
	}

	@keyframes star-opacity {
		0% {
			opacity: 0;
		}

		30% {
			opacity: 0;
		}

		40% {
			opacity: 0.1;
		}

		50% {
			opacity: 0.75;
		}

		60% {
			opacity: 0.1;
		}

		70% {
			opacity: 0;
		}

		100% {
			opacity: 0;
		}
	}

	@keyframes arrow-movement {
		0% {
			left: 0;
		}

		50% {
			left: 50px;
		}

		60% {
			left: 0;
		}

		100% {
			left: 0;
		}
	}

	@keyframes rating-font-size {
		0% {
			transform: scale(1);
		}

		40% {
			transform: scale(1.05);
		}

		50% {
			transform: scale(1.1);
		}

		60% {
			transform: scale(1.05);
		}

		0% {
			transform: scale(1);
		}
	}

}

.about-hp .about-introduction-container {
	.about-introduction {
		@include max-screen(768px) {
			flex-direction: column;
			margin-top: 50px;
		}

		.about-introduction--text {
			max-width: 700px;

			@include max-screen(768px) {
                margin-top: 50px;
                order: 2;
			}

			p {
				max-width: 520px;
			}
		}

		.about-introduction--medal {
			margin-left: auto;
			margin-right: auto;
			position: relative;

			.star1 {
                animation-duration: 4s;
				animation-iteration-count: infinite;
				animation-name: star-opacity;
                bottom: 20px;
                height: 35px;
                left: 50px;
                opacity: 0;
                position: absolute;
				width: 35px;
			}

			.star2 {
                animation-delay: 1s;
				animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-name: star-opacity;
                bottom: 40px;
                opacity: 0;
				position: absolute;
				right: 50px;
			}

			@include max-screen(768px) {
                max-width: 150px;
                order: 1;
			}
		}
	}

	.about-introduction--graph {
		margin-left: auto;
		margin-right: auto;

		@include max-screen(1280px) {
			overflow-x: auto;
			overflow-y: hidden;
		}

		.about-graph--image {
			width: 1260px;

			img {
				width: 1260px;

				@include min-screen(1440px) {
                    margin-left: 40px;
					width: calc(100% - 40px);
				}
			}

			.about-introduction-date-grid {
				@include min-screen(1440px) {
					margin-left: -20px;
				}

				.about-introduction-date-block {
					h3 {
						color: $hp-gold;
                        font-size: em(28, 16);
                        text-align: center;
					}

					span {
                        color: $hp-grey;
                        display: block;
                        font-family: 'Proxima Nova';
                        font-size: em(18, 16);
						margin-top: 10px;
						text-align: center;
					}
				}

				.about-introduction-date-block:nth-child(2) {
					margin-left: 80px;

					@include min-screen(1440px) {
						margin-left: 70px;
					}
				}

				.about-introduction-date-block:nth-child(3) {
					margin-left: 10px;

					@include min-screen(1440px) {
						margin-left: 30px;
					}
				}

				.about-introduction-date-block:nth-child(4) {
					margin-left: 40px;

					@include max-screen(1440px) {
						margin-left: 50px;
					}
				}

				.about-introduction-date-block:nth-child(5) {
					margin-left: 50px;

					@include max-screen(1440px) {
						margin-left: 40px;
					}
				}

				.about-introduction-date-block:nth-child(6) {
					margin-left: 80px;

					@include max-screen(1440px) {
						margin-left: 70px;
					}
				}
			}

			.about-introduction-mobile-arrow {
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-name: arrow-movement;
                height: 56px;
                opacity: 0;
                position: relative;
                transition: all 0.2s ease-in-out;
                visibility: hidden;
				width: 50px;

				@include max-screen(1280px) {
                    opacity: 1;
                    visibility: visible;
				}

				span {
                    background-color: $hp-blue;
					border-radius: 6px;
                    display: block;
                    height: 6px;
					width: 50px;
				}

				span:first-child {
					position: absolute;
					right: -5px;
                    top: -7px;
                    transform: rotate(45deg);
					width: 25px;
				}

				span:last-child {
					position: absolute;
					right: -5px;
                    top: 7px;
                    transform: rotate(-45deg);
					width: 25px;
				}
			}
		}
	}
}

.about-hp .about-thuiswinkel-container {
	h2 {
		max-width: 800px;
	}

	.about-thuiswinkel--text {
		max-width: 800px;

		p {
			max-width: 730px;
		}
	}

	.about-thuiswinkel--image {
		margin-top: -80px;

		@include max-screen(768px) {
			display: none;
		}
	}
}

.about-hp .about-first-things-container {
	background-color: $hp-lightblue;
    padding-bottom: 1px;
    position: relative;

	&::before {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        content: "";
        height: 200px;
        left: 0;
		position: absolute;
        right: 0;
        top: 0;
		width: 100%;

		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	&::after {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        bottom: 0;
        content: "";
        height: 200px;
        left: 0;
        position: absolute;
		right: 0;
		width: 100%;

		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	h2 {
        color: $white;
		text-align: center;

		@include max-screen(768px) {
			padding-top: 150px;
		}
	}

	.about-first-things-title {
        color: $white;
		text-align: center;
	}

	.about-first-things--block {
        min-height: 350px;
		position: relative;

		@include max-screen(768px) {
            flex-direction: column;
			margin-top: 100px;
			min-height: 400px;
		}

		.about-first-things--text {
			@include max-screen(1280px) {
				max-width: 500px;
			}

			@include max-screen(1024px) {
				max-width: 450px;
			}

			@include max-screen(900px) {
				max-width: 400px;
			}

			@include max-screen(768px) {
				max-width: 100%;
				order: 2;
			}

			h3 {
				color: $white;
			}

			p {
				color: $white;
				font-size: em(26, 16);
				max-width: 550px;

				@include max-screen(1024px) {
					font-size: em(18, 16);
				}
			}

			.about-first-things--text-bubble {
                background-color: $white;
                border-radius: 40px;
                border-top-left-radius: 0;
				max-width: 620px;
                min-height: 100px;
                padding: 40px;
				padding-top: 30px;
				padding-bottom: 30px;
                position: relative;
				
				@include max-screen(768px) {
					margin-top: 100px;
					padding: 20px;
					padding-top: 15px;
					padding-bottom: 15px;
				}

				&::before {
                    background-color: $white;
                    content: "";
                    height: 50px;
                    left: 0;
					position: absolute;
					top: -50px;
					width: 35px;
					
				}

				&::after {
					background-color: $hp-lightblue;
					border-radius: 100%;
                    content: "";
                    height: 100px;
                    left: 1px;
					position: absolute;
					top: -100px;
					width: 70px;
				}

				img {
					margin-left: 10px;
					margin-right: 50px;

					@include max-screen(768px) {
						margin-right: 30px;
					}
				}

				p {
                    color: $hp-darkblue;
					font-weight: bold;
					margin: 0;
				}
			}
		}

		.about-first-things--image-circle-container {
			@include max-screen(768px) {
				order: 1;
			}

			.about-first-things--image-circle {
                background-color: $white;
                border: 20px solid $hp-darkblue;
                border-radius: 100%;
                height: 400px;
				width: 400px;

				@include max-screen(1280px) {
                    border: 15px solid $hp-darkblue;
					height: 300px;
					width: 300px;
				}

				@include max-screen(1024px) {
                    height: 250px;
                    width: 250px;
				}

				@include max-screen(768px) {
                    border: 5px solid $hp-darkblue;
                    height: 150px;
					width: 150px;
				}

				img {
					max-height: calc(100% + 10px);
				}
			}
		}
	}

	.about-first-things--block:last-child {
		margin-bottom: 100px;

		.about-first-things--text {
            bottom: -50px;
			left: 0;
			position: absolute;
			z-index: 1;

			.about-first-things--text-bubble {
				border: 5px solid $hp-lightblue;

				img {
					height: 30px;
					margin: 0;
				}
			}
		}

		.about-first-things--image-circle-container {
            bottom: -50px;
            position: absolute;
            right: 0;
			z-index: 1;

			@include max-screen(768px) {
				position: static;
			}
		}
	}
}

.about-hp .about-steps-container {

	h2,
	h4 {
		color: $hp-darkblue;
		text-align: center;
	}

	.about-steps--step-block {

		.about-steps--step-block-image {
			margin: 0 auto;
			padding-left: 5px;
			padding-right: 5px;
		}

		.about-steps--step-block-text {
			margin: 0 auto;
			padding-left: 5px;
			padding-right: 5px;

			h3 {
				color: $hp-darkblue;
			}

			p {
                color: $hp-darkblue;
				font-size: em(24, 16);
				max-width: 640px;

				@include max-screen(1024px) {
					font-size: em(18, 16);
				}
			}
		}
	}

	// Width adjustments per text block as seen in design
	.about-steps--step-block:nth-child(3),
	.about-steps--step-block:nth-child(4) {
		.about-steps--step-block-text {
			max-width: 640px;
		}
	}

	.about-steps--step-block:nth-child(6) {
		.about-steps--step-block-text {
			max-width: 450px;
		}
	}

	.about-steps--step-block:nth-child(7) {
		.about-steps--step-block-text {
			max-width: 570px;
		}
	}

	.about-steps--step-block-big {
		flex-direction: column;

		.about-steps--step-block-image {
			img {
				width: 100%;
			}
		}

		.about-steps--step-block-text {
			h3 {
				color: $hp-darkblue;
				text-align: center;
			}

			p {
                color: $hp-darkblue;
                font-size: em(24, 16);
				max-width: 640px;
				text-align: center;

				@include max-screen(1024px) {
					font-size: em(18, 16);
				}
			}
		}
	}
}

.about-hp .about-fame-container {
    background-color: $hp-lightblue;
    position: relative;
	
	@include max-screen(768px) {
		margin-top: 100px;
	}

	&::before {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        content: "";
        height: 200px;
        left: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		
		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	&::after {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        bottom: 0;
        content: "";
        height: 200px;
        left: 0;
		position: absolute;
		right: 0;
		width: 100%;

		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	h2 {
        color: $white;
		text-align: center;

		@include max-screen(768px) {
			padding-top: 150px;
		}
	}

	.about-fame-title {
        color: $white;
		text-align: center;
	}

	.about-fame-block {
		.about-fame-block--text {
			h3 {
                color: $white;
				max-width: 430px;
			}

			p {
                color: $white;
				font-size: em(24, 16);
				max-width: 570px;

				@include max-screen(1024px) {
					font-size: em(18, 16);
				}
			}
		}

		.about-fame-block--image {
			position: relative;

			.star1 {
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-name: star-opacity;
                bottom: 20px;
                height: 35px;
                left: 50px;
                opacity: 0;
                position: absolute;
				width: 35px;
			}

			.star2 {
				animation-duration: 5s;
				animation-delay: 1s;
                animation-iteration-count: infinite;
                animation-name: star-opacity;
                bottom: 40px;
                opacity: 0;
				position: absolute;
				right: 50px;
			}

			.star3 {
                animation-delay: 1.5s;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-name: star-opacity;
                bottom: 60px;
                height: 70px;
                opacity: 0;
				position: absolute;
                right: 70px;
                width: 60px;
			}
		}
	}

	.about-fame-block:nth-child(odd) {
		.about-fame-block--image {
			padding-right: 10px;
		}
	}

	.about-fame-block:nth-child(even) {
		.about-fame-block--image {
			padding-left: 10px;
		}
	}
}

.about-hp .about-opinions-container {
	@include max-screen(1024px) {
		margin-top: 50px;
	}

	.about-opinions-block {
		@include max-screen(1024px) {
			margin-top: 50px;
		}

		@include max-screen(768px) {
			flex-direction: column;
		}

		&.about-opinions-block--grid {
			@include max-screen(768px) {
				flex-direction: column;
				margin-top: 0;
			}
		}

		.about-opinions-block--text {
			h2 {
				color: $hp-darkblue;
			}

			p {
                color: $hp-darkblue;
                font-size: em(24, 16);
				max-width: 700px;

				@include max-screen(1024px) {
					font-size: em(18, 16);
				}
			}
		}

		.about-opinions-block--kiyoh {
			margin: 0 auto;

			.widget--gradient {
				background: none;
				border: none;

				p {
					color: $black;
				}
			}
		}

		.about-opinions-block--note {
            border: 6px solid $hp-gold;
            border-radius: 6px;
            max-width: 470px;
            min-height: 460px;
            position: relative;
			width: calc(50% - 82px);

			@include max-screen(768px) {
                height: auto;
                margin-top: 50px;
                min-height: auto;
                width: 100%;
			}

			&::before {
                border-left: 7.5px solid $hp-gold;
				border-right: 7.5px solid $hp-gold;
                content: "";
                height: 50px;
                left: 60px;
                position: absolute;
                top: -25px;
				width: calc(50% - 115px);
			}

			&::after {
                border-left: 7.5px solid $hp-gold;
				border-right: 7.5px solid $hp-gold;
                content: "";
                height: 50px;
                position: absolute;
                right: 60px;
				top: -25px;
				width: calc(50% - 115px);
			}

			&.about-opinions-block--image-note {
				@include max-screen(768px) {
					display: none;
				}
			}

			.about-opinions-block--note-number {
                background-color: $hp-gold;
                border-radius: 100%;
                color: $white;
                height: 80px;
                position: absolute;
                right: -40px;
                top: -40px;
				width: 80px;

				@include max-screen(768px) {
                    height: 40px;
                    right: -20px;
                    top: -20px;
                    width: 40px;
				}

				>span {
                    animation-duration: 5s;
                    animation-iteration-count: infinite;
                    animation-name: rating-font-size;
					font-size: em(44, 16);
					font-weight: bold;
					text-align: center;
                    transition: all 0.2s ease-in-out;
                    width: 100%;

					@include max-screen(768px) {
						font-size: em(32, 16);
					}
				}

				&.number--with-arrow {
					border-radius: 16px;
					width: 160px;
					z-index: 4;

					.note-arrow {
                        display: inline-block;
                        height: 13px;
                        position: relative;
						width: 40px;
						

						span {
                            background-color: $white;
							border-radius: 2px;
                            height: 5px;
                            left: 0;
                            position: absolute;
                            width: 40px;

							&:first-child {
                                left: auto;
                                right: 0;
                                transform: rotate(45deg);
                                top: -6px;
                                width: 17.5px;
							}

							&:last-child {
								bottom: 2px;
                                left: auto;
                                right: 0;
                                transform: rotate(-45deg);
                                width: 17.5px;
							}
						}
					}
				}

			}

			.about-opinions-block--note-fold {
				@include max-screen(768px) {
					display: none;
				}

				.about-opinions-block--note-fold-1 {
                    background-color: $hp-gold;
					border-top-left-radius: 6px;
                    bottom: 0;
                    height: 150px;
                    position: absolute;
					right: 0;
					width: 150px;
				}

				.about-opinions-block--note-fold-2 {
					border-bottom: 97px solid transparent;
					border-right: 97px solid rgb(252, 252, 252);
                    border-top: 97px solid transparent;
                    bottom: 12px;
                    height: 0;
                    position: absolute;
					right: 60px;
                    transform: rotate(45deg);
                    width: 0;
				}

				.about-opinions-block--note-fold-3 {
                    border-bottom: 120px solid transparent;
                    border-left: 120px solid $white;
					border-top: 120px solid transparent;
                    bottom: -102px;
                    height: 0;
                    position: absolute;
                    right: -25px;
                    transform: rotate(45deg);
					width: 0;
				}
			}

			.about-opinions-block--note-image {
				padding: 40px;
			}

			.about-opinions-block--note-text {
				padding: 40px;
				padding-top: 60px;

				@include max-screen(768px) {
					padding: 20px;
					padding-top: 40px;
				}

				h3 {
					color: $hp-darkblue;
				}

				p {
					color: $hp-darkblue;
					font-size: em(23, 16);

					@include max-screen(1024px) {
						font-size: em(16, 16);
					}
				}
			}
		}
	}
}

.about-hp .about-sharing-container {
	background-color: $hp-lightblue;
	position: relative;

	@include max-screen(768px) {
		margin-top: 100px;
	}

	&::before {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        content: "";
        height: 200px;
        left: 0;
        position: absolute;
		top: 0;		
		right: 0;
		width: 100%;

		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 100%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	&::after {
        background: rgba(255, 255, 255, 0) radial-gradient(75% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
        bottom: 0;
		content: "";
		height: 200px;
        left: 0;
        position: absolute;
		right: 0;
		width: 100%;
		
		@include max-screen(768px) {
			background: rgba(255, 255, 255, 0) radial-gradient(125% 120px at 50% 0%, rgba(255, 255, 255, 0) 100%, #fff 101%);
		}
	}

	h2 {
        color: $white;
		text-align: center;

		@include max-screen(768px) {
			padding-top: 150px;
		}
	}

	p {
        color: $white;
        font-size: em(28, 16);
		margin: 0 auto;
		margin-top: 15px;
		max-width: 850px;
		text-align: center;

		@include max-screen(768px) {
			font-size: em(20, 16);
		}

	}

	.about-sharing-grid {
		margin-left: auto;
        margin-right: auto;
        max-width: 720px;

		a {
			text-decoration: none;

			.about-sharing-block {
                background-color: $white;
                height: calc(100% - 40px);
                margin-bottom: 40px;
                max-width: 340px;
				padding: 25px;
				transition: all 0.2s ease-in-out;

				@include max-screen(768px) {
					margin-left: auto;
					margin-right: auto;
				}

				.about-sharing-block-image {
                    background-size: cover;
                    height: 212px;
                    width: 100%;
				}

				h3 {
					color: $hp-darkblue;
					font-size: em(26, 16);
				}

				p {
					color: $hp-darkblue;
					font-size: em(16, 16);
					text-align: left;

					span {
						display: inline-block;
						transform: translate(0);
						transition: all 0.2s ease-in-out;
					}
				}
			}

			&:hover {
				.about-sharing-block {
					span {
						text-decoration: underline;
						transform: translate(2px);
					}
				}
			}
		}
	}
}

.about-hp .about-who-container {
	@include max-screen(768px) {
		margin-top: 50px;
	}

	h2 {
		text-align: center;
	}

	p {
		margin-left: auto;
		margin-right: auto;
        max-width: 600px;
        text-align: center;
	}

	.about-who-grid {
		max-width: 800px;
		margin-left: auto;
		margin-right: auto;

		.about-who-block {
			width: 33.33333%;

			@include max-screen(768px) {
				width: 50%;
			}

			h3 {
				text-align: center;
			}

			p {
				text-align: center;

				@include max-screen(768px) {
					max-width: 140px;
				}
			}
		}
	}

	.about-who-team-image {
		text-align: center;
	}
}

.about-hp .about-why-container {
	.about-why-text-container {
		@include max-screen(768px) {
			flex-direction: column;
		}

		.about-why-text {
			max-width: 600px;
		}

		.about-why-image {
			margin-left: auto;
			margin-right: auto;

			@include max-screen(768px) {
				margin-top: 20px;
			}
		}
	}
}
