.account-information__block.invoices {
    .invoices-block {
        display: flex;
        justify-content: flex-start;
    }

    .invoices-block--single {
        background: $blueTertiary;
        box-shadow: 0 1.89527px 0 #427EC1;
        border-radius: 4px;
        font-family: $titleStack;
        margin-right: 10px;
        padding: 15px;
        width: calc(25% - 5px);

        p {
            color: $hp-blue;
            font-weight: 700;
            text-transform: uppercase;
        }
        
        time {
            display: block;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 40px;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
