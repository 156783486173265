$torkBlue: #003466;
$torkLightBlue: #0099ff;

.cms-merken-tork {
    main {
        @include max-screen(768px) {
            margin: 35px auto;
        }
    }
    .wrapper--flex {
        flex-wrap: nowrap;
        flex-flow: initial;
    }
    .wrapper.clearfix.wrapper--category {
        max-width: none;
        width: 100%;
    }
    .btn.btn--green {
        @include button;
        background-color: $euroGreen;
        box-shadow: 1px 1px 0 0 $euroGreen;
    }
    .sidebar-main {
        position: sticky;
        top: 50px;

        @include max-screen(1400px) {
            top: 50px;
        }

        @include max-screen(768px) {
            position: absolute;
            visibility: hidden;
        }

        .kiyoh-block {
            background: none;
            color: $torkBlue;
            margin-top: 35px;
            
            @include max-screen(1400px) {
                padding: 25px;
            }

            @include max-screen(1400px) {
                margin-top: 25px;
            }

            h3 {
                font-size: em(20, 14);
                letter-spacing: 1.3px;
                line-height: 1.2;
                text-transform: uppercase;
            }
        }
    }

    .content--left {
        h1, 
        h2, 
        h3, 
        h4, 
        h5 {
            color: $torkBlue;
        }

        h1 {
            font-size: em(60, 14);
            font-weight: 800;
        }

        h2,
        h3 {
            font-size: em(28, 14);
            font-weight: 900;
            margin-bottom: 5px;
        }

        .header--sub {
            color: $torkLightBlue;
            font-family: $titleStack;
            font-size: em(24, 14);
            font-weight: 600;
            line-height: normal;
            margin: 0;
            text-transform: none;
        }

        h4 {
            font-size: 2em;
            font-weight: 900;
            margin-bottom: 5px;
        }

        h5 {
            font-size: em(28, 14);
            font-weight: 900;
            text-transform: uppercase;
        }

        p {
            font-size: em(16, 14);
        }
        .label-detail--description {
            @include max-screen(768px) {
                header {
                    flex-direction: column;

                    img {
                        margin-top: 20px;
                    }
                }
            }

            div {
                @include max-screen(1024px) {
                    flex-direction: column;
                }
            }
            p.subEuroTitle {
                color: $torkLightBlue;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.8px;
                margin: 0;
                text-transform: uppercase;
            }

            h1 {
                color: $torkBlue;
                text-transform: none;
            }

            header img {
                height: 86px;
                width: 144px;
            }
            
            img.label-detail--description-content {
                width: 322px;
                height: 383px;
                margin-left: 40px;
                @include max-screen(768px) {
                    margin: 0;
                }
            }

            p {
                max-width: 500px;
            }
        }

        .tork--jouwbranche {
            p {
                max-width: 700px;
            }
            ul.expert__products-categories__filter-list {
                margin-left: -8px;
                margin-right: -8px;
        
                li {
                    border: 2px solid $hp-about-light-blue;
                    border-radius: 3px;
                    color: $hp-about-light-blue;
                    font-size: em(14, 14);
                    font-weight: bold;
                    margin-bottom: 16px;
                    margin-left: 8px;
                    margin-right: 8px;
                    padding: 12px 32px;
                    letter-spacing: 0.7px;
                    transition: 0.2s ease-in-out all;
                    
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.05);
                    }
                    &.is-active {
                        background-color: $hp-about-light-blue;
                        color: $white;
                    }
                }
            }

            .expert__grid {
                >h3 {
                    font-weight: bold;
                    margin-bottom: 14px;
                    text-transform: initial;
        
                    span {
                        color: $euroProdYellow;
                    }
                }
                p {
                    color: $torkLightBlue;
                    font-family: $titleStack;
                    font-size: em(14, 14);
                    line-height: normal;
                    margin: 0;
                    max-width: 769px;
                    text-transform: uppercase;
                }
            }
        }
        .tork--functionaliteit {
            p.header--sub {
                max-width: none;
            }

            p {
                max-width: 430px;
            }

            .btn.btn--tork-blue {
                @include button;
                background-color: $torkBlue;
                box-shadow: 1px 1px 0 0 $torkBlue;
                width: 250px;
            }

            img {
                height: 512px;
                margin-left: 90px;
                width: 388px;

                @include max-screen(1268px) {
                    margin: 0;
                }
            }

            @include max-screen(1268px) {
                div {
                    flex-direction: column;
                }
                img {
                    margin-top: 40px;
                }
            }
        }

        .tork--servetten {
            p {
                max-width: 700px;
            }
            .header--sub {
                margin-bottom: 25px;
            }
        }

        .tork--schermen {
            p {
                max-width: 700px;
            }
            
            h4 {
                font-size: inherit;
                text-transform: uppercase;
            }
        }

        .pre-footer {
            flex-direction: column;
            text-align: center;
        }

        .bellen-mag-contact {
            .bellen-mag-contact__blocks {
                .bellen-mag-contact__block-content {
                    span, 
                    svg {
                        color: $torkBlue;
                    }
                }
                .bellen-mag-contact__block {
                    border-color: $torkBlue;
                }
            }
        }
    }
} 
