.cms-merken-santral,
.cms-merken-satino {
    .label-detail {
        .title {
            display: inline-block;
            font-size: em(40, 16);
        
            @include max-screen(1024px) {
                font-size: em(32, 16);
            }
        }

        .btn--centered {
            display: table;
            margin-left: auto;
            margin-right: auto;
        }

        .label-detail--label-description {
            .label-detail--label-logo {
                float: right;
                height: 150px;
                margin-left: 50px;
                padding: 20px;
        
                @include max-screen(1024px) {
                    height: 100px;
                    margin-left: 10px;
                }
                @include max-screen(768px) {
                    height: 40px;
                    margin-left: 10px;
                    padding: 0;
                }
            }
        }
    
        .label-detail--top-categories {
            @include max-screen(1024px) {
                flex-direction: column;
            }
            .top-categories-list {
                li {
                    font-size: em(18, 16);
                    margin-bottom: 40px;
                    margin-right: 50px;
                    min-width: 100px;
                    width: calc(33.333% - 50px);
        
                    @include max-screen(1024px) {
                        margin-bottom: 20px;
                        width: 100%;
                    }
                    @include max-screen(768px) {
                        margin-bottom: 20px;
                        width: 100%;
                    }
                    a {
                        position: relative;
                        width: 100%;
                    }
                }
            }
        }

        .label-detail--info-blocks {
            @include max-screen(1024px) {
                flex-direction: column;
            }
            .label-detail--info-block {
                border: 2px solid #f3f9ff;
                padding: 30px;
                width: calc(50% - 12px);

                .btn {
                    color: $white;
                }
        
                @include max-screen(1024px) {
                    width: 100%;
                }
                .title {
                    @include max-screen(768px) {
                        font-size: em(28, 16);
                    }
                }
                &:last-child {
                    margin-left: 24px;

                    @include max-screen(1024px) {
                        margin-left: 0;
                        margin-top: 25px;
                    }
                }
            }
        }

        .image-block {
            align-items: flex-start;
            background-blend-mode: multiply;
            color: $white;
            display: flex;
            min-height: 250px;
            text-decoration: none;
            
            &::before {
                background: none;
            }
            .image-block__content {
                min-height: 182px;

                h3,
                span {
                    color: $white;
                    font-family: $titleStack;
                    line-height: 23px;
                    text-transform: uppercase;
                }
                .btn {
                    float: none;

                    span.fa-caret-right {
                        &:before {
                            color: white;
                            content: "\f105";
                            display: inline-block;
                            font-family: "FontAwesome";
                            font-size: 13px;
                            font-weight: 900;
                            margin-right: 5px;
                        }
                    }
                }
                .btn--bottom {
                    bottom: 0;
                    position: absolute;
                }
        
            }
        }
    }
    .pre-footer {
        flex-direction: column;
        text-align: center;

        h5 {
            font-size: em(28, 14);
            font-weight: 800;
            text-transform: uppercase;
        }
    }
}
  