.consent-cookie-directive > div > div > div a.action.accept-all.btn,
.consent-cookie-directive > div > div > div a.action.accept.btn {
    @extend .btn--green;
    color: white;
}
.consent-cookie-directive > div > div > div a.action.customize.btn {
    @extend .btn--blue;
    color: white;
}
.consent-cookie-directive > div > div > div a.action.decline.btn {
    @extend .btn--gold;
}
.consent-cookie-directive > div > div > div a.action.accept-all,
.consent-cookie-directive > div > div > div a.action.accept,
.consent-cookie-directive > div > div > div a.action.customize,
.consent-cookie-directive > div > div > div a.action.decline {
    @extend .btn;
    color: white;
}
