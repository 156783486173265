.klantenservice_all {
    h1 {
        color: $blueSecondary;
        font-size: em(28, 14);
        margin-bottom: 30px;
    }
}

.contact_row {
    align-content: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
  
    @include max-screen(1300px) {
        flex-direction: column;
    }
}
  
.contact_blocks {
    display: inline;
    flex-direction: column;
    list-style-type: none;

    span.fa-phone,
    span.fa-envelope,
    span.fa-home,
    span.fa-question-circle,
    span.fa-credit-card-alt {
        &:before {
            color: $hp-blue;
            display: inline-block;
            height: 35px;
            font-family: "FontAwesome";
            font-size: 17px;
            font-weight: 900;
            padding: 6px;
            width: 35px;
        }
    }

    span.fa-phone:before {
        content: "\f095";
    }

    span.fa-envelope:before {
        content: "\f0e0";
    }

    span.fa-home:before {
        content: "\f015";
    }

    span.fa-question-circle:before {
        content: "\f095";
    }

    span.fa-credit-card-alt:before {
        content: "\f09d";
    }

    h3 {
        color: $blueSecondary;
        font-size: em(16, 14);
        margin-bottom: 0;
        padding-bottom: 0;
    }

    p {
        margin: 0;
    }

    span {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
    }
}
  
.contact_block_single {
    align-items: center;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 30px;
    max-height: 80px;
    min-width: 300px;
    text-align: left;
    width: 40%;

    h3 {
        text-transform: none;
    }

    b {
        font-weight: 600;
    }
}

.form.contact {
    .fieldset > .field.required > .label {
        &:after {
            display: none;
        }
        &:before {
            content: '*';
            color: $blue;
            font-size: em(14, 14);
            margin: 0 5px 0 0;
        }
    }
    .btn:hover {
        background: $blueSecondary;
        border: 0;
        box-shadow: none;
        color: white;
    }
}
  
.faq_content {
    p,
    a {
        font-size: $baseFontSize;
    }

    i {
        color: $blueSecondary;
        font-size: 25px;
        padding: 5px;
        transition: all 0.2s $transitionPrimary;
    }
  
    a,
    p a {
        padding-left: 0;
        text-decoration: underline;
    
        &:hover {
            text-decoration: none;
        }
    }
  
    a.toggle_row {
        padding-left: 5px;
        text-decoration: none;
    }
  
    hr {
        background-color: $graySecondary;
        border: 0;
        height: 1px;
        margin: 10px 0;
    }

    h4 {
        font-size: em(22, 14);
        margin-bottom: 20px;
    }
  
    .row_align {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
  
        &::before {
            content: '\f107';
            display: inline-block;
            font-family: 'FontAwesome';
            margin-right: 10px;
            transition: all 0.2s $transitionPrimary;
        }
    
        &.is-toggled {
            a {
                font-weight: bold;
            }
        }
    }
  
    .row_align.is-toggled {
        &::before {
            transform: rotate(180deg);
        }
    }
  
    .faq_block {
        p {
            margin-bottom: 9px;
            margin-top: 5px;
            max-height: 0;
            overflow: hidden;
            padding-left: 25px;
            transition: all 0.4s $transitionPrimary;
    
            &.is-toggled {
                display: block;
                margin-bottom: 15px;
                max-height: 999px;
                padding-top: 0;
            }
        }
    
        a {
            user-select: none;
        }
    }
}

.klantenservice_footer {
    align-content: stretch;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 auto;
    position: relative;
    width: 100%;    
  
    ul {
        list-style-type: none;
        margin: 0;
        padding-left: 0;
    }
  
    a {
        font-size: $baseFontSize;
        text-decoration: none;
    }
  
    ul li:last-child {
        bottom: 28px;
        position: absolute;

        a {
            font-weight: 600;
            text-decoration: underline;
        }
    }
  
    @include max-screen(1300px) {
        margin-left: 0;
    }
}
  
.klantenservice_footer_blokje {
    border-left: solid 1px #e2e2e2;
    border-right: solid 1px #e2e2e2;
    height: 270px;
    margin-left: -1px;
    margin-top: 15px;
    min-width: 100px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 190px;

    h3 {
        font-size: em(21, 14);
        height: 26px;
        margin-bottom: 30px;
    }

    @include max-screen(459px) {
        border: none;
        border-top: solid 1px #e2e2e2;
    }
}
  
.klantenservice_text {
    p {
        margin-bottom: 40px;
    }
}
