@mixin no-room {
    margin: 0;
    padding: 0;
    border: 0;
    }

@mixin no-list {
    list-style: none;
    margin: 0;
    padding: 0;
    }

@mixin hidden {
    visibility: hidden;
    position: absolute;
    left: -9999em;
}

@mixin full-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin opacity-bg-image($opacity) {
    * {
        position: relative;
    }
    &:before {
        background: rgba(255, 255, 255, $opacity);
        bottom: 0;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 0;   
    }
}

@mixin full-absolute {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

@mixin clearfix {
    &:after {
        clear: both;
        content: "";
        display: table;
    }
}

@mixin button {
    border-radius: 3px;
    font-family: $readStack;
    font-size: em(14, 14);
    font-weight: bold;
    letter-spacing: 0.6px;
    min-height: 42px;
    &:hover {
        opacity: 0.8;
    }
}

@mixin bigtitle {
    display: block;
    font-family: $titleStack;
    font-size: em(24, 14);
    font-weight: 900;
    text-transform: uppercase;
}

@mixin normaltitle {
    display: block;
    font-family: $readStack;
    font-size: em(20, 14);
    font-weight: bold;
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}
