// Colours
$backgroundMobileNav: #247aca;
$black: #000;
$blue: #34495e;
$blueLight: #dde9f4;
$blueLightSecondary: #e6f2fc;
$blueSecondary: #41a1e6;
$blueTertiary: #f1f8ff;
$blueQuarternary: #1e5485;
$borderMobileNav: #6da9e1;
$darkBlue: #38495c;
$darkGreen: #337e0b;
$darkGrey: #4a4a4a;
$error: #bf2c2c;
$euroGreen: #42ba0f;
$euroProdLightBlue: #3997e2;
$euroProdBlue: #0154a0;
$euroProdYellow: #fcc154;
$facebook: #3b5998;
$gray: #e2e2e2;
$gray2: #ededed;
$grayBG: #f1f1f1;
$grayBorder: #dbdbdb;
$graySecondary: #d2d2d2;
$grayTertiary: #9b9b9b;
$grayWhite: #ececec;
$green-shopping-cart: #558d37;
$grey: rgb(150, 150, 150);
$gold: #ffc154;
$goldSecondary: #bd9a5c;
$green: #78c64d;
$hp: #34495e;
$hp-about-light-blue: #50aae9;
$hp-blue: #358ee0;
$hp-darkblue: #287bb5;
$hp-gold: #f9c363;
$hp-green: #52cb10;
$hp-grey: #627077;
$hp-lightblue: #41a1e6;
$hp-orange: #f7b653;
$kc-blue: #1f3a66;
$light-silver: rgb(240, 240, 240);
$orange: #ec691d;
$ovalBlue: #579ee0;
$priceBlue: #358ee0;
$productRed: #ff686c;
$red: #7b3b15;
$silver: #c5c5c5;
$strangeGreen: #477f29;
$strangeGrey: #717171;
$success: #78c64d;
$warning: #ffc831;
$white: #ffffff;

// Fonts
$baseFontSize: 14px;
$readStack: "Open Sans", "Helvetica", "Arial", sans-serif;
$titleStack: "Proxima Nova", "Helvetica", "Arial", sans-serif;
$btnStack: "Proxima Nova", "Arial", sans-serif;

// Gradient
$blueGradient: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 28%, rgba(65, 161, 230, 0.15) 100%);

// Gutter
$gutter: 35px;

// Transitions
$transitionPrimary: cubic-bezier(0.55, 0, 0.1, 1);
