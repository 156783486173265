.page-wrapper > .page-bottom {
    @include min-screen(1280px) { width: 1260px; }
    @include max-screen(1280px) { width: 1024px; }
    @include screen(1024px, 1280px) { width: 1000px; }
    @include max-screen(1040px) {
        margin: 0 auto;
        width: 90%; 
    }
}
.page-bottom {
    // Separator
    .separator {
        @extend .clearfix;
        background: $blue;
        border: 0;
        display: block;
        height: 1px;
        margin: 35px 0;
        width: 100%;
    
        &--white {
            background: #fff;
        }
    
        &--blue-tertiary {
            background: $blueTertiary;
        }
    
        &--big {
            height: 5px;
            margin: 70px 0;
        }
    }

    .footerblock-title {
        font-size: em(21, 14);
        margin: 0 auto 20px auto;
        text-align: center;
    }

    .seo-block {
        width: 100%;
      
        &__title {
            background: $blueTertiary;
            display: block;
            font-size: em(16, 14);
            margin: 0 0 10px 0;
            padding: 10px;
            width: 100%;
        }

        h2 {
            font-size: em(21, 14);
        }
        
        p {
            font-size: em(16, 16);
            color: $blue;
            margin: 14px 0;
            
            @include max-screen(768px) {
                padding: 0;
            }
        }
    }
}
