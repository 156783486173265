$sanferBlue: #003466;
$sanferLightBlue: #0099ff;

.cms-merken-sanfer,
.cms-merken-santral-ingoman-sanfer {
    overflow-x: hidden;

    .wrapper--flex {
        flex-flow: initial;
        flex-wrap: nowrap;
    }

    .wrapper.clearfix.wrapper--category {
        max-width: none;
        width: 100%;
    }

    .sanfer-detail {
        font-family: $titleStack;

        h1,
        h2,
        h5 {
            color: $sanferBlue;
            font-family: $titleStack;
            text-transform: initial;
        }

        h4,
        h6,
        span.title--subtitle {
            color: $sanferLightBlue;
            font-family: $titleStack;
            font-weight: 700;
            letter-spacing: 0.8px;
            text-transform: uppercase;
        }

        h1 {
            font-size: em(60, 14);
        }

        h2, 
        h3 {
            font-size: em(28, 14);
            font-weight: 900;
            text-transform: uppercase;
        }

        h4.sub-title, 
        span.sub-title {
            color: $sanferLightBlue;
            font-size: em(24, 14);
            font-weight: 600;
        }

        p {
            font-family: $readStack;
            font-size: em(16, 14);
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            line-height: 1.56;
        }

        a {
            text-decoration: none;
        }

        .btn.btn--orange {
            background-color: $orange;
            border-radius: 3px;
            box-shadow: 1px 1px 0 0 $red;
            font-family: $readStack;
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: normal;
            min-height: 42px;
            padding: 12px 32px;
            transition: 0.2s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }
        }

        .topProducten404 {
            h4 {
                color: $hp-lightblue;
            }

            .product-grid {
                margin-bottom: 0;
            }

            li.item {
                @include min-screen(1179px) {
                    margin-bottom: 0;
                }
            }
        }

        .sanfer-detail_introduction {
            display: flex;

            @include max-screen(768px) {
                flex-flow: column;
            }

            .sanfer-detail_introduction-content {
                margin-right: 43px;
                max-width: 560px;

                @include max-screen(768px) {
                    margin-right: 0;
                }

                h1 {
                    font-weight: bold;
                    line-height: 1.1;
                }

                h2 {
                    font-weight: bold;
                    letter-spacing: 0.8px;
                    line-height: normal;
                }

                p {
                    max-width: 539px;
                }
            }

            .sanfer-detail_introduction-images {
                max-width: 356px;

                @include max-screen(768px) {
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        .sanfer-detail__label {
            @include max-screen(1280px) {
                margin-top: 45px;
            }

            .sanfer-detail__label-top {
                display: flex;
                justify-content: space-between;
                margin-right: 140px;

                @include max-screen(1280px) {
                    flex-direction: column;
                }

                .sanfer-detail__label-introduction {
                    @include max-screen(1280px) {
                        order: 2;
                    }
                }

                .sanfer-detail__label-logo {
                    padding-bottom: 20px;
                    width: 200px;

                    @include max-screen(1280px) {
                        order: 1;
                    }
                }

                &.sanfer {
                    margin-right: 195px;

                    .sanfer-detail__label-logo {
                        width: 148px;
                    }
                }

                &.ingo-man {
                    margin-right: 0;

                    .sanfer-detail__label-top__titles {
                        @include max-screen(1280px) {
                            flex-direction: column;
                        }
                    }

                    .sanfer-detail__label-top__description {
                        @include max-screen(1280px) {
                            margin-right: 20px;
                        }

                        .sanfer-detail__label-logo {
                            margin-right: -35px;
                            width: 148px;

                            @include max-screen(1280px) {
                                margin-right: 0;
                            }
                        }

                        p {
                            max-width: 515px;
                        }
                    }
                }
            }

            p {
                max-width: 769px;
            }

            .sanfer-detail__label-image {
                @include max-screen(1280px) {
                    margin-right: 0;
                    margin-top: 30px;
                }
            }
        }

        .expert-disclaimer {
            position: relative;

            @include max-screen(1280px) {
                &::before {
                    background-color: rgb(240, 240, 240);
                    content: "";
                    height: 3px;
                    left: 0;
                    position: absolute;
                    width: 51px;
                }

                &::after {
                    background-color: rgb(240, 240, 240);
                    content: "";
                    height: 3px;
                    position: absolute;
                    right: -33px;
                    width: 51px;
                    
                }
            }

            .expert-disclaimer__item {
                letter-spacing: normal;
                margin-left: 32px;

                &:before {
                    background-color: $sanferLightBlue;
                }

                &:before,
                &:after {
                    @include max-screen(1024px) {
                        display: none;
                    }
                }

                @include max-screen(1280px) {
                    margin-left: 22px;
                }

                span {
                    color: $gold;
                }
            }
        }
    }

    .bellen-mag-contact {
        .bellen-mag-contact__block {
            border-color: $sanferBlue;

            &:first-child,
            &:nth-child(2) {
                a {
                    color: #34495e;
                    font-family: $readStack;
                    font-size: em(20, 14);
                    font-weight: normal;
                }
            }

            svg circle {
                color: $sanferBlue;
                fill: $sanferBlue;
            }

            .bellen-mag-contact__block-content {
                span:first-child {
                    color: $sanferBlue;
                }
            }
        }
    }

    .pre-footer {
        h5 {
            font-size: em(28, 14);
            font-weight: 900;
            text-align: center;
        }
    }
}
