.catalog-product-view .page-main {
    max-width: 100%;
    width: 100%;
}

.product-wrapper {
    padding: 0 80px;
    width: 100%;

    @include max-screen(1280px) {
        padding: 0 40px;
    }

    @include max-screen(768px) {
        padding: 0;
    }
}

.product-hero .product-wrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
}

.product-hero .product-wrapper .product-hero__featured-labels {
    li {
        &:not(:first-child) {
            margin-left: 15px;

            @include max-screen(768px) {
                margin-left: 30px;
            }
        }

        img {
            max-height: 40px;
        }

        &.is-label {
            img {
                max-height: 50px;
            }
        }
    }
    .hide-desktop {
        @include min-screen(768px) {
            display: none;
        }
    }
}

.product-hero .product-wrapper .product-hero__top {
    position: relative;
    width: 100%;

    .hide-desktop {
        @include min-screen(768px) {
            display: none;
        }
    }

    @include max-screen(768px) {
        border-bottom: 1px solid $grayBorder;
        flex-direction: column;
        margin-top: 40px;
        order: 2;
        padding-bottom: 15px;
    }
}

.product-hero .product-wrapper .product-hero__top .product-hero__top-info {
    h1.title {
        font-size: em(32, 14);
        font-weight: 900;
        line-height: 0.97;
        max-width: 650px;

        @include max-screen(768px) {
            font-size: em(24, 14);
        }
    }

    ul {
        li {
            color: $hp;
            display: inline-block;
            font-size: em(14, 14);
            font-stretch: normal;
            font-style: normal;
            font-weight: normal;
            letter-spacing: normal;
            line-height: 1.21;

            &.hpkeuze {
                align-items: center;
                background-color: $blueTertiary;
                color: $blueSecondary;
                font-weight: 600;
                display: flex;
            }

            &:not(:first-child) {
                border-left: 1px solid $grey;
                margin-left: 7.5px;
                padding-left: 7.5px;

                @include max-screen(768px) {
                    border: none;
                    margin-left: 0;
                    padding-left: 0;
                }
            }

            @include max-screen(768px) {
                display: block;
                margin-top: 8px;
            }

            a {
                display: inline-block;
                text-decoration: none;
                transition: 0.2s ease-in-out;

                &:hover {
                    color: $hp-blue;
                    transform: translateX(2px);
                }
            }

            b.hp-blue {
                color: $hp-blue;
            }
        }
    }
}

.product-hero
.product-wrapper
.product-hero__top
.product-hero__featured-labels {
    @include max-screen(768px) {
        bottom: 10px;
        position: absolute;
        right: 0;
    }

    img {
        @include max-screen(768px) {
            max-height: 25px;
        }
    }
}

.product-hero .product-hero__content-left {
    max-width: 50%;

    @include max-screen(1040px) {
        max-width: 40%;
    }

    @include max-screen(768px) {
        flex-direction: column;
        margin-top: 10px;
        max-width: none;
        order: 1;
        width: 100%;
    }
}

.product-hero .product-hero__content-left .product-hero__notification {
    background-color: #78c64d;
    border-radius: 3px;
    color: $white;
    display: inline-block;
    font-family: $titleStack;
    font-size: 1em;
    font-weight: bold;
    padding: 10px;
    opacity: 1;
    transition: 0.2s ease-in-out;
}

.product-hero .product-hero__content-left .product-hero__images {
    display: flex;

    .product-hero__images-thumbnails {
        margin-right: 3px;

        .product-hero__images-thumbnail {
            border-bottom: 2px solid rgba(240, 240, 240, 0);
            transition: 0.2s ease-in-out;

            &.is-active {
                border-bottom: 2px solid rgba(240, 240, 240, 1);
            }

            &:hover {
                border-bottom: 2px solid rgba(200, 200, 200, 1);
                cursor: pointer;

                img {
                    transform: scale(1.05);
                }
            }

            &:not(:first-child) {
                margin-top: 10px;
            }

            img {
                transition: 0.2s ease-in-out;
            }
        }
    }

    .product-hero__images-active {
        margin-left: 25px;
        min-height: 400px;

        @include max-screen(1040px) {
            margin: 0 auto;
            max-width: 350px;
            min-height: 300px;
        }

        @include max-screen(768px) {
            margin: 0 auto;
            max-width: 250px;
            min-height: 200px;
        }
    }
}

.product-hero .product-hero__content-right {
    margin-left: 80px;
    max-width: calc(50% - 80px);

    @include max-screen(1040px) {
        margin-left: 40px;
        max-width: calc(60% - 40px);
    }

    @include max-screen(768px) {
        margin-left: 0;
        max-width: none;
        order: 3;
        width: 100%;
    }
}

.product-hero .product-hero__content-right .product-hero__hp-keuze {
    max-width: 130px;

    @include max-screen(768px) {
        display: none;
    }
}

.product-hero__hp-keuze {
    display: flex;
    justify-content: flex-start;
    img {
        margin-right: 10px;
    }

    .product-hero__kortingsbox {
        background-color: $productRed;
        border-radius: 8px;
        color: white;
        padding: 5px 10px;

        span {
            display: block;
            font-weight: 600;
            line-height: 35px;
            min-width: 90px;
        }
    }
}

.product-hero .product-hero__content-right .product-hero__pricing {
    ul.product-hero__pricing-prices {
        @include max-screen(768px) {
            flex-wrap: wrap;
        }

        li.product-hero__pricing-price {
            display: flex;

            .incbtw {
                display: none;
            }

            &:not(:last-child) {
                margin-right: 10px;
            }

            &.price-blue {
                color: $hp-blue;
            }

            &.price-red {
                color: $productRed;
            }

            &.price-orange {
                color: $hp-orange;
            }

            &.price-dark-blue {
                color: $darkBlue;
            }

            @include max-screen(768px) {
                margin-top: 5px;
            }

            .price {
                font-family: $titleStack;
                font-size: em(34, 14);
                font-weight: 900;
                position: relative;

                &.including {
                    font-size: em(35, 14);
                }

                .price-decimal {
                    font-family: $readStack;
                    font-size: 0.4em;
                    position: absolute;
                    right: -13px;
                    top: 6px;
                }
            }

            .flex-row {
                display: flex;
                flex-direction: row;
                height: 80%;
                margin-left: 4px;
            }

            .price-disclaimer {
                align-self: flex-end;
                color: $strangeGrey;
                font-size: 0.75em;
            }
        }
    }
}

.product-hero .product-hero__content-right .tierprices {
    font-weight: 700;
    margin: 20px 0 10px 0;
}
.product-hero .product-hero__content-right .product-hero__overview-table {
    border: 2px solid $grayBorder;
    border-radius: 3px;

    > li {
        margin-bottom: 0;
        width: 33.333%;

        &:not(:last-child) {
            ul {
                border-right: 2px solid $grayBorder;
            }
        }

        ul {
            &.incbtw {
                display: none;
            }

            li {
                padding: 8px 30px;
                margin: 0;
                position: relative;
                text-align: center;

                @include max-screen(768px) {
                    padding: 8px 15px;
                }

                &:first-child {
                    border-bottom: 2px solid $grayBorder;
                }

                .btn--plus {
                    box-shadow: 0 1px 0 0 $hp;
                    font-size: 1.1em;
                    padding: 0 5px;
                    position: absolute;
                    right: 8px;
                    transition: 0.2s ease-in-out all;

                    &:active,
                    &:hover,
                    &:focus {
                        background-color: $hp-darkblue;
                        border: none;
                    }

                    img {
                        height: 8px;
                        width: 8px;
                    }
                }
            }
        }
    }

    &.configurable li {
        &:last-child() {
            border-top: 2px solid $grayBorder;
        }
    }
}

.product-hero .product-hero__content-right .product-hero__usps {
    li {
        color: $darkGreen;
        font-family: $readStack;
        font-weight: bold;
        margin-bottom: 8px;
        position: relative;

        &.tooltip.wrapper {
            width: 100%;

            .tooltip.content {
                font-weight: normal;
                max-width: 550px;
                width: 100%;
            }
        }

        &.usp-blue {
            color: $hp-blue;
        }

        img {
            margin-right: 8px;
            position: relative;
            top: 2px;
        }
    }
}

.product-hero .product-hero__content-right .product-hero__disclaimer-payment-methods {
    height: 30px;
    margin: 0 -5px 0 -5px;

    img {
        height: 100%;
        margin: 0 5px 0 5px;
    }
}

.product-hero__addtocart {
    margin-bottom: 15px;
    width: calc(100% + 60px);

    @include max-screen(768px) {
        width: auto;
    }

    .red {
        color: $error;
        font-weight: 600;
    }

    .product-hero__cart-values {
        border: 1px solid $grayBorder;
        border-radius: 3px;
        margin-right: 7px;
        width: 101px;

        input {
            background-color: none;
            border: none;
            border-left: 1px solid $grayBorder;
            border-right: 1px solid $grayBorder;
            border-radius: 0;
            color: $hp;
            float: none;
            font-weight: 600;
            height: 100%;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
            width: calc(100% - 56px);
        }

        button,
        div.btn {
            background: none;
            border-radius: 0;
            box-shadow: none;
            color: $hp-blue;
            height: 100%;
            font-size: 1em;
            font-weight: bold;
            line-height: 1em;
            padding: 0;
            transition: 0.2s ease-in-out all;
            width: 28px;

            &:hover {
                background-color: rgb(245, 245, 245);
            }

            img {
                height: 100%;
                width: 11px;
            }
        }

        .product-hero__cart-add {
            background-color: $hp-green;
            border-radius: 3px;
            box-shadow: 2px 2px 0 0 $strangeGreen;
            color: white;
            font-size: em(16, 14);
            font-stretch: normal;
            font-style: normal;
            font-weight: bold;
            letter-spacing: 0.9px;
            line-height: 1.38;
            padding: 12px 80px;
            text-transform: normal;
            transition: 0.2s ease-in-out all;
            width: calc(100% - 120px);

            &:hover {
                background-color: $strangeGreen;
            }

            @include max-screen(1280px) {
                padding: 12px 40px;
            }

            @include max-screen(1040px) {
                padding: 12px 24px;
            }

            @include max-screen(768px) {
                box-shadow: none;
                flex-grow: 1;
                padding: 12px 32px;
                width: auto;
            }

            @include max-screen(400px) {
                font-size: 1em;
                padding: 12px 12px;
            }
        }
    }
}

.product-hero__sticky {
    background-color: $white;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    display: none;
    min-height: 90px;
    padding: 9px 0;
    opacity: 0;
    position: sticky;
    top: -120px;
    transition: 0.2s all ease-in-out;
    z-index: 2;

    &.is-active {
        display: block;
        opacity: 1;
        top: 0;
        visibility: visible;

        @include max-screen(750px) {
            display: none;
        }
    }

    .product-hero__sticky-info {
        .product-hero__images {
            .product-hero__images-thumbnails {
                display: none;
            }

            .product-hero__images-active {
                max-width: 68px;
            }
        }

        .title {
            font-family: $titleStack;
            font-size: em(16, 14);
            font-weight: 900;
            margin-left: 35px;
            max-width: 316px;
            line-height: 1.13;
            text-transform: uppercase;

            @include max-screen(1041px) {
                font-size: em(14, 14);
                margin-left: 10px;
            }

            @include max-screen(901px) {
                font-size: em(12, 14);
                max-width: 250px;
            }
        }

        .product-hero__sticky-price {
            color: $hp-blue;
            margin-left: 35px;
            margin-right: 20px;

            @include max-screen(1041px) {
                margin-left: 10px;
            }

            .price {
                font-family: $titleStack;
                font-size: em(34, 14);
                font-weight: 900;
                position: relative;

                .price-decimal {
                    font-size: 0.4em;
                    position: absolute;
                    top: 0;
                    right: -5px;

                    @include max-screen(1041px) {
                        right: -8px;
                    }
                }
            }

            .price-disclaimer {
                color: #717171;
                font-size: 0.9em;
                margin-left: 3px;

                @include max-screen(1041px) {
                    display: block;
                    margin-left: 0;
                }
            }
        }
    }

    .product-hero__addtocart {
        flex: 0;
        flex-grow: 1;
        justify-content: flex-end;

        .product-hero__cart-add {
            max-width: 215px;
            padding: 12px 33px;

            @include max-screen(1041px) {
                min-width: 200px;
                padding: 12px 20px;
            }
        }
    }
}

.product-hero__addtocart {
    width: calc(100% + 60px);

    @include max-screen(768px) {
        width: auto;
    }

    .red {
        color: $error;
        font-weight: 600;
    }

    .product-hero__cart-values {
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        margin-right: 7px;
        width: 101px;

        input {
            background-color: none;
            border: none;
            border-radius: 0;
            border-left: 1px solid $grayBorder;
            border-right: 1px solid $grayBorder;
            color: $hp;
            float: none;
            font-weight: 600;
            height: 100%;
            padding-left: 5px;
            padding-right: 5px;
            text-align: center;
            width: calc(100% - 56px);
        }

        button {
            background: none;
            border-radius: 0;
            box-shadow: none;
            color: $hp-blue;
            font-size: 1em;
            font-weight: bold;
            height: 100%;
            line-height: 1em;
            padding: 0;
            transition: 0.2s ease-in-out all;
            width: 28px;

            &:hover {
                background-color: rgb(245, 245, 245);
            }

            img {
                height: 11px;
                width: 11px;
            }
        }
    }

    .product-hero__cart-add {
        background-color: $hp-green;
        box-shadow: 2px 2px 0 0 #477f29;
        border-radius: 3px;
        color: white;
        font-size: em(16, 14);
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.9px;
        line-height: 1.38;
        padding: 12px 80px;
        text-transform: capitalize;
        transition: 0.2s ease-in-out all;
        width: calc(100% - 120px);

        &:hover {
            background-color: #477f29;
            border: none;
            color: $white;
        }

        @include max-screen(1280px) {
            padding: 12px 40px;
        }

        @include max-screen(1041px) {
            padding: 12px 24px;
        }

        @include max-screen(768px) {
            box-shadow: none;
            flex-grow: 1;
            padding: 12px 32px;
            width: auto;
        }

        @include max-screen(400px) {
            font-size: 1em;
            padding: 12px 12px;
        }
    }
}

.product-information {
    background-color: #f1f8ff;

    @include max-screen(768px) {
        margin-top: 32px;
    }
}

.product-information .product-information__index {
    background-color: $white;

    @include max-screen(768px) {
        display: none;
    }

    ul.product-information__index-list {
        margin-left: -12.5px;
        margin-right: -12.5px;

        @include max-screen(1040px) {
            margin-left: -5px;
            margin-right: -5px;
        }

        li {
            margin-left: 12.5px;
            margin-right: 12.5px;
            transition: 0.2s ease-in-out;

            &:hover {
                transform: scale(1.05);
            }

            @include max-screen(1040px) {
                margin-left: 5px;
                margin-right: 5px;
            }

            a {
                color: $hp;
                font-size: 1em;
                font-stretch: normal;
                font-style: normal;
                font-weight: 600;
                line-height: 1.43;
                text-decoration: none;
            }
        }
    }

    .product-information__read-more {
        font-family: $readStack;
        font-stretch: normal;
        font-style: normal;
        font-weight: bold;
        line-height: 1.43;
        text-decoration: none;
        transition: 0.2s all ease-in-out;

        @include max-screen(1040px) {
            margin-left: 20px;
            min-width: 97px;
        }

        img {
            margin-left: 8px;
            transition: 0.2s all ease-in-out;
            transform: translateY(1px);
        }

        &:hover {
            img {
                transform: translateY(3px);
            }
        }
    }
}

.product-information .product-information__content-item {
    background-color: $white;
    padding: 32px;

    h2,
    h3,
    span.title {
        display: block;
        font-family: $titleStack;
        font-size: em(32, 14);
        font-weight: 900;
        margin-top: 10px;
        text-transform: initial;

        @include max-screen(768px) {
            font-size: em(24, 14);
        }
    }

    a.btn {
        font-size: em(16, 14);
        font-weight: bold;
        letter-spacing: 0.9px;
        line-height: 1.38;
        min-width: 285px;
        &:visited {
            color: white;
        }

        @include max-screen(768px) {
            font-size: em(15, 14);
        }

        @include max-screen(400px) {
            font-size: em(14, 14);
            min-width: auto;
        }
    }
}

.product-information .product-information__content-item--large {
    max-width: none;
    width: 100%;

    @include max-screen(768px) {
        flex-direction: column;
    }

    .product-information__content-item-info,
    .product-information__content-item-specs {
        max-width: 50%;

        @include max-screen(768px) {
            max-width: none;
        }
    }

    .product-information__content-item-info {
        ul {
            margin-bottom: 10px;
            margin-top: 5px;

            li {
                list-style: disc inside;
                margin: 0;
            }

            br {
                display: none;
            }
        }
    }

    .label--hp-keuze {
        @include max-screen(768px) {
            display: none;
        }
    }

    .product-information__content-item-specs {
        font-family: $readStack;
        line-height: 1.21;
        margin-left: 10px;
        max-width: 400px;
        width: 100%;

        @include max-screen(768px) {
            margin-left: 0;
            margin-top: 32px;
        }

        .product-specifications ul li {
            display: flex;
            margin-bottom: 0;
            padding: 8px 20px;

            @include max-screen(768px) {
                flex-direction: column;
            }

            &:nth-child(odd) {
                background: $blueTertiary;
            }

            b {
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                line-height: 1.21;
                max-width: 165px;
                min-width: 170px;
            }
        }

        .product-specifications.box-mediathek ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 20px;
            @include max-screen(900px) {
                flex-direction: row;
            }
            li {
                background: none;
                border: solid 0.8px $priceBlue;
                border-radius: 2.4px;
                margin: 10px 0 0 0;
                padding: 15px;
                width: 49%;
                @include max-screen(900px) {
                    width: 100%;
                }
                a {
                    color: $priceBlue;
                    font-family: $titleStack;
                    font-weight: 700;
                    text-decoration: none;
                    text-transform: uppercase;
                }
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}

.product-information .product-information__content-item_small-blocks {
    @include max-screen(900px) {
        flex-direction: column;
    }

    > div {
        width: calc(50% - 20px);

        @include max-screen(900px) {
            width: auto;
        }
    }

    .product-information__content-item {
        img {
            height: 45px;

            @include max-screen(768px) {
                height: 34px;
            }
        }
    }

    .product-information__content-item_contact-list {
        li {
            img {
                margin-right: 15px;
                width: 42px;

                @include max-screen(768px) {
                    width: 38px;
                }
            }

            span.title {
                font-family: $readStack;
                font-size: em(21, 14);
                font-weight: bold;
                line-height: 0.77;
                margin-top: 2px;
                text-transform: initial;

                @include max-screen(768px) {
                    font-size: em(18, 14);
                }

                a {
                    text-decoration: none;
                }
            }

            span {
                font-family: $readStack;
                font-size: em(11, 14);
                line-height: 1;
            }
        }
    }
}

.catalog-product-view .block.related {
    .block-title strong {
        font-family: $titleStack;
        font-size: em(16, 14);
        font-weight: 900;
        text-transform: none;
    }
}

.becom-logo {
    min-width: 100px;
    margin-top: 5px;
}

.kiyoh-logo {
    right: 18px;
    position: relative;
}
