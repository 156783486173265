.cms-no-route {
    .category-header__title {
        color: $blueSecondary;
        font-size: em(28, 14);

        @include max-screen(425px) {
            margin-bottom: $gutter;
          }
    }

    p {
        margin: 14px 0;
    }

    .default404 li:first-of-type {
        margin-bottom: 30px;
    }
    
    .default404 .icon404_descSearch {
        margin-bottom: 90px;
        margin-top: 17px;
    }

    a.home--icon404:before,
    span.search--icon404:before {
        background-color: $blueSecondary;
        border-radius: 50px;
        color: white;
        display: inline-block;
        height: 35px;
        font-family: "FontAwesome";
        font-size: 17px;
        font-weight: 900;
        padding: 6px;
        text-align: center;
        vertical-align: middle;
        width: 35px;
    }

    a.home--icon404:before {
        content: "\f015";
    }

    span.search--icon404:before {
        content: "\f002";
    }
      
    .icon404_desc {
        color: $blueSecondary;
        font-weight: 600;
        margin-left: 20px;
      
        a {
            color: inherit;
        }
    }
    
    .page-main .block-search {
        float: none;
        margin-bottom: 60px;
        padding: 0;
        z-index: 1;

        form {
            display: flex;
        }

        .field.search .input-text {
            background: #fafafa;
            border: 1px solid $gray;
            border-radius: 4px 0 0 4px;
            height: 40px;
            left: 0;
            line-height: 40px;
            min-width: 300px;
            width: 35%;
            
            @include max-screen(360px) {
                min-width: 100px;
                width: 100%;
            }
        }
        button.action.search {
            background-color: $gray;
            border-radius: 0 4px 4px 0;
            font-size: 0;
            height: 40px;
            position: static;
            width: 40px;

            &:before {
                width: 40px;
            }
        }
    }
}
