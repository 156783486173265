//Declare gridsize
.product-grid-new.product-grid-new-four {
    display: flex;
    flex-wrap: wrap;
    margin-left: -17.5px;
    margin-right: -17.5px;

    .product-grid-item {
        margin-bottom: 35px;
        margin-left: 17.5px;
        margin-right: 17.5px;
        max-width: 220px;
        width: calc(25% - 35px);

        .item__label--hp-keuze {
            background-color: #f1f8ff;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            left: -35px;
            min-height: 30px;
            position: absolute;
            top: 100px;
            transform: rotate(-90deg);
            width: 100px;
        }

        .item__right {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: flex-end;
        }
        
        .item__right .item__price-flex {
            margin-top: auto;
        }
        @include max-screen(1180px) {
            width: calc(50% - 17.5px);
        }

        @include max-screen(1040px) {
            width: calc(33.333% - 35px);
        }

        @include max-screen(850px) {
            max-width: none;
            width: calc(50% - 35px);
        }

        @include max-screen(768px) {
            max-width: none;
            width: 100%;
        }
    }

    @include max-screen(768px) {
        .product-grid-item {
            margin: 0;
        }

        .product-grid-item > a {
            border: 0;
            border-bottom: 1px solid $light-silver;
            display: flex;

            .item__figure {
                margin-top: 10px;
            }

            .item__right {
                padding-left: 5px;
            }

            .item__title {
                min-height: unset;
            }
        }

        .item__figure {
            img {
                height: 80px;
                max-width: 80px;
                width: 80px;
            }
        }
    }
}

//Grid inner
.product-grid-new {
    .product-grid-item {
        > a {
            background: $white;
            border: 2px solid $blueTertiary;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 10px;
            position: relative;
            text-align: left;
            text-decoration: none;
            transition: transform 0.15s cubic-bezier(0.55, 0, 0.1, 1);

            .item__notification {
                background: $blueTertiary;
                border-radius: 4px;
                color: $grayTertiary;
                opacity: 0.5;
                padding: 5px 10px;
                position: absolute;
                text-align: left;
                text-decoration: none;
                transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
                width: calc(100% - 20px);
                
                &.item__notification--hp-keuze {
                    background-color: rgba(57, 151, 226, 0.9);
                    color: $white;
                    opacity: 1;
                }
            }

            .product-grid-item__label--hp-keuze {
                background-color: $blueTertiary;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                left: -35px;
                min-height: 30px;
                position: absolute;
                top: 100px;
                transform: rotate(-90deg);
                width: 100px;
            }

            .product-grid-item__quickbuy {
                backface-visibility: hidden;
                left: 10px;
                opacity: 0;
                position: absolute;
                transform: scale(1);
                transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
                top: calc(50% - 70px);
                width: calc(100% - 20px);

                @include max-screen(768px) {
                    display: none;
                }

                &:hover {
                    transform: scale(1.2);

                    @include max-screen(768px) {
                        display: none;
                    }
                }
            }

            .product-grid-item__quickbuy.btn--uitverkocht, 
            .product-grid-item__quickbuy.btn--uitverkocht:hover {
                opacity: 1;
                transform: none;
                transition: none;
            }

            .item__desc,
            .item__manufacturer,
            .item__stuks {
                font-family: $titleStack;
                font-size: em(12, 14);
            }

            .item__stuks,
            .item__title {
                display: block;
                font-family: $titleStack;
            }

            .item__manufacturer {
                color: $darkBlue;
            }

            .item__stuks {
                color: $hp;
                font-weight: normal;
                margin-bottom: 7px;
                min-height: 17px;
            }

            .item__title {
                color: $darkBlue;
                font-size: em(15, 14);
                font-weight: 800;
                height: initial;
                max-height: 85px;
                min-height: 85px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-transform: uppercase;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .item__desc {
                color: $darkGreen;
                display: block;
                font-size: em(10.5, 14);
                font-weight: 600;
                margin: 10px 0 0 0;

                b {
                    display: block;
                }
            }

            .price-flexbox {
                display: flex;
                width: 100%;
            }

            .price-box {
                display: block;
                margin: 10px 0 0 0;
                max-height: 32px;

                .normal-price,
                .item__price {
                    align-items: flex-start;
                    display: flex;
                    font-size: em(16, 14);

                    .price-label {
                        color: $blue;
                        font-family: $titleStack;
                        font-size: 15px;
                        font-weight: normal;
                    }

                    &.item__price-old {
                        color: $grayTertiary;
                        font-weight: 300;
                        margin-left: 7px;
                        padding: 0;
                        text-align: left;
                        text-decoration: line-through;
                        width: 50%;
                    }

                    .price-container.price-final_price {
                        color: $blueSecondary;
                        font-weight: 900;
                        text-align: left;
                    }

                    .price-excluding-tax {
                        .price {
                            font-size: 18px;
                            font-weight: 900;
                        }
                        &:before {
                            display: none;
                            content: "";
                        }
                    }

                    .price-wrapper {
                        display: inline-block;
                        margin-top: 0;
                    }

                    .incbtw,
                    .price-including-tax {
                        display: none;
                    }
                }
            }
        }

        > a:hover {
            border: 2px solid $blueSecondary;
            opacity: 1;

            @include max-screen(768px) {
                border: 0;
            }

            .item__notification {
                background: $green;
                color: $white;
                opacity: 0.9;

                &.item__notification--hp-keuze {
                    background-color: rgba(57, 151, 226, 0.9);
                    color: $white;
                    opacity: 1;
                }
            }

            .product-grid-item__quickbuy {
                transition: opacity 0.5s ease-in-out;
                opacity: 1;
            }
        }
    }

    .product-grid-item--blue {
        a {
            min-height: 402px;
        }
    }

    .product-grid-item--brand {
        display: none;
        a {
            align-items: center;
            background-color: #F1F8FF;
            border-color: $grayBorder;
            display: flex;
            .item__figure {
                text-align: center;
            }
            img {
                width: 80%;
            }

            .item__brand-title {
                color: $darkBlue;
                font-weight: 700;
                padding: 20px;
                text-align: center;
                text-transform: unset;
            }

            .item__brand-description {
                color: #34495E;
                font-size: em(14, 14);
                padding-top: 5px;
            }
        }
    }

    @include max-screen(768px) {
        .product-grid-item--brand {
            > a {
                border: 1px solid $grayBorder;
                flex-direction: row;
            }
            img {
                margin-right: 15px;
            }
            .item__brand-title {
                display: none;
            }
        }
    }
}
